
import { importSync as i, macroCondition, getGlobalConfig } from '@embroider/macros';
let w = window;
let d = w.define;


i("./-embroider-implicit-modules.js");

d("boel/adapters/account", function(){ return i("boel/adapters/account.js");});
d("boel/adapters/application", function(){ return i("boel/adapters/application.js");});
d("boel/app", function(){ return i("boel/app.js");});
d("boel/authenticators/oauth2", function(){ return i("boel/authenticators/oauth2.js");});
d("boel/config/environment", function(){ return i("boel/config/environment.js");});
d("boel/formats", function(){ return i("boel/formats.js");});
d("boel/models/account", function(){ return i("boel/models/account.js");});
d("boel/models/action", function(){ return i("boel/models/action.js");});
d("boel/models/addressstatus", function(){ return i("boel/models/addressstatus.js");});
d("boel/models/adressestatus", function(){ return i("boel/models/adressestatus.js");});
d("boel/models/appointment", function(){ return i("boel/models/appointment.js");});
d("boel/models/appointmentstate", function(){ return i("boel/models/appointmentstate.js");});
d("boel/models/article", function(){ return i("boel/models/article.js");});
d("boel/models/association", function(){ return i("boel/models/association.js");});
d("boel/models/behandlingsstatus", function(){ return i("boel/models/behandlingsstatus.js");});
d("boel/models/bundle", function(){ return i("boel/models/bundle.js");});
d("boel/models/closedperiod", function(){ return i("boel/models/closedperiod.js");});
d("boel/models/company", function(){ return i("boel/models/company.js");});
d("boel/models/document", function(){ return i("boel/models/document.js");});
d("boel/models/favoritter", function(){ return i("boel/models/favoritter.js");});
d("boel/models/favorittyper", function(){ return i("boel/models/favorittyper.js");});
d("boel/models/insurance-company", function(){ return i("boel/models/insurance-company.js");});
d("boel/models/insurancecase", function(){ return i("boel/models/insurancecase.js");});
d("boel/models/insurancefirm", function(){ return i("boel/models/insurancefirm.js");});
d("boel/models/journal", function(){ return i("boel/models/journal.js");});
d("boel/models/journaltype", function(){ return i("boel/models/journaltype.js");});
d("boel/models/link", function(){ return i("boel/models/link.js");});
d("boel/models/log", function(){ return i("boel/models/log.js");});
d("boel/models/manual", function(){ return i("boel/models/manual.js");});
d("boel/models/message", function(){ return i("boel/models/message.js");});
d("boel/models/openinghour", function(){ return i("boel/models/openinghour.js");});
d("boel/models/order", function(){ return i("boel/models/order.js");});
d("boel/models/orderline", function(){ return i("boel/models/orderline.js");});
d("boel/models/outlet", function(){ return i("boel/models/outlet.js");});
d("boel/models/patient", function(){ return i("boel/models/patient.js");});
d("boel/models/patients-outlet", function(){ return i("boel/models/patients-outlet.js");});
d("boel/models/payment", function(){ return i("boel/models/payment.js");});
d("boel/models/postalcode", function(){ return i("boel/models/postalcode.js");});
d("boel/models/problem", function(){ return i("boel/models/problem.js");});
d("boel/models/product", function(){ return i("boel/models/product.js");});
d("boel/models/report", function(){ return i("boel/models/report.js");});
d("boel/models/room", function(){ return i("boel/models/room.js");});
d("boel/models/setting", function(){ return i("boel/models/setting.js");});
d("boel/models/sikkerhedslog", function(){ return i("boel/models/sikkerhedslog.js");});
d("boel/models/surface", function(){ return i("boel/models/surface.js");});
d("boel/models/therapist", function(){ return i("boel/models/therapist.js");});
d("boel/models/treatment", function(){ return i("boel/models/treatment.js");});
d("boel/models/user", function(){ return i("boel/models/user.js");});
d("boel/router", function(){ return i("boel/router.js");});
d("boel/serializers/application", function(){ return i("boel/serializers/application.js");});
d("boel/services/aftalestatus", function(){ return i("boel/services/aftalestatus.js");});
d("boel/services/articles", function(){ return i("boel/services/articles.js");});
d("boel/services/clock", function(){ return i("boel/services/clock.js");});
d("boel/services/confirmation", function(){ return i("boel/services/confirmation.js");});
d("boel/services/current-user", function(){ return i("boel/services/current-user.js");});
d("boel/services/excelexport", function(){ return i("boel/services/excelexport.js");});
d("boel/services/favoritter", function(){ return i("boel/services/favoritter.js");});
d("boel/services/kabinestyringhub", function(){ return i("boel/services/kabinestyringhub.js");});
d("boel/services/keymessage", function(){ return i("boel/services/keymessage.js");});
d("boel/services/patienter", function(){ return i("boel/services/patienter.js");});
d("boel/services/previous-route", function(){ return i("boel/services/previous-route.js");});
d("boel/services/session", function(){ return i("boel/services/session.js");});
d("boel/services/store", function(){ return i("boel/services/store.js");});
d("boel/session-stores/application", function(){ return i("boel/session-stores/application.js");});
d("boel/transforms/boolean", function(){ return i("boel/transforms/boolean.js");});
d("boel/transforms/date", function(){ return i("boel/transforms/date.js");});
d("boel/transforms/number", function(){ return i("boel/transforms/number.js");});
d("boel/transforms/string", function(){ return i("boel/transforms/string.js");});
d("boel/utils/DateUtils", function(){ return i("boel/utils/DateUtils.js");});
d("boel/utils/betalinger", function(){ return i("boel/utils/betalinger.js");});
d("boel/utils/calender", function(){ return i("boel/utils/calender.js");});
d("boel/utils/error", function(){ return i("boel/utils/error.js");});
d("boel/utils/field", function(){ return i("boel/utils/field.js");});
d("boel/utils/kabinestyringsstatus", function(){ return i("boel/utils/kabinestyringsstatus.js");});
d("boel/utils/sdk", function(){ return i("boel/utils/sdk.js");});
d("boel/utils/stringutils", function(){ return i("boel/utils/stringutils.js");});
d("boel/validations/unique", function(){ return i("boel/validations/unique.js");});
d("boel/services/notifications", function(){ return i("boel/services/notifications.js");});
d("boel/data-adapter", function(){ return i("boel/data-adapter.js");});
d("boel/initializers/ember-data", function(){ return i("boel/initializers/ember-data.js");});
d("boel/services/file-queue", function(){ return i("boel/services/file-queue.js");});
d("boel/services/keyboard", function(){ return i("boel/services/keyboard.js");});
d("boel/services/moment", function(){ return i("boel/services/moment.js");});
d("boel/services/page-title", function(){ return i("boel/services/page-title.js");});
d("boel/services/cookies", function(){ return i("boel/services/cookies.js");});
d("boel/initializers/ember-simple-auth", function(){ return i("boel/initializers/ember-simple-auth.js");});
d("boel/utils/inject", function(){ return i("boel/utils/inject.js");});
d("boel/utils/is-fastboot", function(){ return i("boel/utils/is-fastboot.js");});
d("boel/utils/location", function(){ return i("boel/utils/location.js");});
d("boel/utils/objects-are-equal", function(){ return i("boel/utils/objects-are-equal.js");});
d("boel/component-managers/glimmer", function(){ return i("boel/component-managers/glimmer.js");});
d("boel/services/-ensure-registered", function(){ return i("boel/services/-ensure-registered.js");});
d("boel/instance-initializers/global-ref-cleanup", function(){ return i("boel/instance-initializers/global-ref-cleanup.js");});
d("boel/initializers/load-bootstrap-config", function(){ return i("boel/initializers/load-bootstrap-config.js");});
d("boel/initializers/app-version", function(){ return i("boel/initializers/app-version.js");});
d("boel/services/intl", function(){ return i("boel/services/intl.js");});
d("boel/utils/intl/missing-message", function(){ return i("boel/utils/intl/missing-message.js");});
d("boel/services/new-version", function(){ return i("boel/services/new-version.js");});
d("boel/utils/titleize", function(){ return i("boel/utils/titleize.js");});
d("boel/services/print-this", function(){ return i("boel/services/print-this.js");});
d("boel/instance-initializers/ember-scroll", function(){ return i("boel/instance-initializers/ember-scroll.js");});
d("boel/services/scroll", function(){ return i("boel/services/scroll.js");});
d("boel/utils/parse-touch-data", function(){ return i("boel/utils/parse-touch-data.js");});
d("boel/templates/application", function(){ return i("boel/templates/application.hbs");});
d("boel/controllers/application", function(){ return i("boel/controllers/application.js");});
d("boel/routes/application", function(){ return i("boel/routes/application.js");});
d("boel/templates/auth", function(){ return i("boel/templates/auth.hbs");});
d("boel/routes/auth", function(){ return i("boel/routes/auth.js");});
d("boel/templates/auth/aftaler", function(){ return i("boel/templates/auth/aftaler.hbs");});
d("boel/controllers/auth/aftaler", function(){ return i("boel/controllers/auth/aftaler.js");});
d("boel/routes/auth/aftaler", function(){ return i("boel/routes/auth/aftaler.js");});
d("boel/templates/auth/aftaler/aftaler", function(){ return i("boel/templates/auth/aftaler/aftaler.hbs");});
d("boel/controllers/auth/aftaler/aftaler", function(){ return i("boel/controllers/auth/aftaler/aftaler.js");});
d("boel/routes/auth/aftaler/aftaler", function(){ return i("boel/routes/auth/aftaler/aftaler.js");});
d("boel/templates/auth/aftaler/betalinger", function(){ return i("boel/templates/auth/aftaler/betalinger.hbs");});
d("boel/controllers/auth/aftaler/betalinger", function(){ return i("boel/controllers/auth/aftaler/betalinger.js");});
d("boel/routes/auth/aftaler/betalinger", function(){ return i("boel/routes/auth/aftaler/betalinger.js");});
d("boel/templates/auth/aftaler/betalinger/delete", function(){ return i("boel/templates/auth/aftaler/betalinger/delete.hbs");});
d("boel/controllers/auth/aftaler/betalinger/delete", function(){ return i("boel/controllers/auth/aftaler/betalinger/delete.js");});
d("boel/routes/auth/aftaler/betalinger/delete", function(){ return i("boel/routes/auth/aftaler/betalinger/delete.js");});
d("boel/templates/auth/aftaler/betalinger/edit", function(){ return i("boel/templates/auth/aftaler/betalinger/edit.hbs");});
d("boel/controllers/auth/aftaler/betalinger/edit", function(){ return i("boel/controllers/auth/aftaler/betalinger/edit.js");});
d("boel/routes/auth/aftaler/betalinger/edit", function(){ return i("boel/routes/auth/aftaler/betalinger/edit.js");});
d("boel/templates/auth/aftaler/betalinger/kvittering", function(){ return i("boel/templates/auth/aftaler/betalinger/kvittering.hbs");});
d("boel/controllers/auth/aftaler/betalinger/kvittering", function(){ return i("boel/controllers/auth/aftaler/betalinger/kvittering.js");});
d("boel/routes/auth/aftaler/betalinger/kvittering", function(){ return i("boel/routes/auth/aftaler/betalinger/kvittering.js");});
d("boel/templates/auth/aftaler/betalinger/new", function(){ return i("boel/templates/auth/aftaler/betalinger/new.hbs");});
d("boel/controllers/auth/aftaler/betalinger/new", function(){ return i("boel/controllers/auth/aftaler/betalinger/new.js");});
d("boel/routes/auth/aftaler/betalinger/new", function(){ return i("boel/routes/auth/aftaler/betalinger/new.js");});
d("boel/templates/auth/aftaler/delete", function(){ return i("boel/templates/auth/aftaler/delete.hbs");});
d("boel/controllers/auth/aftaler/delete", function(){ return i("boel/controllers/auth/aftaler/delete.js");});
d("boel/routes/auth/aftaler/delete", function(){ return i("boel/routes/auth/aftaler/delete.js");});
d("boel/templates/auth/aftaler/deletepatient", function(){ return i("boel/templates/auth/aftaler/deletepatient.hbs");});
d("boel/controllers/auth/aftaler/deletepatient", function(){ return i("boel/controllers/auth/aftaler/deletepatient.js");});
d("boel/routes/auth/aftaler/deletepatient", function(){ return i("boel/routes/auth/aftaler/deletepatient.js");});
d("boel/templates/auth/aftaler/edit", function(){ return i("boel/templates/auth/aftaler/edit.hbs");});
d("boel/controllers/auth/aftaler/edit", function(){ return i("boel/controllers/auth/aftaler/edit.js");});
d("boel/routes/auth/aftaler/edit", function(){ return i("boel/routes/auth/aftaler/edit.js");});
d("boel/templates/auth/aftaler/insurancecases", function(){ return i("boel/templates/auth/aftaler/insurancecases.hbs");});
d("boel/controllers/auth/aftaler/insurancecases", function(){ return i("boel/controllers/auth/aftaler/insurancecases.js");});
d("boel/routes/auth/aftaler/insurancecases", function(){ return i("boel/routes/auth/aftaler/insurancecases.js");});
d("boel/templates/auth/aftaler/insurancecases/delete", function(){ return i("boel/templates/auth/aftaler/insurancecases/delete.hbs");});
d("boel/controllers/auth/aftaler/insurancecases/delete", function(){ return i("boel/controllers/auth/aftaler/insurancecases/delete.js");});
d("boel/routes/auth/aftaler/insurancecases/delete", function(){ return i("boel/routes/auth/aftaler/insurancecases/delete.js");});
d("boel/templates/auth/aftaler/insurancecases/edit", function(){ return i("boel/templates/auth/aftaler/insurancecases/edit.hbs");});
d("boel/controllers/auth/aftaler/insurancecases/edit", function(){ return i("boel/controllers/auth/aftaler/insurancecases/edit.js");});
d("boel/routes/auth/aftaler/insurancecases/edit", function(){ return i("boel/routes/auth/aftaler/insurancecases/edit.js");});
d("boel/templates/auth/aftaler/insurancecases/new", function(){ return i("boel/templates/auth/aftaler/insurancecases/new.hbs");});
d("boel/controllers/auth/aftaler/insurancecases/new", function(){ return i("boel/controllers/auth/aftaler/insurancecases/new.js");});
d("boel/routes/auth/aftaler/insurancecases/new", function(){ return i("boel/routes/auth/aftaler/insurancecases/new.js");});
d("boel/templates/auth/aftaler/journal", function(){ return i("boel/templates/auth/aftaler/journal.hbs");});
d("boel/controllers/auth/aftaler/journal", function(){ return i("boel/controllers/auth/aftaler/journal.js");});
d("boel/routes/auth/aftaler/journal", function(){ return i("boel/routes/auth/aftaler/journal.js");});
d("boel/templates/auth/aftaler/journal/delete", function(){ return i("boel/templates/auth/aftaler/journal/delete.hbs");});
d("boel/controllers/auth/aftaler/journal/delete", function(){ return i("boel/controllers/auth/aftaler/journal/delete.js");});
d("boel/routes/auth/aftaler/journal/delete", function(){ return i("boel/routes/auth/aftaler/journal/delete.js");});
d("boel/templates/auth/aftaler/journal/edit", function(){ return i("boel/templates/auth/aftaler/journal/edit.hbs");});
d("boel/controllers/auth/aftaler/journal/edit", function(){ return i("boel/controllers/auth/aftaler/journal/edit.js");});
d("boel/routes/auth/aftaler/journal/edit", function(){ return i("boel/routes/auth/aftaler/journal/edit.js");});
d("boel/templates/auth/aftaler/journal/new", function(){ return i("boel/templates/auth/aftaler/journal/new.hbs");});
d("boel/controllers/auth/aftaler/journal/new", function(){ return i("boel/controllers/auth/aftaler/journal/new.js");});
d("boel/routes/auth/aftaler/journal/new", function(){ return i("boel/routes/auth/aftaler/journal/new.js");});
d("boel/templates/auth/aftaler/key", function(){ return i("boel/templates/auth/aftaler/key.hbs");});
d("boel/controllers/auth/aftaler/key", function(){ return i("boel/controllers/auth/aftaler/key.js");});
d("boel/routes/auth/aftaler/key", function(){ return i("boel/routes/auth/aftaler/key.js");});
d("boel/templates/auth/aftaler/merge", function(){ return i("boel/templates/auth/aftaler/merge.hbs");});
d("boel/controllers/auth/aftaler/merge", function(){ return i("boel/controllers/auth/aftaler/merge.js");});
d("boel/routes/auth/aftaler/merge", function(){ return i("boel/routes/auth/aftaler/merge.js");});
d("boel/templates/auth/aftaler/new", function(){ return i("boel/templates/auth/aftaler/new.hbs");});
d("boel/controllers/auth/aftaler/new", function(){ return i("boel/controllers/auth/aftaler/new.js");});
d("boel/routes/auth/aftaler/new", function(){ return i("boel/routes/auth/aftaler/new.js");});
d("boel/templates/auth/aftaler/newappointment", function(){ return i("boel/templates/auth/aftaler/newappointment.hbs");});
d("boel/controllers/auth/aftaler/newappointment", function(){ return i("boel/controllers/auth/aftaler/newappointment.js");});
d("boel/routes/auth/aftaler/newappointment", function(){ return i("boel/routes/auth/aftaler/newappointment.js");});
d("boel/templates/auth/aftaler/newnopatient", function(){ return i("boel/templates/auth/aftaler/newnopatient.hbs");});
d("boel/controllers/auth/aftaler/newnopatient", function(){ return i("boel/controllers/auth/aftaler/newnopatient.js");});
d("boel/routes/auth/aftaler/newnopatient", function(){ return i("boel/routes/auth/aftaler/newnopatient.js");});
d("boel/templates/auth/aftaler/newpatient", function(){ return i("boel/templates/auth/aftaler/newpatient.hbs");});
d("boel/controllers/auth/aftaler/newpatient", function(){ return i("boel/controllers/auth/aftaler/newpatient.js");});
d("boel/routes/auth/aftaler/newpatient", function(){ return i("boel/routes/auth/aftaler/newpatient.js");});
d("boel/templates/auth/aftaler/patient", function(){ return i("boel/templates/auth/aftaler/patient.hbs");});
d("boel/controllers/auth/aftaler/patient", function(){ return i("boel/controllers/auth/aftaler/patient.js");});
d("boel/routes/auth/aftaler/patient", function(){ return i("boel/routes/auth/aftaler/patient.js");});
d("boel/templates/auth/aftaler/stamdata", function(){ return i("boel/templates/auth/aftaler/stamdata.hbs");});
d("boel/controllers/auth/aftaler/stamdata", function(){ return i("boel/controllers/auth/aftaler/stamdata.js");});
d("boel/routes/auth/aftaler/stamdata", function(){ return i("boel/routes/auth/aftaler/stamdata.js");});
d("boel/templates/auth/booking", function(){ return i("boel/templates/auth/booking.hbs");});
d("boel/controllers/auth/booking", function(){ return i("boel/controllers/auth/booking.js");});
d("boel/routes/auth/booking", function(){ return i("boel/routes/auth/booking.js");});
d("boel/templates/auth/help", function(){ return i("boel/templates/auth/help.hbs");});
d("boel/controllers/auth/help", function(){ return i("boel/controllers/auth/help.js");});
d("boel/routes/auth/help", function(){ return i("boel/routes/auth/help.js");});
d("boel/templates/auth/help/highscore", function(){ return i("boel/templates/auth/help/highscore.hbs");});
d("boel/routes/auth/help/highscore", function(){ return i("boel/routes/auth/help/highscore.js");});
d("boel/templates/auth/help/view", function(){ return i("boel/templates/auth/help/view.hbs");});
d("boel/routes/auth/help/view", function(){ return i("boel/routes/auth/help/view.js");});
d("boel/templates/auth/help/viewguide", function(){ return i("boel/templates/auth/help/viewguide.hbs");});
d("boel/routes/auth/help/viewguide", function(){ return i("boel/routes/auth/help/viewguide.js");});
d("boel/templates/auth/kabiner", function(){ return i("boel/templates/auth/kabiner.hbs");});
d("boel/controllers/auth/kabiner", function(){ return i("boel/controllers/auth/kabiner.js");});
d("boel/routes/auth/kabiner", function(){ return i("boel/routes/auth/kabiner.js");});
d("boel/templates/auth/kabinestyring", function(){ return i("boel/templates/auth/kabinestyring.hbs");});
d("boel/controllers/auth/kabinestyring", function(){ return i("boel/controllers/auth/kabinestyring.js");});
d("boel/routes/auth/kabinestyring", function(){ return i("boel/routes/auth/kabinestyring.js");});
d("boel/templates/auth/kabinestyring/aftaler", function(){ return i("boel/templates/auth/kabinestyring/aftaler.hbs");});
d("boel/controllers/auth/kabinestyring/aftaler", function(){ return i("boel/controllers/auth/kabinestyring/aftaler.js");});
d("boel/routes/auth/kabinestyring/aftaler", function(){ return i("boel/routes/auth/kabinestyring/aftaler.js");});
d("boel/templates/auth/kabinestyring/aftaler/delete", function(){ return i("boel/templates/auth/kabinestyring/aftaler/delete.hbs");});
d("boel/controllers/auth/kabinestyring/aftaler/delete", function(){ return i("boel/controllers/auth/kabinestyring/aftaler/delete.js");});
d("boel/routes/auth/kabinestyring/aftaler/delete", function(){ return i("boel/routes/auth/kabinestyring/aftaler/delete.js");});
d("boel/templates/auth/kabinestyring/aftaler/edit", function(){ return i("boel/templates/auth/kabinestyring/aftaler/edit.hbs");});
d("boel/controllers/auth/kabinestyring/aftaler/edit", function(){ return i("boel/controllers/auth/kabinestyring/aftaler/edit.js");});
d("boel/routes/auth/kabinestyring/aftaler/edit", function(){ return i("boel/routes/auth/kabinestyring/aftaler/edit.js");});
d("boel/templates/auth/kabinestyring/aftaler/new", function(){ return i("boel/templates/auth/kabinestyring/aftaler/new.hbs");});
d("boel/controllers/auth/kabinestyring/aftaler/new", function(){ return i("boel/controllers/auth/kabinestyring/aftaler/new.js");});
d("boel/routes/auth/kabinestyring/aftaler/new", function(){ return i("boel/routes/auth/kabinestyring/aftaler/new.js");});
d("boel/templates/auth/kabinestyring/betalinger", function(){ return i("boel/templates/auth/kabinestyring/betalinger.hbs");});
d("boel/controllers/auth/kabinestyring/betalinger", function(){ return i("boel/controllers/auth/kabinestyring/betalinger.js");});
d("boel/routes/auth/kabinestyring/betalinger", function(){ return i("boel/routes/auth/kabinestyring/betalinger.js");});
d("boel/templates/auth/kabinestyring/betalinger/delete", function(){ return i("boel/templates/auth/kabinestyring/betalinger/delete.hbs");});
d("boel/controllers/auth/kabinestyring/betalinger/delete", function(){ return i("boel/controllers/auth/kabinestyring/betalinger/delete.js");});
d("boel/routes/auth/kabinestyring/betalinger/delete", function(){ return i("boel/routes/auth/kabinestyring/betalinger/delete.js");});
d("boel/templates/auth/kabinestyring/betalinger/edit", function(){ return i("boel/templates/auth/kabinestyring/betalinger/edit.hbs");});
d("boel/controllers/auth/kabinestyring/betalinger/edit", function(){ return i("boel/controllers/auth/kabinestyring/betalinger/edit.js");});
d("boel/routes/auth/kabinestyring/betalinger/edit", function(){ return i("boel/routes/auth/kabinestyring/betalinger/edit.js");});
d("boel/templates/auth/kabinestyring/betalinger/kvittering", function(){ return i("boel/templates/auth/kabinestyring/betalinger/kvittering.hbs");});
d("boel/controllers/auth/kabinestyring/betalinger/kvittering", function(){ return i("boel/controllers/auth/kabinestyring/betalinger/kvittering.js");});
d("boel/routes/auth/kabinestyring/betalinger/kvittering", function(){ return i("boel/routes/auth/kabinestyring/betalinger/kvittering.js");});
d("boel/templates/auth/kabinestyring/betalinger/new", function(){ return i("boel/templates/auth/kabinestyring/betalinger/new.hbs");});
d("boel/controllers/auth/kabinestyring/betalinger/new", function(){ return i("boel/controllers/auth/kabinestyring/betalinger/new.js");});
d("boel/routes/auth/kabinestyring/betalinger/new", function(){ return i("boel/routes/auth/kabinestyring/betalinger/new.js");});
d("boel/templates/auth/kabinestyring/journal", function(){ return i("boel/templates/auth/kabinestyring/journal.hbs");});
d("boel/controllers/auth/kabinestyring/journal", function(){ return i("boel/controllers/auth/kabinestyring/journal.js");});
d("boel/routes/auth/kabinestyring/journal", function(){ return i("boel/routes/auth/kabinestyring/journal.js");});
d("boel/templates/auth/kabinestyring/journal/delete", function(){ return i("boel/templates/auth/kabinestyring/journal/delete.hbs");});
d("boel/controllers/auth/kabinestyring/journal/delete", function(){ return i("boel/controllers/auth/kabinestyring/journal/delete.js");});
d("boel/routes/auth/kabinestyring/journal/delete", function(){ return i("boel/routes/auth/kabinestyring/journal/delete.js");});
d("boel/templates/auth/kabinestyring/journal/edit", function(){ return i("boel/templates/auth/kabinestyring/journal/edit.hbs");});
d("boel/controllers/auth/kabinestyring/journal/edit", function(){ return i("boel/controllers/auth/kabinestyring/journal/edit.js");});
d("boel/routes/auth/kabinestyring/journal/edit", function(){ return i("boel/routes/auth/kabinestyring/journal/edit.js");});
d("boel/templates/auth/kabinestyring/journal/new", function(){ return i("boel/templates/auth/kabinestyring/journal/new.hbs");});
d("boel/controllers/auth/kabinestyring/journal/new", function(){ return i("boel/controllers/auth/kabinestyring/journal/new.js");});
d("boel/routes/auth/kabinestyring/journal/new", function(){ return i("boel/routes/auth/kabinestyring/journal/new.js");});
d("boel/templates/auth/patienter", function(){ return i("boel/templates/auth/patienter.hbs");});
d("boel/controllers/auth/patienter", function(){ return i("boel/controllers/auth/patienter.js");});
d("boel/routes/auth/patienter", function(){ return i("boel/routes/auth/patienter.js");});
d("boel/templates/auth/patienter/aftaler", function(){ return i("boel/templates/auth/patienter/aftaler.hbs");});
d("boel/controllers/auth/patienter/aftaler", function(){ return i("boel/controllers/auth/patienter/aftaler.js");});
d("boel/routes/auth/patienter/aftaler", function(){ return i("boel/routes/auth/patienter/aftaler.js");});
d("boel/templates/auth/patienter/aftaler/delete", function(){ return i("boel/templates/auth/patienter/aftaler/delete.hbs");});
d("boel/controllers/auth/patienter/aftaler/delete", function(){ return i("boel/controllers/auth/patienter/aftaler/delete.js");});
d("boel/routes/auth/patienter/aftaler/delete", function(){ return i("boel/routes/auth/patienter/aftaler/delete.js");});
d("boel/templates/auth/patienter/aftaler/edit", function(){ return i("boel/templates/auth/patienter/aftaler/edit.hbs");});
d("boel/controllers/auth/patienter/aftaler/edit", function(){ return i("boel/controllers/auth/patienter/aftaler/edit.js");});
d("boel/routes/auth/patienter/aftaler/edit", function(){ return i("boel/routes/auth/patienter/aftaler/edit.js");});
d("boel/templates/auth/patienter/aftaler/new", function(){ return i("boel/templates/auth/patienter/aftaler/new.hbs");});
d("boel/controllers/auth/patienter/aftaler/new", function(){ return i("boel/controllers/auth/patienter/aftaler/new.js");});
d("boel/routes/auth/patienter/aftaler/new", function(){ return i("boel/routes/auth/patienter/aftaler/new.js");});
d("boel/templates/auth/patienter/betalinger", function(){ return i("boel/templates/auth/patienter/betalinger.hbs");});
d("boel/controllers/auth/patienter/betalinger", function(){ return i("boel/controllers/auth/patienter/betalinger.js");});
d("boel/routes/auth/patienter/betalinger", function(){ return i("boel/routes/auth/patienter/betalinger.js");});
d("boel/templates/auth/patienter/betalinger/delete", function(){ return i("boel/templates/auth/patienter/betalinger/delete.hbs");});
d("boel/controllers/auth/patienter/betalinger/delete", function(){ return i("boel/controllers/auth/patienter/betalinger/delete.js");});
d("boel/routes/auth/patienter/betalinger/delete", function(){ return i("boel/routes/auth/patienter/betalinger/delete.js");});
d("boel/templates/auth/patienter/betalinger/edit", function(){ return i("boel/templates/auth/patienter/betalinger/edit.hbs");});
d("boel/controllers/auth/patienter/betalinger/edit", function(){ return i("boel/controllers/auth/patienter/betalinger/edit.js");});
d("boel/routes/auth/patienter/betalinger/edit", function(){ return i("boel/routes/auth/patienter/betalinger/edit.js");});
d("boel/templates/auth/patienter/betalinger/kvittering", function(){ return i("boel/templates/auth/patienter/betalinger/kvittering.hbs");});
d("boel/controllers/auth/patienter/betalinger/kvittering", function(){ return i("boel/controllers/auth/patienter/betalinger/kvittering.js");});
d("boel/routes/auth/patienter/betalinger/kvittering", function(){ return i("boel/routes/auth/patienter/betalinger/kvittering.js");});
d("boel/templates/auth/patienter/betalinger/new", function(){ return i("boel/templates/auth/patienter/betalinger/new.hbs");});
d("boel/controllers/auth/patienter/betalinger/new", function(){ return i("boel/controllers/auth/patienter/betalinger/new.js");});
d("boel/routes/auth/patienter/betalinger/new", function(){ return i("boel/routes/auth/patienter/betalinger/new.js");});
d("boel/templates/auth/patienter/delete", function(){ return i("boel/templates/auth/patienter/delete.hbs");});
d("boel/controllers/auth/patienter/delete", function(){ return i("boel/controllers/auth/patienter/delete.js");});
d("boel/routes/auth/patienter/delete", function(){ return i("boel/routes/auth/patienter/delete.js");});
d("boel/templates/auth/patienter/edit", function(){ return i("boel/templates/auth/patienter/edit.hbs");});
d("boel/controllers/auth/patienter/edit", function(){ return i("boel/controllers/auth/patienter/edit.js");});
d("boel/routes/auth/patienter/edit", function(){ return i("boel/routes/auth/patienter/edit.js");});
d("boel/templates/auth/patienter/insurancecases", function(){ return i("boel/templates/auth/patienter/insurancecases.hbs");});
d("boel/controllers/auth/patienter/insurancecases", function(){ return i("boel/controllers/auth/patienter/insurancecases.js");});
d("boel/routes/auth/patienter/insurancecases", function(){ return i("boel/routes/auth/patienter/insurancecases.js");});
d("boel/templates/auth/patienter/insurancecases/delete", function(){ return i("boel/templates/auth/patienter/insurancecases/delete.hbs");});
d("boel/controllers/auth/patienter/insurancecases/delete", function(){ return i("boel/controllers/auth/patienter/insurancecases/delete.js");});
d("boel/routes/auth/patienter/insurancecases/delete", function(){ return i("boel/routes/auth/patienter/insurancecases/delete.js");});
d("boel/templates/auth/patienter/insurancecases/edit", function(){ return i("boel/templates/auth/patienter/insurancecases/edit.hbs");});
d("boel/controllers/auth/patienter/insurancecases/edit", function(){ return i("boel/controllers/auth/patienter/insurancecases/edit.js");});
d("boel/routes/auth/patienter/insurancecases/edit", function(){ return i("boel/routes/auth/patienter/insurancecases/edit.js");});
d("boel/templates/auth/patienter/insurancecases/new", function(){ return i("boel/templates/auth/patienter/insurancecases/new.hbs");});
d("boel/controllers/auth/patienter/insurancecases/new", function(){ return i("boel/controllers/auth/patienter/insurancecases/new.js");});
d("boel/routes/auth/patienter/insurancecases/new", function(){ return i("boel/routes/auth/patienter/insurancecases/new.js");});
d("boel/templates/auth/patienter/journal", function(){ return i("boel/templates/auth/patienter/journal.hbs");});
d("boel/controllers/auth/patienter/journal", function(){ return i("boel/controllers/auth/patienter/journal.js");});
d("boel/routes/auth/patienter/journal", function(){ return i("boel/routes/auth/patienter/journal.js");});
d("boel/templates/auth/patienter/journal/delete", function(){ return i("boel/templates/auth/patienter/journal/delete.hbs");});
d("boel/controllers/auth/patienter/journal/delete", function(){ return i("boel/controllers/auth/patienter/journal/delete.js");});
d("boel/routes/auth/patienter/journal/delete", function(){ return i("boel/routes/auth/patienter/journal/delete.js");});
d("boel/templates/auth/patienter/journal/edit", function(){ return i("boel/templates/auth/patienter/journal/edit.hbs");});
d("boel/controllers/auth/patienter/journal/edit", function(){ return i("boel/controllers/auth/patienter/journal/edit.js");});
d("boel/routes/auth/patienter/journal/edit", function(){ return i("boel/routes/auth/patienter/journal/edit.js");});
d("boel/templates/auth/patienter/journal/new", function(){ return i("boel/templates/auth/patienter/journal/new.hbs");});
d("boel/controllers/auth/patienter/journal/new", function(){ return i("boel/controllers/auth/patienter/journal/new.js");});
d("boel/routes/auth/patienter/journal/new", function(){ return i("boel/routes/auth/patienter/journal/new.js");});
d("boel/templates/auth/patienter/kontakt", function(){ return i("boel/templates/auth/patienter/kontakt.hbs");});
d("boel/controllers/auth/patienter/kontakt", function(){ return i("boel/controllers/auth/patienter/kontakt.js");});
d("boel/routes/auth/patienter/kontakt", function(){ return i("boel/routes/auth/patienter/kontakt.js");});
d("boel/templates/auth/patienter/new", function(){ return i("boel/templates/auth/patienter/new.hbs");});
d("boel/controllers/auth/patienter/new", function(){ return i("boel/controllers/auth/patienter/new.js");});
d("boel/routes/auth/patienter/new", function(){ return i("boel/routes/auth/patienter/new.js");});
d("boel/templates/auth/patienter/stamdata", function(){ return i("boel/templates/auth/patienter/stamdata.hbs");});
d("boel/controllers/auth/patienter/stamdata", function(){ return i("boel/controllers/auth/patienter/stamdata.js");});
d("boel/routes/auth/patienter/stamdata", function(){ return i("boel/routes/auth/patienter/stamdata.js");});
d("boel/templates/auth/skiftpassword", function(){ return i("boel/templates/auth/skiftpassword.hbs");});
d("boel/controllers/auth/skiftpassword", function(){ return i("boel/controllers/auth/skiftpassword.js");});
d("boel/routes/auth/skiftpassword", function(){ return i("boel/routes/auth/skiftpassword.js");});
d("boel/templates/auth/superbruger", function(){ return i("boel/templates/auth/superbruger.hbs");});
d("boel/routes/auth/superbruger", function(){ return i("boel/routes/auth/superbruger.js");});
d("boel/templates/auth/superbruger/bills", function(){ return i("boel/templates/auth/superbruger/bills.hbs");});
d("boel/controllers/auth/superbruger/bills", function(){ return i("boel/controllers/auth/superbruger/bills.js");});
d("boel/routes/auth/superbruger/bills", function(){ return i("boel/routes/auth/superbruger/bills.js");});
d("boel/templates/auth/superbruger/indstillinger", function(){ return i("boel/templates/auth/superbruger/indstillinger.hbs");});
d("boel/controllers/auth/superbruger/indstillinger", function(){ return i("boel/controllers/auth/superbruger/indstillinger.js");});
d("boel/routes/auth/superbruger/indstillinger", function(){ return i("boel/routes/auth/superbruger/indstillinger.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/closedperiods/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/closedperiods/new.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/closedperiods/new.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/new.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/new.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/afdelinger/openinghours/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/afdelinger/openinghours/new.js");});
d("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/afdelinger/openinghours/new.js");});
d("boel/templates/auth/superbruger/indstillinger/aftalestatus", function(){ return i("boel/templates/auth/superbruger/indstillinger/aftalestatus.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/aftalestatus", function(){ return i("boel/controllers/auth/superbruger/indstillinger/aftalestatus.js");});
d("boel/routes/auth/superbruger/indstillinger/aftalestatus", function(){ return i("boel/routes/auth/superbruger/indstillinger/aftalestatus.js");});
d("boel/templates/auth/superbruger/indstillinger/aftalestatus/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/aftalestatus/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/aftalestatus/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/aftalestatus/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/aftalestatus/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/aftalestatus/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/aftalestatus/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/aftalestatus/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/aftalestatus/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/aftalestatus/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/aftalestatus/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/aftalestatus/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/aftalestatus/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/aftalestatus/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/aftalestatus/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/aftalestatus/new.js");});
d("boel/routes/auth/superbruger/indstillinger/aftalestatus/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/aftalestatus/new.js");});
d("boel/templates/auth/superbruger/indstillinger/artikler", function(){ return i("boel/templates/auth/superbruger/indstillinger/artikler.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/artikler", function(){ return i("boel/controllers/auth/superbruger/indstillinger/artikler.js");});
d("boel/routes/auth/superbruger/indstillinger/artikler", function(){ return i("boel/routes/auth/superbruger/indstillinger/artikler.js");});
d("boel/templates/auth/superbruger/indstillinger/artikler/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/artikler/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/artikler/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/artikler/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/artikler/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/artikler/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/artikler/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/artikler/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/artikler/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/artikler/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/artikler/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/artikler/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/artikler/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/artikler/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/artikler/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/artikler/new.js");});
d("boel/routes/auth/superbruger/indstillinger/artikler/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/artikler/new.js");});
d("boel/templates/auth/superbruger/indstillinger/behandlinger", function(){ return i("boel/templates/auth/superbruger/indstillinger/behandlinger.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/behandlinger", function(){ return i("boel/controllers/auth/superbruger/indstillinger/behandlinger.js");});
d("boel/routes/auth/superbruger/indstillinger/behandlinger", function(){ return i("boel/routes/auth/superbruger/indstillinger/behandlinger.js");});
d("boel/templates/auth/superbruger/indstillinger/behandlinger/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/behandlinger/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/behandlinger/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/behandlinger/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/behandlinger/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/behandlinger/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/behandlinger/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/behandlinger/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/behandlinger/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/behandlinger/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/behandlinger/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/behandlinger/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/behandlinger/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/behandlinger/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/behandlinger/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/behandlinger/new.js");});
d("boel/routes/auth/superbruger/indstillinger/behandlinger/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/behandlinger/new.js");});
d("boel/templates/auth/superbruger/indstillinger/brugere", function(){ return i("boel/templates/auth/superbruger/indstillinger/brugere.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/brugere", function(){ return i("boel/controllers/auth/superbruger/indstillinger/brugere.js");});
d("boel/routes/auth/superbruger/indstillinger/brugere", function(){ return i("boel/routes/auth/superbruger/indstillinger/brugere.js");});
d("boel/templates/auth/superbruger/indstillinger/brugere/associations", function(){ return i("boel/templates/auth/superbruger/indstillinger/brugere/associations.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/brugere/associations", function(){ return i("boel/controllers/auth/superbruger/indstillinger/brugere/associations.js");});
d("boel/routes/auth/superbruger/indstillinger/brugere/associations", function(){ return i("boel/routes/auth/superbruger/indstillinger/brugere/associations.js");});
d("boel/templates/auth/superbruger/indstillinger/brugere/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/brugere/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/brugere/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/brugere/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/brugere/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/brugere/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/brugere/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/brugere/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/brugere/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/brugere/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/brugere/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/brugere/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/brugere/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/brugere/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/brugere/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/brugere/new.js");});
d("boel/routes/auth/superbruger/indstillinger/brugere/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/brugere/new.js");});
d("boel/templates/auth/superbruger/indstillinger/handlinger", function(){ return i("boel/templates/auth/superbruger/indstillinger/handlinger.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/handlinger", function(){ return i("boel/controllers/auth/superbruger/indstillinger/handlinger.js");});
d("boel/routes/auth/superbruger/indstillinger/handlinger", function(){ return i("boel/routes/auth/superbruger/indstillinger/handlinger.js");});
d("boel/templates/auth/superbruger/indstillinger/handlinger/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/handlinger/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/handlinger/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/handlinger/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/handlinger/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/handlinger/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/handlinger/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/handlinger/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/handlinger/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/handlinger/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/handlinger/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/handlinger/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/handlinger/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/handlinger/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/handlinger/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/handlinger/new.js");});
d("boel/routes/auth/superbruger/indstillinger/handlinger/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/handlinger/new.js");});
d("boel/templates/auth/superbruger/indstillinger/journaltype", function(){ return i("boel/templates/auth/superbruger/indstillinger/journaltype.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/journaltype", function(){ return i("boel/controllers/auth/superbruger/indstillinger/journaltype.js");});
d("boel/routes/auth/superbruger/indstillinger/journaltype", function(){ return i("boel/routes/auth/superbruger/indstillinger/journaltype.js");});
d("boel/templates/auth/superbruger/indstillinger/journaltype/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/journaltype/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/journaltype/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/journaltype/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/journaltype/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/journaltype/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/journaltype/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/journaltype/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/journaltype/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/journaltype/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/journaltype/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/journaltype/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/journaltype/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/journaltype/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/journaltype/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/journaltype/new.js");});
d("boel/routes/auth/superbruger/indstillinger/journaltype/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/journaltype/new.js");});
d("boel/templates/auth/superbruger/indstillinger/kabiner", function(){ return i("boel/templates/auth/superbruger/indstillinger/kabiner.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/kabiner", function(){ return i("boel/controllers/auth/superbruger/indstillinger/kabiner.js");});
d("boel/routes/auth/superbruger/indstillinger/kabiner", function(){ return i("boel/routes/auth/superbruger/indstillinger/kabiner.js");});
d("boel/templates/auth/superbruger/indstillinger/kabiner/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/kabiner/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/kabiner/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/kabiner/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/kabiner/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/kabiner/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/kabiner/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/kabiner/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/kabiner/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/kabiner/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/kabiner/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/kabiner/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/kabiner/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/kabiner/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/kabiner/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/kabiner/new.js");});
d("boel/routes/auth/superbruger/indstillinger/kabiner/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/kabiner/new.js");});
d("boel/templates/auth/superbruger/indstillinger/link", function(){ return i("boel/templates/auth/superbruger/indstillinger/link.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/link", function(){ return i("boel/controllers/auth/superbruger/indstillinger/link.js");});
d("boel/routes/auth/superbruger/indstillinger/link", function(){ return i("boel/routes/auth/superbruger/indstillinger/link.js");});
d("boel/templates/auth/superbruger/indstillinger/link/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/link/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/link/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/link/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/link/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/link/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/link/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/link/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/link/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/link/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/link/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/link/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/link/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/link/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/link/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/link/new.js");});
d("boel/routes/auth/superbruger/indstillinger/link/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/link/new.js");});
d("boel/templates/auth/superbruger/indstillinger/manual", function(){ return i("boel/templates/auth/superbruger/indstillinger/manual.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/manual", function(){ return i("boel/controllers/auth/superbruger/indstillinger/manual.js");});
d("boel/routes/auth/superbruger/indstillinger/manual", function(){ return i("boel/routes/auth/superbruger/indstillinger/manual.js");});
d("boel/templates/auth/superbruger/indstillinger/manual/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/manual/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/manual/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/manual/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/manual/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/manual/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/manual/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/manual/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/manual/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/manual/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/manual/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/manual/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/manual/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/manual/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/manual/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/manual/new.js");});
d("boel/routes/auth/superbruger/indstillinger/manual/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/manual/new.js");});
d("boel/templates/auth/superbruger/indstillinger/messages", function(){ return i("boel/templates/auth/superbruger/indstillinger/messages.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/messages", function(){ return i("boel/controllers/auth/superbruger/indstillinger/messages.js");});
d("boel/routes/auth/superbruger/indstillinger/messages", function(){ return i("boel/routes/auth/superbruger/indstillinger/messages.js");});
d("boel/templates/auth/superbruger/indstillinger/messages/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/messages/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/messages/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/messages/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/messages/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/messages/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/messages/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/messages/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/messages/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/messages/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/messages/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/messages/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/messages/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/messages/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/messages/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/messages/new.js");});
d("boel/routes/auth/superbruger/indstillinger/messages/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/messages/new.js");});
d("boel/templates/auth/superbruger/indstillinger/problemer", function(){ return i("boel/templates/auth/superbruger/indstillinger/problemer.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/problemer", function(){ return i("boel/controllers/auth/superbruger/indstillinger/problemer.js");});
d("boel/routes/auth/superbruger/indstillinger/problemer", function(){ return i("boel/routes/auth/superbruger/indstillinger/problemer.js");});
d("boel/templates/auth/superbruger/indstillinger/problemer/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/problemer/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/problemer/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/problemer/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/problemer/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/problemer/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/problemer/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/problemer/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/problemer/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/problemer/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/problemer/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/problemer/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/problemer/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/problemer/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/problemer/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/problemer/new.js");});
d("boel/routes/auth/superbruger/indstillinger/problemer/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/problemer/new.js");});
d("boel/templates/auth/superbruger/indstillinger/settings", function(){ return i("boel/templates/auth/superbruger/indstillinger/settings.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/settings", function(){ return i("boel/controllers/auth/superbruger/indstillinger/settings.js");});
d("boel/routes/auth/superbruger/indstillinger/settings", function(){ return i("boel/routes/auth/superbruger/indstillinger/settings.js");});
d("boel/templates/auth/superbruger/indstillinger/settings/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/settings/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/settings/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/settings/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/settings/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/settings/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/settings/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/settings/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/settings/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/settings/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/settings/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/settings/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/settings/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/settings/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/settings/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/settings/new.js");});
d("boel/routes/auth/superbruger/indstillinger/settings/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/settings/new.js");});
d("boel/templates/auth/superbruger/indstillinger/therapists", function(){ return i("boel/templates/auth/superbruger/indstillinger/therapists.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/therapists", function(){ return i("boel/controllers/auth/superbruger/indstillinger/therapists.js");});
d("boel/routes/auth/superbruger/indstillinger/therapists", function(){ return i("boel/routes/auth/superbruger/indstillinger/therapists.js");});
d("boel/templates/auth/superbruger/indstillinger/therapists/delete", function(){ return i("boel/templates/auth/superbruger/indstillinger/therapists/delete.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/therapists/delete", function(){ return i("boel/controllers/auth/superbruger/indstillinger/therapists/delete.js");});
d("boel/routes/auth/superbruger/indstillinger/therapists/delete", function(){ return i("boel/routes/auth/superbruger/indstillinger/therapists/delete.js");});
d("boel/templates/auth/superbruger/indstillinger/therapists/edit", function(){ return i("boel/templates/auth/superbruger/indstillinger/therapists/edit.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/therapists/edit", function(){ return i("boel/controllers/auth/superbruger/indstillinger/therapists/edit.js");});
d("boel/routes/auth/superbruger/indstillinger/therapists/edit", function(){ return i("boel/routes/auth/superbruger/indstillinger/therapists/edit.js");});
d("boel/templates/auth/superbruger/indstillinger/therapists/new", function(){ return i("boel/templates/auth/superbruger/indstillinger/therapists/new.hbs");});
d("boel/controllers/auth/superbruger/indstillinger/therapists/new", function(){ return i("boel/controllers/auth/superbruger/indstillinger/therapists/new.js");});
d("boel/routes/auth/superbruger/indstillinger/therapists/new", function(){ return i("boel/routes/auth/superbruger/indstillinger/therapists/new.js");});
d("boel/templates/auth/superbruger/insurancecases/delete", function(){ return i("boel/templates/auth/superbruger/insurancecases/delete.hbs");});
d("boel/controllers/auth/superbruger/insurancecases/delete", function(){ return i("boel/controllers/auth/superbruger/insurancecases/delete.js");});
d("boel/routes/auth/superbruger/insurancecases/delete", function(){ return i("boel/routes/auth/superbruger/insurancecases/delete.js");});
d("boel/templates/auth/superbruger/insurancecases/edit", function(){ return i("boel/templates/auth/superbruger/insurancecases/edit.hbs");});
d("boel/controllers/auth/superbruger/insurancecases/edit", function(){ return i("boel/controllers/auth/superbruger/insurancecases/edit.js");});
d("boel/routes/auth/superbruger/insurancecases/edit", function(){ return i("boel/routes/auth/superbruger/insurancecases/edit.js");});
d("boel/templates/auth/superbruger/insurancecases/new", function(){ return i("boel/templates/auth/superbruger/insurancecases/new.hbs");});
d("boel/controllers/auth/superbruger/insurancecases/new", function(){ return i("boel/controllers/auth/superbruger/insurancecases/new.js");});
d("boel/routes/auth/superbruger/insurancecases/new", function(){ return i("boel/routes/auth/superbruger/insurancecases/new.js");});
d("boel/templates/auth/superbruger/insurancecompanies", function(){ return i("boel/templates/auth/superbruger/insurancecompanies.hbs");});
d("boel/controllers/auth/superbruger/insurancecompanies", function(){ return i("boel/controllers/auth/superbruger/insurancecompanies.js");});
d("boel/routes/auth/superbruger/insurancecompanies", function(){ return i("boel/routes/auth/superbruger/insurancecompanies.js");});
d("boel/templates/auth/superbruger/insurancecompanies/delete", function(){ return i("boel/templates/auth/superbruger/insurancecompanies/delete.hbs");});
d("boel/controllers/auth/superbruger/insurancecompanies/delete", function(){ return i("boel/controllers/auth/superbruger/insurancecompanies/delete.js");});
d("boel/routes/auth/superbruger/insurancecompanies/delete", function(){ return i("boel/routes/auth/superbruger/insurancecompanies/delete.js");});
d("boel/templates/auth/superbruger/insurancecompanies/edit", function(){ return i("boel/templates/auth/superbruger/insurancecompanies/edit.hbs");});
d("boel/controllers/auth/superbruger/insurancecompanies/edit", function(){ return i("boel/controllers/auth/superbruger/insurancecompanies/edit.js");});
d("boel/routes/auth/superbruger/insurancecompanies/edit", function(){ return i("boel/routes/auth/superbruger/insurancecompanies/edit.js");});
d("boel/templates/auth/superbruger/insurancecompanies/new", function(){ return i("boel/templates/auth/superbruger/insurancecompanies/new.hbs");});
d("boel/controllers/auth/superbruger/insurancecompanies/new", function(){ return i("boel/controllers/auth/superbruger/insurancecompanies/new.js");});
d("boel/routes/auth/superbruger/insurancecompanies/new", function(){ return i("boel/routes/auth/superbruger/insurancecompanies/new.js");});
d("boel/templates/auth/superbruger/log", function(){ return i("boel/templates/auth/superbruger/log.hbs");});
d("boel/controllers/auth/superbruger/log", function(){ return i("boel/controllers/auth/superbruger/log.js");});
d("boel/routes/auth/superbruger/log", function(){ return i("boel/routes/auth/superbruger/log.js");});
d("boel/templates/auth/superbruger/rapporter", function(){ return i("boel/templates/auth/superbruger/rapporter.hbs");});
d("boel/controllers/auth/superbruger/rapporter", function(){ return i("boel/controllers/auth/superbruger/rapporter.js");});
d("boel/routes/auth/superbruger/rapporter", function(){ return i("boel/routes/auth/superbruger/rapporter.js");});
d("boel/templates/auth/superbruger/rapporter/diagrammer", function(){ return i("boel/templates/auth/superbruger/rapporter/diagrammer.hbs");});
d("boel/controllers/auth/superbruger/rapporter/diagrammer", function(){ return i("boel/controllers/auth/superbruger/rapporter/diagrammer.js");});
d("boel/routes/auth/superbruger/rapporter/diagrammer", function(){ return i("boel/routes/auth/superbruger/rapporter/diagrammer.js");});
d("boel/templates/auth/superbruger/rapporter/eksport", function(){ return i("boel/templates/auth/superbruger/rapporter/eksport.hbs");});
d("boel/controllers/auth/superbruger/rapporter/eksport", function(){ return i("boel/controllers/auth/superbruger/rapporter/eksport.js");});
d("boel/routes/auth/superbruger/rapporter/eksport", function(){ return i("boel/routes/auth/superbruger/rapporter/eksport.js");});
d("boel/templates/auth/sygeforsikringendanmark", function(){ return i("boel/templates/auth/sygeforsikringendanmark.hbs");});
d("boel/controllers/auth/sygeforsikringendanmark", function(){ return i("boel/controllers/auth/sygeforsikringendanmark.js");});
d("boel/routes/auth/sygeforsikringendanmark", function(){ return i("boel/routes/auth/sygeforsikringendanmark.js");});
d("boel/templates/auth/udlejning", function(){ return i("boel/templates/auth/udlejning.hbs");});
d("boel/controllers/auth/udlejning", function(){ return i("boel/controllers/auth/udlejning.js");});
d("boel/routes/auth/udlejning", function(){ return i("boel/routes/auth/udlejning.js");});
d("boel/templates/auth/udlejning/delete", function(){ return i("boel/templates/auth/udlejning/delete.hbs");});
d("boel/controllers/auth/udlejning/delete", function(){ return i("boel/controllers/auth/udlejning/delete.js");});
d("boel/routes/auth/udlejning/delete", function(){ return i("boel/routes/auth/udlejning/delete.js");});
d("boel/templates/auth/udlejning/edit", function(){ return i("boel/templates/auth/udlejning/edit.hbs");});
d("boel/controllers/auth/udlejning/edit", function(){ return i("boel/controllers/auth/udlejning/edit.js");});
d("boel/routes/auth/udlejning/edit", function(){ return i("boel/routes/auth/udlejning/edit.js");});
d("boel/templates/auth/udlejning/key", function(){ return i("boel/templates/auth/udlejning/key.hbs");});
d("boel/controllers/auth/udlejning/key", function(){ return i("boel/controllers/auth/udlejning/key.js");});
d("boel/routes/auth/udlejning/key", function(){ return i("boel/routes/auth/udlejning/key.js");});
d("boel/templates/auth/udlejning/new", function(){ return i("boel/templates/auth/udlejning/new.hbs");});
d("boel/controllers/auth/udlejning/new", function(){ return i("boel/controllers/auth/udlejning/new.js");});
d("boel/routes/auth/udlejning/new", function(){ return i("boel/routes/auth/udlejning/new.js");});
d("boel/templates/auth/changeoutlet", function(){ return i("boel/templates/auth/changeoutlet.hbs");});
d("boel/routes/auth/changeoutlet", function(){ return i("boel/routes/auth/changeoutlet.js");});
d("boel/templates/auth/index", function(){ return i("boel/templates/auth/index.hbs");});
d("boel/routes/auth/index", function(){ return i("boel/routes/auth/index.js");});
d("boel/templates/auth/logout", function(){ return i("boel/templates/auth/logout.hbs");});
d("boel/routes/auth/logout", function(){ return i("boel/routes/auth/logout.js");});
d("boel/templates/error", function(){ return i("boel/templates/error.hbs");});
d("boel/controllers/error", function(){ return i("boel/controllers/error.js");});
d("boel/templates/help", function(){ return i("boel/templates/help.hbs");});
d("boel/controllers/help", function(){ return i("boel/controllers/help.js");});
d("boel/templates/help/view", function(){ return i("boel/templates/help/view.hbs");});
d("boel/controllers/help/view", function(){ return i("boel/controllers/help/view.js");});
d("boel/routes/help/view", function(){ return i("boel/routes/help/view.js");});
d("boel/templates/help/highscore", function(){ return i("boel/templates/help/highscore.hbs");});
d("boel/routes/help/highscore", function(){ return i("boel/routes/help/highscore.js");});
d("boel/templates/help/viewguide", function(){ return i("boel/templates/help/viewguide.hbs");});
d("boel/routes/help/viewguide", function(){ return i("boel/routes/help/viewguide.js");});
d("boel/templates/login", function(){ return i("boel/templates/login.hbs");});
d("boel/controllers/login", function(){ return i("boel/controllers/login.js");});
d("boel/routes/login", function(){ return i("boel/routes/login.js");});
d("boel/templates/index", function(){ return i("boel/templates/index.hbs");});
d("boel/routes/index", function(){ return i("boel/routes/index.js");});

  if (macroCondition(getGlobalConfig().fastboot?.isRunning)) {
d("boel/instance-initializers/setup-fetch", function(){ return i("boel/instance-initializers/setup-fetch.js");});
  }




if (!runningTests) {
  i("../app").default.create({"VisBrugerMenu":true,"VisSkiftpassword":true,"VisSkiftindstillinger":false,"VisTitleLogo":false,"MENU_Administration":true,"MENU_Trimme":true,"MENU_Log":true,"MENU_Brugere":true,"MENU_Beskeder":true,"MENU_Manualer":true,"MENU_Artikler":true,"MENU_Link":true,"USER_Fullname":false,"USER_Rolle":true,"USER_Telefon":false,"USER_Kodebrev":false,"MENU_Rapporter":true,"MENU_Bills":true,"MENU_Insurance_companies":true,"MENU_visGrafer":true,"Appointment_State_Gammel_Id":3,"Appointment_State_Ny_Id":2,"Appointment_State_Normal_Id":1,"Discount_Threshold":10,"Diagrams_Default_Period_Length":30,"Logs_Default_Period_Length":7,"Kabinestyring_update_interval":10000,"Allow_Delete_Patient":false,"Allow_Delete_Outlet":false,"Allow_Delete_User":false,"Allow_Delete_Payment":false,"Allow_Create_Payment":false,"Allow_Delete_Insurance_Company":false,"Normal_User_Start_Page":"auth.aftaler","Superuser_User_Start_Page":"auth.aftaler","Admin_User_Start_Page":"auth.aftaler","DEBUG":true,"ECONOMIC":false,"APILINK":"https://boel.ecitsoftware.com/v1/api/","SIGNALRENDPOINT":"https://boel.ecitsoftware.com/v1","SIGNALRENDPOINTHUB":"https://boel.ecitsoftware.dk/v1","RETRYINTERVALSECONDS":5000,"Scheduler_Calendar_Width":7,"CookieSameSiteMode":"Strict","ShowInfobuttons":false,"ShowNotifications":false,"host":"https://boel.ecitsoftware.com","namespace":"v1/api","namespaceRoot":"https://boel.ecitsoftware.com","serverTokenEndpoint":"https://boel.ecitsoftware.com/v1/token","serverTokenRevocationEndpoint":"https://boel.ecitsoftware.com/v1/api/auth/logout","name":"boel","version":"1.8.16"});
}

