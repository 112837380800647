import { setOwner, getOwner } from '@ember/application';
import { setRecordIdentifier, StoreMap, setCacheFor } from '@ember-data/store/-private';
import { g as getModelFactory } from "./schema-provider-BtvdNYTK.js";
import { n as normalizeModelName } from "./model-CAqu4cYw.js";
import { macroCondition, getGlobalConfig } from '@embroider/macros';
function instantiateRecord(identifier, createRecordArgs) {
  const type = identifier.type;
  const cache = this.cache;
  // TODO deprecate allowing unknown args setting
  const createOptions = {
    _createProps: createRecordArgs,
    // TODO @deprecate consider deprecating accessing record properties during init which the below is necessary for
    _secretInit: {
      identifier,
      cache,
      store: this,
      cb: secretInit
    }
  };

  // ensure that `getOwner(this)` works inside a model instance
  setOwner(createOptions, getOwner(this));
  const factory = getModelFactory(this, type);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`No model was found for '${type}'`);
    }
  })(factory) : {};
  return factory.class.create(createOptions);
}
function teardownRecord(record) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`expected to receive an instance of Model from @ember-data/model. If using a custom model make sure you implement teardownRecord`);
    }
  })('destroy' in record) : {};
  record.destroy();
}
function modelFor(modelName) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Attempted to call store.modelFor(), but the store instance has already been destroyed.`);
    }
  })(!this.isDestroyed && !this.isDestroying) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You need to pass a model name to the store's modelFor method`);
    }
  })(modelName) : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Please pass a proper model name to the store's modelFor method`);
    }
  })(typeof modelName === 'string' && modelName.length) : {};
  const type = normalizeModelName(modelName);
  const maybeFactory = getModelFactory(this, type);
  const klass = maybeFactory && maybeFactory.class ? maybeFactory.class : null;
  const ignoreType = !klass || !klass.isModel || this._forceShim;
  if (!ignoreType) {
    return klass;
  }
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`No model was found for '${type}' and no schema handles the type`);
    }
  })(this.schema.hasResource({
    type
  })) : {};
}
function secretInit(record, cache, identifier, store) {
  setRecordIdentifier(record, identifier);
  StoreMap.set(record, store);
  setCacheFor(record, cache);
}
export { instantiateRecord as i, modelFor as m, teardownRecord as t };