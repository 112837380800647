import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

export default class UserModel extends Model {
  @service store;

  @attr('string', { defaultValue: '' })
  userRole;

  @attr('boolean', { defaultValue: false })
  treats;

  @attr('boolean', { defaultValue: false })
  disabled;

  @attr('string', { defaultValue: '' })
  userName;

  @attr('string', { defaultValue: '' })
  firstname;

  @attr('string', { defaultValue: '' })
  lastname;

  @attr('string', { defaultValue: '' })
  startroute;

  @attr('string', { defaultValue: '' })
  startSide;

  @attr('string', { defaultValue: '' })
  email;

  @attr('string', { defaultValue: '' })
  smartphone;

  @attr('boolean', { defaultValue: false })
  Administrator;

  @attr('boolean', { defaultValue: false })
  isSuperuser;

  @attr('number', { defaultValue: false })
  outletid;

  @hasMany('association', {
    inverse: null,
    async: false,
  })
  associations;

  @belongsTo('outlet', {
    inverse: null,
    async: false,
  })
  outlet;

  @attr
  created;

  @attr
  updated;
}
