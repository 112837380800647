import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
import { tracked } from '@glimmer/tracking';
import
  {
    validatePresence,
    validateNumber,
    validateLength,
    validateFormat,
  } from 'ember-changeset-validations/validators';
import fetch from 'fetch';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
    isForbiddenResponse,
    isBadRequestResponse,
    isAbortError,
  } from 'ember-fetch/errors';

import { debug } from '@ember/debug';

let rolleListe = ['Normal', 'Superbruger'];

export default class AuthSuperbrugerIndstillingerBrugereEditController extends Controller
{
  validations = {
    smartphone: [
      validateNumber({ allowBlank: true }),
      validateLength({ min: 8, max: 8, allowBlank: true }),
    ],
    outlet: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    email: [
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: this.intl.t('validations.email', {
          description: 'Emailen',
        }),
      }),
    ],
  };
  @service router;
  @service notifications;
  @service session;
  @service currentUser;
  @service intl;
  @service router;

  @tracked serverError = [];
  @tracked errorMessage = '';

  @tracked chosenOutlet;

  get ErAdministrator()
  {
    return this.currentUser.user.userRole === 'Administrator';
  }
  get IngenRettighed()
  {
    let IngenRettighed = true;
    if (this.currentUser.user.userRole != 'Normal')
    {
      IngenRettighed = false;

      if (
        this.currentUser.user.userRole == 'Superuser' &&
        this.model.editRecord.userRole === 'Administrator'
      )
      {
        IngenRettighed = true;
      }
    }
    return IngenRettighed;
  }
  @action
  choose(outlet)
  {
    this.chosenOutlet = outlet;
  }
  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    this.serverError = [];
    const id = changeset.get('id');
    const user = changeset.get('userName');
    const firstname = changeset.get('firstname');
    const lastname = changeset.get('lastname');
    const role = changeset.get('userRole') || 'Normal';
    const email = changeset.get('email');
    const disabled = changeset.get('disabled');
    let outletid = -1;
    const outlet = changeset.get('outlet');
    if (outlet != null)
    {
      outletid = outlet.id;
    }
    var headers = {
      'Content-Type': 'application/json',
    };
    const Is_Valid = user !== '' && outletid != -1;

    if (Is_Valid)
    {
      if (this.session.isAuthenticated)
      {
        headers[
          'Authorization'
        ] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      yield fetch(ENV.APP.host + '/' + ENV.APP.namespace + '/auth/SetAccount', {
        headers: headers,
        method: 'POST',
        cache: 'no-cache',
        body: JSON.stringify({
          Id: id,
          UserName: user,
          Password: '',
          Email: email,
          Firstname: firstname,
          Lastname: lastname,
          Outletid: outletid,
          UserRole: role,
          Disabled: disabled
        }),
      })
        .then((response) =>
        {
          if (response.ok)
          {
            if (ENV.APP.ShowNotifications) {
              this.notifications.success(this.intl.t('retbruger.editok'));
            }
            this.errorMessage = '';
            this.router.transitionTo('auth.superbruger.indstillinger.brugere', {
              queryParams: { refresh: Date.now() },
            });
          } else if (isUnauthorizedResponse(response))
          {
            this.notifications.error(this.intl.t('retbruger.unauthorized'));
            this.router.transitionTo('login');
          } else if (isForbiddenResponse(response))
          {
            this.notifications.error(this.intl.t('retbruger.forbidden'));
          } else if (isBadRequestResponse(response))
          {
            response.json().then((data) =>
            {
              const errorList = [];
              Object.keys(data).forEach(function (key)
              {
                if (!Object.hasOwnProperty(key))
                {
                  var value = data[key];
                  errorList.push(value);
                }
              });
              this.serverError = errorList;
            });
          } else if (isServerErrorResponse(response))
          {
            this.notifications.error(this.intl.t('retbruger.servererror'));
          } else if (isAbortError(response))
          {
            this.notifications.error(this.intl.t('retbruger.servererror'));
          }
        })
        .catch((error) =>
        {
          this.notifications.error(this.intl.t('retbruger.catch'));
          debug('Fejl under fetch: ');
          debug(error);
        });
    }
  }
  @action
  cancel()
  {
    this.router.transitionTo('auth.superbruger.indstillinger.brugere');
  }
}
