import { deprecate } from '@ember/debug';
import { dasherize } from '@ember-data/request-utils/string';
import { macroCondition, getGlobalConfig, dependencySatisfies, importSync } from '@embroider/macros';
import EmberObject, { computed, get } from '@ember/object';
import { recordIdentifierFor as recordIdentifierFor$1, storeFor as storeFor$1 } from '@ember-data/store';
import { LiveArray, MUTATE, SOURCE, recordIdentifierFor, ARRAY_SIGNAL, notifyArray, isStableIdentifier, storeFor, peekCache, fastPush, coerceId } from '@ember-data/store/-private';
import { cached, compat } from '@ember-data/tracking';
import { addToTransaction, defineSignal, peekSignal, getSignal, subscribe } from '@ember-data/tracking/-private';
import { RecordStore } from '@warp-drive/core-types/symbols';
import { A } from '@ember/array';
import ArrayProxy from '@ember/array/proxy';
import { mapBy, not } from '@ember/object/computed';
import { upgradeStore } from '@ember-data/legacy-compat/-private';
import { getOrSetGlobal } from '@warp-drive/core-types/-private';
import PromiseProxyMixin from '@ember/object/promise-proxy-mixin';
import ObjectProxy from '@ember/object/proxy';
import { cacheFor } from '@ember/object/internals';
function isElementDescriptor(args) {
  const [maybeTarget, maybeKey, maybeDesc] = args;
  return (
    // Ensure we have the right number of args
    args.length === 3 && (
    // Make sure the target is a class or object (prototype)
    typeof maybeTarget === 'function' || typeof maybeTarget === 'object' && maybeTarget !== null) &&
    // Make sure the key is a string
    typeof maybeKey === 'string' && (
    // Make sure the descriptor is the right shape
    typeof maybeDesc === 'object' && maybeDesc !== null && 'enumerable' in maybeDesc && 'configurable' in maybeDesc ||
    // TS compatibility
    maybeDesc === undefined)
  );
}
function normalizeModelName(type) {
  if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_NON_STRICT_TYPES)) {
    const result = dasherize(type);
    deprecate(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
      id: 'ember-data:deprecate-non-strict-types',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.3'
      }
    });
    return result;
  }
  return type;
}

/**
  @module @ember-data/store
*/
/**
  A `ManyArray` is a `MutableArray` that represents the contents of a has-many
  relationship.

  The `ManyArray` is instantiated lazily the first time the relationship is
  requested.

  This class is not intended to be directly instantiated by consuming applications.

  ### Inverses

  Often, the relationships in Ember Data applications will have
  an inverse. For example, imagine the following models are
  defined:

  ```app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class PostModel extends Model {
    @hasMany('comment') comments;
  }
  ```

  ```app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class CommentModel extends Model {
    @belongsTo('post') post;
  }
  ```

  If you created a new instance of `Post` and added
  a `Comment` record to its `comments` has-many
  relationship, you would expect the comment's `post`
  property to be set to the post that contained
  the has-many.

  We call the record to which a relationship belongs-to the
  relationship's _owner_.

  @class ManyArray
  @public
*/
class RelatedCollection extends LiveArray {
  /**
    The loading state of this array
     @property {Boolean} isLoaded
    @public
    */

  /**
    `true` if the relationship is polymorphic, `false` otherwise.
     @property {Boolean} isPolymorphic
    @private
    */

  /**
    Metadata associated with the request for async hasMany relationships.
     Example
     Given that the server returns the following JSON payload when fetching a
    hasMany relationship:
     ```js
    {
      "comments": [{
        "id": 1,
        "comment": "This is the first comment",
      }, {
    // ...
      }],
       "meta": {
        "page": 1,
        "total": 5
      }
    }
    ```
     You can then access the meta data via the `meta` property:
     ```js
    let comments = await post.comments;
    let meta = comments.meta;
     // meta.page => 1
    // meta.total => 5
    ```
     @property {Object | null} meta
    @public
    */

  /**
     * Retrieve the links for this relationship
     *
     @property {Object | null} links
     @public
     */

  constructor(options) {
    super(options);
    this.isLoaded = options.isLoaded || false;
    this.isAsync = options.isAsync || false;
    this.isPolymorphic = options.isPolymorphic || false;
    this.identifier = options.identifier;
    this.key = options.key;
  }
  [MUTATE](target, receiver, prop, args, _SIGNAL) {
    switch (prop) {
      case 'length 0':
        {
          Reflect.set(target, 'length', 0);
          mutateReplaceRelatedRecords(this, [], _SIGNAL);
          return true;
        }
      case 'replace cell':
        {
          const [index, prior, value] = args;
          target[index] = value;
          mutateReplaceRelatedRecord(this, {
            value,
            prior,
            index
          }, _SIGNAL);
          return true;
        }
      case 'push':
        {
          const newValues = extractIdentifiersFromRecords(args);
          assertNoDuplicates(this, target, currentState => currentState.push(...newValues), `Cannot push duplicates to a hasMany's state.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const seen = new Set(target);
            const unique = new Set();
            args.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.add(item);
              }
            });
            const newArgs = Array.from(unique);
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (newArgs.length) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(newArgs)
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (newValues.length) {
            mutateAddToRelatedRecords(this, {
              value: newValues
            }, _SIGNAL);
          }
          return result;
        }
      case 'pop':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          if (result) {
            mutateRemoveFromRelatedRecords(this, {
              value: recordIdentifierFor(result)
            }, _SIGNAL);
          }
          return result;
        }
      case 'unshift':
        {
          const newValues = extractIdentifiersFromRecords(args);
          assertNoDuplicates(this, target, currentState => currentState.unshift(...newValues), `Cannot unshift duplicates to a hasMany's state.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const seen = new Set(target);
            const unique = new Set();
            args.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.add(item);
              }
            });
            const newArgs = Array.from(unique);
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (newArgs.length) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(newArgs),
                index: 0
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (newValues.length) {
            mutateAddToRelatedRecords(this, {
              value: newValues,
              index: 0
            }, _SIGNAL);
          }
          return result;
        }
      case 'shift':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          if (result) {
            mutateRemoveFromRelatedRecords(this, {
              value: recordIdentifierFor(result),
              index: 0
            }, _SIGNAL);
          }
          return result;
        }
      case 'sort':
        {
          const result = Reflect.apply(target[prop], receiver, args);
          mutateSortRelatedRecords(this, result.map(recordIdentifierFor), _SIGNAL);
          return result;
        }
      case 'splice':
        {
          const [start, deleteCount, ...adds] = args;

          // detect a full replace
          if (start === 0 && deleteCount === this[SOURCE].length) {
            const newValues = extractIdentifiersFromRecords(adds);
            assertNoDuplicates(this, target, currentState => currentState.splice(start, deleteCount, ...newValues), `Cannot replace a hasMany's state with a new state that contains duplicates.`);
            if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
              // dedupe
              const current = new Set(adds);
              const unique = Array.from(current);
              const newArgs = [start, deleteCount].concat(unique);
              const result = Reflect.apply(target[prop], receiver, newArgs);
              mutateReplaceRelatedRecords(this, extractIdentifiersFromRecords(unique), _SIGNAL);
              return result;
            }

            // else, no dedupe, error on duplicates
            const result = Reflect.apply(target[prop], receiver, args);
            mutateReplaceRelatedRecords(this, newValues, _SIGNAL);
            return result;
          }
          const newValues = extractIdentifiersFromRecords(adds);
          assertNoDuplicates(this, target, currentState => currentState.splice(start, deleteCount, ...newValues), `Cannot splice a hasMany's state with a new state that contains duplicates.`);
          if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
            // dedupe
            const currentState = target.slice();
            currentState.splice(start, deleteCount);
            const seen = new Set(currentState);
            const unique = [];
            adds.forEach(item => {
              const identifier = recordIdentifierFor(item);
              if (!seen.has(identifier)) {
                seen.add(identifier);
                unique.push(item);
              }
            });
            const newArgs = [start, deleteCount, ...unique];
            const result = Reflect.apply(target[prop], receiver, newArgs);
            if (deleteCount > 0) {
              mutateRemoveFromRelatedRecords(this, {
                value: result.map(recordIdentifierFor),
                index: start
              }, _SIGNAL);
            }
            if (unique.length > 0) {
              mutateAddToRelatedRecords(this, {
                value: extractIdentifiersFromRecords(unique),
                index: start
              }, _SIGNAL);
            }
            return result;
          }

          // else, no dedupe, error on duplicates
          const result = Reflect.apply(target[prop], receiver, args);
          if (deleteCount > 0) {
            mutateRemoveFromRelatedRecords(this, {
              value: result.map(recordIdentifierFor),
              index: start
            }, _SIGNAL);
          }
          if (newValues.length > 0) {
            mutateAddToRelatedRecords(this, {
              value: newValues,
              index: start
            }, _SIGNAL);
          }
          return result;
        }
      default:
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(`unable to convert ${prop} into a transaction that updates the cache state for this record array`);
          }
        })() : {};
    }
  }
  notify() {
    const signal = this[ARRAY_SIGNAL];
    signal.shouldReset = true;
    notifyArray(this);
  }

  /**
    Reloads all of the records in the manyArray. If the manyArray
    holds a relationship that was originally fetched using a links url
    EmberData will revisit the original links url to repopulate the
    relationship.
     If the ManyArray holds the result of a `store.query()` reload will
    re-run the original query.
     Example
     ```javascript
    let user = store.peekRecord('user', '1')
    await login(user);
     let permissions = await user.permissions;
    await permissions.reload();
    ```
     @method reload
    @public
  */
  reload(options) {
    // TODO this is odd, we don't ask the store for anything else like this?
    return this._manager.reloadHasMany(this.key, options);
  }

  /**
    Saves all of the records in the `ManyArray`.
     Example
     ```javascript
    let inbox = await store.findRecord('inbox', '1');
    let messages = await inbox.messages;
    messages.forEach((message) => {
      message.isRead = true;
    });
    messages.save();
    ```
     @method save
    @public
    @return {PromiseArray} promise
  */

  /**
    Create a child record within the owner
     @method createRecord
    @public
    @param {Object} hash
    @return {Model} record
  */
  createRecord(hash) {
    const {
      store
    } = this;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected modelName to be set`);
      }
    })(this.modelName) : {};
    const record = store.createRecord(this.modelName, hash);
    this.push(record);
    return record;
  }
  destroy() {
    super.destroy(false);
  }
}
RelatedCollection.prototype.isAsync = false;
RelatedCollection.prototype.isPolymorphic = false;
RelatedCollection.prototype.identifier = null;
RelatedCollection.prototype.cache = null;
RelatedCollection.prototype._inverseIsAsync = false;
RelatedCollection.prototype.key = '';
RelatedCollection.prototype.DEPRECATED_CLASS_NAME = 'ManyArray';
function assertRecordPassedToHasMany(record) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`All elements of a hasMany relationship must be instances of Model, you passed ${typeof record}`);
    }
  })(function () {
    try {
      recordIdentifierFor(record);
      return true;
    } catch {
      return false;
    }
  }()) : {};
}
function extractIdentifiersFromRecords(records) {
  return records.map(extractIdentifierFromRecord$1);
}
function extractIdentifierFromRecord$1(recordOrPromiseRecord) {
  assertRecordPassedToHasMany(recordOrPromiseRecord);
  return recordIdentifierFor(recordOrPromiseRecord);
}
function assertNoDuplicates(collection, target, callback, reason) {
  const state = target.slice();
  callback(state);
  if (state.length !== new Set(state).size) {
    const duplicates = state.filter((currentValue, currentIndex) => state.indexOf(currentValue) !== currentIndex);
    if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_MANY_ARRAY_DUPLICATES)) {
      deprecate(`${reason} This behavior is deprecated. Found duplicates for the following records within the new state provided to \`<${collection.identifier.type}:${collection.identifier.id || collection.identifier.lid}>.${collection.key}\`\n\t- ${Array.from(new Set(duplicates)).map(r => isStableIdentifier(r) ? r.lid : recordIdentifierFor(r).lid).sort((a, b) => a.localeCompare(b)).join('\n\t- ')}`, false, {
        id: 'ember-data:deprecate-many-array-duplicates',
        for: 'ember-data',
        until: '6.0',
        since: {
          enabled: '5.3',
          available: '4.13'
        }
      });
    } else {
      throw new Error(`${reason} Found duplicates for the following records within the new state provided to \`<${collection.identifier.type}:${collection.identifier.id || collection.identifier.lid}>.${collection.key}\`\n\t- ${Array.from(new Set(duplicates)).map(r => isStableIdentifier(r) ? r.lid : recordIdentifierFor(r).lid).sort((a, b) => a.localeCompare(b)).join('\n\t- ')}`);
    }
  }
}
function mutateAddToRelatedRecords(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'addToRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateRemoveFromRelatedRecords(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'removeFromRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateReplaceRelatedRecord(collection, operationInfo, _SIGNAL) {
  mutate(collection, {
    op: 'replaceRelatedRecord',
    record: collection.identifier,
    field: collection.key,
    ...operationInfo
  }, _SIGNAL);
}
function mutateReplaceRelatedRecords(collection, value, _SIGNAL) {
  mutate(collection, {
    op: 'replaceRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    value
  }, _SIGNAL);
}
function mutateSortRelatedRecords(collection, value, _SIGNAL) {
  mutate(collection, {
    op: 'sortRelatedRecords',
    record: collection.identifier,
    field: collection.key,
    value
  }, _SIGNAL);
}
function mutate(collection, mutation, _SIGNAL) {
  collection._manager.mutate(mutation);
  addToTransaction(_SIGNAL);
}
const PromiseObject = ObjectProxy.extend(PromiseProxyMixin);
const deferred = /* @__PURE__ */new WeakMap();
function deferDecorator(proto, prop, desc) {
  let map = deferred.get(proto);
  if (!map) {
    map = /* @__PURE__ */new Map();
    deferred.set(proto, map);
  }
  map.set(prop, desc);
}
function findDeferredDecorator(target, prop) {
  var _a;
  let cursor = target.prototype;
  while (cursor) {
    let desc = (_a = deferred.get(cursor)) == null ? void 0 : _a.get(prop);
    if (desc) {
      return desc;
    }
    cursor = cursor.prototype;
  }
}
function decorateFieldV2(prototype, prop, decorators, initializer) {
  let desc = {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  };
  if (initializer) {
    desc.initializer = initializer;
  }
  for (let decorator of decorators) {
    desc = decorator(prototype, prop, desc) || desc;
  }
  if (desc.initializer === void 0) {
    Object.defineProperty(prototype, prop, desc);
  } else {
    deferDecorator(prototype, prop, desc);
  }
}
function decorateMethodV2(prototype, prop, decorators) {
  const origDesc = Object.getOwnPropertyDescriptor(prototype, prop);
  let desc = {
    ...origDesc
  };
  for (let decorator of decorators) {
    desc = decorator(prototype, prop, desc) || desc;
  }
  if (desc.initializer !== void 0) {
    desc.value = desc.initializer ? desc.initializer.call(prototype) : void 0;
    desc.initializer = void 0;
  }
  Object.defineProperty(prototype, prop, desc);
}
function initializeDeferredDecorator(target, prop) {
  let desc = findDeferredDecorator(target.constructor, prop);
  if (desc) {
    Object.defineProperty(target, prop, {
      enumerable: desc.enumerable,
      configurable: desc.configurable,
      writable: desc.writable,
      value: desc.initializer ? desc.initializer.call(target) : void 0
    });
  }
}
const LegacyPromiseProxy = Symbol.for('LegacyPromiseProxy');

// eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-extraneous-class

const Extended = PromiseObject;

/**
 @module @ember-data/model
 */

/**
  A PromiseBelongsTo is a PromiseObject that also proxies certain method calls
  to the underlying belongsTo model.
  Right now we proxy:
    * `reload()`
  @class PromiseBelongsTo
  @extends PromiseObject
  @private
*/
class PromiseBelongsTo extends Extended {
  get id() {
    const {
      key,
      legacySupport
    } = this._belongsToState;
    const ref = legacySupport.referenceFor('belongsTo', key);
    return ref.id();
  }

  // we don't proxy meta because we would need to proxy it to the relationship state container
  //  however, meta on relationships does not trigger change notifications.
  //  if you need relationship meta, you should do `record.belongsTo(relationshipName).meta()`
  static {
    decorateMethodV2(this.prototype, "id", [cached]);
  }
  get meta() {
    // eslint-disable-next-line no-constant-condition
    {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        {
          throw new Error('You attempted to access meta on the promise for the async belongsTo relationship ' + `${this._belongsToState.modelName}:${this._belongsToState.key}'.` + '\nUse `record.belongsTo(relationshipName).meta()` instead.');
        }
      })() : {};
    }
    return;
  }
  static {
    decorateMethodV2(this.prototype, "meta", [computed()]);
  }
  async reload(options) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('You are trying to reload an async belongsTo before it has been created');
      }
    })(this.content !== undefined) : {};
    const {
      key,
      legacySupport
    } = this._belongsToState;
    await legacySupport.reloadBelongsTo(key, options);
    return this;
  }
  [LegacyPromiseProxy] = true;
}

/**
 @module @ember-data/model
 */
/**
  This class is returned as the result of accessing an async hasMany relationship
  on an instance of a Model extending from `@ember-data/model`.

  A PromiseManyArray is an iterable proxy that allows templates to consume related
  ManyArrays and update once their contents are no longer pending.

  In your JS code you should resolve the promise first.

  ```js
  const comments = await post.comments;
  ```

  @class PromiseManyArray
  @public
*/
class PromiseManyArray {
  constructor(promise, content) {
    this._update(promise, content);
    this.isDestroyed = false;
  }

  /**
   * Retrieve the length of the content
   * @property length
   * @public
   */
  get length() {
    // shouldn't be needed, but ends up being needed
    // for computed chains even in 4.x
    if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this['[]'];
    }
    return this.content ? this.content.length : 0;
  }

  /**
   * Iterate the proxied content. Called by the glimmer iterator in #each
   * We do not guarantee that forEach will always be available. This
   * may eventually be made to use Symbol.Iterator once glimmer supports it.
   *
   * @method forEach
   * @param cb
   * @return
   * @private
   */
  static {
    decorateMethodV2(this.prototype, "length", [compat]);
  }
  forEach(cb) {
    if (this.content && this.length) {
      this.content.forEach(cb);
    }
  }

  /**
   * Reload the relationship
   * @method reload
   * @public
   * @param options
   * @return
   */
  reload(options) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error('You are trying to reload an async manyArray before it has been created');
      }
    })(this.content) : {};
    void this.content.reload(options);
    return this;
  }

  //----  Properties/Methods from the PromiseProxyMixin that we will keep as our API

  /**
   * Whether the loading promise is still pending
   *
   * @property {boolean} isPending
   * @public
   */

  /**
   * Whether the loading promise rejected
   *
   * @property {boolean} isRejected
   * @public
   */

  /**
   * Whether the loading promise succeeded
   *
   * @property {boolean} isFulfilled
   * @public
   */

  /**
   * Whether the loading promise completed (resolved or rejected)
   *
   * @property {boolean} isSettled
   * @public
   */

  /**
   * chain this promise
   *
   * @method then
   * @public
   * @param success
   * @param fail
   * @return Promise
   */
  then(s, f) {
    return this.promise.then(s, f);
  }

  /**
   * catch errors thrown by this promise
   * @method catch
   * @public
   * @param callback
   * @return Promise
   */
  catch(cb) {
    return this.promise.catch(cb);
  }

  /**
   * run cleanup after this promise completes
   *
   * @method finally
   * @public
   * @param callback
   * @return Promise
   */
  finally(cb) {
    return this.promise.finally(cb);
  }

  //---- Methods on EmberObject that we should keep

  destroy() {
    this.isDestroyed = true;
    this.content = null;
    this.promise = null;
  }

  //---- Methods/Properties on ManyArray that we own and proxy to

  /**
   * Retrieve the links for this relationship
   * @property links
   * @public
   */
  get links() {
    return this.content ? this.content.links : undefined;
  }

  /**
   * Retrieve the meta for this relationship
   * @property meta
   * @public
   */
  static {
    decorateMethodV2(this.prototype, "links", [compat]);
  }
  get meta() {
    return this.content ? this.content.meta : undefined;
  }

  //---- Our own stuff
  static {
    decorateMethodV2(this.prototype, "meta", [compat]);
  }
  _update(promise, content) {
    if (content !== undefined) {
      this.content = content;
    }
    this.promise = tapPromise(this, promise);
  }
  static create({
    promise,
    content
  }) {
    return new this(promise, content);
  }
  [LegacyPromiseProxy] = true;
}
defineSignal(PromiseManyArray.prototype, 'content', null);
defineSignal(PromiseManyArray.prototype, 'isPending', false);
defineSignal(PromiseManyArray.prototype, 'isRejected', false);
defineSignal(PromiseManyArray.prototype, 'isFulfilled', false);
defineSignal(PromiseManyArray.prototype, 'isSettled', false);

// this will error if someone tries to call
// A(identifierArray) since it is not configurable
// which is preferrable to the `meta` override we used
// before which required importing all of Ember
if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_COMPUTED_CHAINS)) {
  const desc = {
    enumerable: true,
    configurable: false,
    get: function () {
      return this.content?.length && this.content;
    }
  };
  compat(desc);

  // ember-source < 3.23 (e.g. 3.20 lts)
  // requires that the tag `'[]'` be notified
  // on the ArrayProxy in order for `{{#each}}`
  // to recompute. We entangle the '[]' tag from content

  Object.defineProperty(PromiseManyArray.prototype, '[]', desc);
}
function tapPromise(proxy, promise) {
  proxy.isPending = true;
  proxy.isSettled = false;
  proxy.isFulfilled = false;
  proxy.isRejected = false;
  return Promise.resolve(promise).then(content => {
    proxy.isPending = false;
    proxy.isFulfilled = true;
    proxy.isSettled = true;
    proxy.content = content;
    return content;
  }, error => {
    proxy.isPending = false;
    proxy.isFulfilled = false;
    proxy.isRejected = true;
    proxy.isSettled = true;
    throw error;
  });
}

/*
  Assert that `addedRecord` has a valid type so it can be added to the
  relationship of the `record`.

  The assert basically checks if the `addedRecord` can be added to the
  relationship (specified via `relationshipMeta`) of the `record`.

  This utility should only be used internally, as both record parameters must
  be stable record identifiers and the `relationshipMeta` needs to be the meta
  information about the relationship, retrieved via
  `record.relationshipFor(key)`.
*/
let assertPolymorphicType;
if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
  // eslint-disable-next-line @typescript-eslint/no-shadow
  assertPolymorphicType = function assertPolymorphicType(parentIdentifier, parentDefinition, addedIdentifier, store) {
    if (parentDefinition.inverseIsImplicit) {
      return;
    }
    if (parentDefinition.isPolymorphic) {
      const meta = store.schema.fields(addedIdentifier)?.get(parentDefinition.inverseKey);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected the schema for the field ${parentDefinition.inverseKey} on ${addedIdentifier.type} to be for a legacy relationship`);
        }
      })(!meta || meta.kind === 'belongsTo' || meta.kind === 'hasMany') : {};
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`The schema for the relationship '${parentDefinition.inverseKey}' on '${addedIdentifier.type}' type does not implement '${parentDefinition.type}' and thus cannot be assigned to the '${parentDefinition.key}' relationship in '${parentIdentifier.type}'. The definition should specify 'as: "${parentDefinition.type}"' in options.`);
        }
      })(meta?.options.as === parentDefinition.type) : {};
    }
  };
}
function isResourceIdentiferWithRelatedLinks$1(value) {
  return Boolean(value && value.links && value.links.related);
}
/**
 A `HasManyReference` is a low-level API that allows access
 and manipulation of a hasMany relationship.

 It is especially useful when you're dealing with `async` relationships
 from `@ember-data/model` as it allows synchronous access to
 the relationship data if loaded, as well as APIs for loading, reloading
 the data or accessing available information without triggering a load.

 It may also be useful when using `sync` relationships with `@ember-data/model`
 that need to be loaded/reloaded with more precise timing than marking the
 relationship as `async` and relying on autofetch would have allowed.

 However,keep in mind that marking a relationship as `async: false` will introduce
 bugs into your application if the data is not always guaranteed to be available
 by the time the relationship is accessed. Ergo, it is recommended when using this
 approach to utilize `links` for unloaded relationship state instead of identifiers.

 Reference APIs are entangled with the relationship's underlying state,
 thus any getters or cached properties that utilize these will properly
 invalidate if the relationship state changes.

 References are "stable", meaning that multiple calls to retrieve the reference
  for a given relationship will always return the same HasManyReference.

 @class HasManyReference
 @public
 */
class HasManyReference {
  /**
   * The field name on the parent record for this has-many relationship.
   *
   * @property {String} key
   * @public
   */

  /**
   * The type of resource this relationship will contain.
   *
   * @property {String} type
   * @public
   */

  // unsubscribe tokens given to us by the notification manager
  ___token;
  ___identifier;
  ___relatedTokenMap;
  constructor(store, graph, parentIdentifier, hasManyRelationship, key) {
    this.graph = graph;
    this.key = key;
    this.hasManyRelationship = hasManyRelationship;
    this.type = hasManyRelationship.definition.type;
    this.store = store;
    this.___identifier = parentIdentifier;
    this.___token = store.notifications.subscribe(parentIdentifier, (_, bucket, notifiedKey) => {
      if (bucket === 'relationships' && notifiedKey === key) {
        this._ref++;
      }
    });
    this.___relatedTokenMap = new Map();
    // TODO inverse
  }

  /**
   * This method should never be called by user code.
   *
   * @internal
   */
  destroy() {
    this.store.notifications.unsubscribe(this.___token);
    this.___relatedTokenMap.forEach(token => {
      this.store.notifications.unsubscribe(token);
    });
    this.___relatedTokenMap.clear();
  }

  /**
   * An array of identifiers for the records that this reference refers to.
   *
   * @property {StableRecordIdentifier[]} identifiers
   * @public
   */
  get identifiers() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._ref; // consume the tracked prop

    const resource = this._resource();
    const map = this.___relatedTokenMap;
    this.___relatedTokenMap = new Map();
    if (resource && resource.data) {
      return resource.data.map(resourceIdentifier => {
        const identifier = this.store.identifierCache.getOrCreateRecordIdentifier(resourceIdentifier);
        let token = map.get(identifier);
        if (token) {
          map.delete(identifier);
        } else {
          token = this.store.notifications.subscribe(identifier, (_, bucket, notifiedKey) => {
            if (bucket === 'identity' || bucket === 'attributes' && notifiedKey === 'id') {
              this._ref++;
            }
          });
        }
        this.___relatedTokenMap.set(identifier, token);
        return identifier;
      });
    }
    map.forEach(token => {
      this.store.notifications.unsubscribe(token);
    });
    map.clear();
    return [];
  }
  static {
    decorateMethodV2(this.prototype, "identifiers", [compat, cached]);
  }
  _resource() {
    const cache = this.store.cache;
    return cache.getRelationship(this.___identifier, this.key);
  }

  /**
   This returns a string that represents how the reference will be
   looked up when it is loaded. If the relationship has a link it will
   use the "link" otherwise it defaults to "id".
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    // get the identifier of the reference
   if (commentsRef.remoteType() === "ids") {
     let ids = commentsRef.ids();
   } else if (commentsRef.remoteType() === "link") {
     let link = commentsRef.link();
   }
   ```
    @method remoteType
   @public
   @return {String} The name of the remote type. This should either be `link` or `ids`
   */
  remoteType() {
    const value = this._resource();
    if (value && value.links && value.links.related) {
      return 'link';
    }
    return 'ids';
  }

  /**
   `ids()` returns an array of the record IDs in this relationship.
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    commentsRef.ids(); // ['1']
   ```
    @method ids
    @public
   @return {Array} The ids in this has-many relationship
   */
  ids() {
    return this.identifiers.map(identifier => identifier.id);
  }

  /**
   The link Ember Data will use to fetch or reload this belongs-to
   relationship. By default it uses only the "related" resource linkage.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
   export default Model.extend({
      user: belongsTo('user', { async: true, inverse: null })
    });
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            links: {
              related: '/articles/1/author'
            }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    // get the identifier of the reference
   if (userRef.remoteType() === "link") {
      let link = userRef.link();
    }
   ```
    @method link
   @public
   @return {String} The link Ember Data will use to fetch or reload this belongs-to relationship.
   */
  link() {
    const resource = this._resource();
    if (isResourceIdentiferWithRelatedLinks$1(resource)) {
      if (resource.links) {
        const related = resource.links.related;
        return !related || typeof related === 'string' ? related : related.href;
      }
    }
    return null;
  }

  /**
   * any links that have been received for this relationship
   *
   * @method links
   * @public
   * @return
   */
  links() {
    const resource = this._resource();
    return resource && resource.links ? resource.links : null;
  }

  /**
   The meta data for the has-many relationship.
    Example
    ```javascript
   // models/blog.js
   import Model, { hasMany } from '@ember-data/model';
   export default Model.extend({
      users: hasMany('user', { async: true, inverse: null })
    });
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          users: {
            links: {
              related: {
                href: '/articles/1/authors'
              },
            },
            meta: {
              lastUpdated: 1458014400000
            }
          }
        }
      }
    });
    let usersRef = blog.hasMany('user');
    usersRef.meta() // { lastUpdated: 1458014400000 }
   ```
   @method meta
  @public
  @return {Object|null} The meta information for the belongs-to relationship.
  */
  meta() {
    let meta = null;
    const resource = this._resource();
    if (resource && resource.meta && typeof resource.meta === 'object') {
      meta = resource.meta;
    }
    return meta;
  }

  /**
   `push` can be used to update the data in the relationship and EmberData
   will treat the new data as the canonical value of this relationship on
   the backend. An empty array will signify the canonical value should be
   empty.
    Example model
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    Setup some initial state, note we haven't loaded the comments yet:
    ```js
   const post = store.push({
     data: {
       type: 'post',
       id: '1',
       relationships: {
         comments: {
           data: [{ type: 'comment', id: '1' }]
         }
       }
     }
   });
    const commentsRef = post.hasMany('comments');
   commentsRef.ids(); // ['1']
   ```
    Update the state using `push`, note we can do this even without
   having loaded these comments yet by providing resource identifiers.
    Both full resources and resource identifiers are supported.
    ```js
   await commentsRef.push({
    data: [
     { type: 'comment', id: '2' },
     { type: 'comment', id: '3' },
    ]
   });
    commentsRef.ids(); // ['2', '3']
   ```
    For convenience, you can also pass in an array of resources or resource identifiers
   without wrapping them in the `data` property:
    ```js
   await commentsRef.push([
     { type: 'comment', id: '4' },
     { type: 'comment', id: '5' },
   ]);
    commentsRef.ids(); // ['4', '5']
   ```
    When using the `data` property, you may also include other resource data via included,
   as well as provide new links and meta to the relationship.
    ```js
   await commentsRef.push({
     links: {
       related: '/posts/1/comments'
     },
     meta: {
       total: 2
     },
     data: [
       { type: 'comment', id: '4' },
       { type: 'comment', id: '5' },
     ],
     included: [
       { type: 'other-thing', id: '1', attributes: { foo: 'bar' },
     ]
   });
   ```
    By default, the store will attempt to fetch any unloaded records before resolving
   the returned promise with the ManyArray.
    Alternatively, pass `true` as the second argument to avoid fetching unloaded records
   and instead the promise will resolve with void without attempting to fetch. This is
   particularly useful if you want to update the state of the relationship without
   forcing the load of all of the associated records.
    @method push
   @public
   @param {Array|Object} doc a JSONAPI document object describing the new value of this relationship.
   @param {Boolean} [skipFetch] if `true`, do not attempt to fetch unloaded records
   @return {Promise<ManyArray | void>}
  */
  async push(doc, skipFetch) {
    const {
      store
    } = this;
    const dataDoc = Array.isArray(doc) ? {
      data: doc
    } : doc;
    const isResourceData = Array.isArray(dataDoc.data) && dataDoc.data.length > 0 && isMaybeResource(dataDoc.data[0]);

    // enforce that one of links, meta or data is present
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`You must provide at least one of 'links', 'meta' or 'data' when calling hasManyReference.push`);
      }
    })('links' in dataDoc || 'meta' in dataDoc || 'data' in dataDoc) : {};
    const identifiers = !Array.isArray(dataDoc.data) ? [] : isResourceData ? store._push(dataDoc, true) : dataDoc.data.map(i => store.identifierCache.getOrCreateRecordIdentifier(i));
    const {
      identifier
    } = this.hasManyRelationship;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      const relationshipMeta = this.hasManyRelationship.definition;
      identifiers.forEach(added => {
        assertPolymorphicType(identifier, relationshipMeta, added, store);
      });
    }
    const newData = {};
    // only set data if it was passed in
    if (Array.isArray(dataDoc.data)) {
      newData.data = identifiers;
    }
    if ('links' in dataDoc) {
      newData.links = dataDoc.links;
    }
    if ('meta' in dataDoc) {
      newData.meta = dataDoc.meta;
    }
    store._join(() => {
      this.graph.push({
        op: 'updateRelationship',
        record: identifier,
        field: this.key,
        value: newData
      });
    });
    if (!skipFetch) return this.load();
  }
  _isLoaded() {
    const hasRelationshipDataProperty = this.hasManyRelationship.state.hasReceivedData;
    if (!hasRelationshipDataProperty) {
      return false;
    }
    const relationship = this.graph.getData(this.hasManyRelationship.identifier, this.key);
    return relationship.data?.every(identifier => {
      return this.store._instanceCache.recordIsLoaded(identifier, true) === true;
    });
  }

  /**
   `value()` synchronously returns the current value of the has-many
   relationship. Unlike `record.relationshipName`, calling
   `value()` on a reference does not trigger a fetch if the async
   relationship is not yet loaded. If the relationship is not loaded
   it will always return `null`.
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    post.comments.then(function(comments) {
     commentsRef.value() === comments
   })
   ```
    @method value
    @public
   @return {ManyArray}
   */
  value() {
    const support = LEGACY_SUPPORT.get(this.___identifier);
    const loaded = this._isLoaded();
    if (!loaded) {
      // subscribe to changes
      // for when we are not loaded yet
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this._ref;
      return null;
    }
    return support.getManyArray(this.key);
  }

  /**
   Loads the relationship if it is not already loaded.  If the
   relationship is already loaded this method does not trigger a new
   load. This causes a request to the specified
   relationship link or reloads all items currently in the relationship.
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    commentsRef.load().then(function(comments) {
     //...
   });
   ```
    You may also pass in an options object whose properties will be
   fed forward. This enables you to pass `adapterOptions` into the
   request given to the adapter via the reference.
    Example
    ```javascript
   commentsRef.load({ adapterOptions: { isPrivate: true } })
     .then(function(comments) {
       //...
     });
   ```
    ```app/adapters/comment.js
   export default ApplicationAdapter.extend({
     findMany(store, type, id, snapshots) {
       // In the adapter you will have access to adapterOptions.
       let adapterOptions = snapshots[0].adapterOptions;
     }
   });
   ```
    @method load
   @public
   @param {Object} options the options to pass in.
   @return {Promise} a promise that resolves with the ManyArray in
   this has-many relationship.
   */
  async load(options) {
    const support = LEGACY_SUPPORT.get(this.___identifier);
    const fetchSyncRel = !this.hasManyRelationship.definition.isAsync && !areAllInverseRecordsLoaded(this.store, this._resource());
    return fetchSyncRel ? support.reloadHasMany(this.key, options) :
    // we cast to fix the return type since typescript and eslint don't understand async functions
    // properly
    support.getHasMany(this.key, options);
  }

  /**
   Reloads this has-many relationship. This causes a request to the specified
   relationship link or reloads all items currently in the relationship.
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    commentsRef.reload().then(function(comments) {
     //...
   });
   ```
    You may also pass in an options object whose properties will be
   fed forward. This enables you to pass `adapterOptions` into the
   request given to the adapter via the reference. A full example
   can be found in the `load` method.
    Example
    ```javascript
   commentsRef.reload({ adapterOptions: { isPrivate: true } })
   ```
    @method reload
    @public
   @param {Object} options the options to pass in.
   @return {Promise} a promise that resolves with the ManyArray in this has-many relationship.
   */
  reload(options) {
    const support = LEGACY_SUPPORT.get(this.___identifier);
    return support.reloadHasMany(this.key, options);
  }
}
defineSignal(HasManyReference.prototype, '_ref', 0);
function isMaybeResource(object) {
  const keys = Object.keys(object).filter(k => k !== 'id' && k !== 'type' && k !== 'lid');
  return keys.length > 0;
}
function isResourceIdentiferWithRelatedLinks(value) {
  return Boolean(value && value.links && value.links.related);
}

/**
 A `BelongsToReference` is a low-level API that allows access
 and manipulation of a belongsTo relationship.

 It is especially useful when you're dealing with `async` relationships
 from `@ember-data/model` as it allows synchronous access to
 the relationship data if loaded, as well as APIs for loading, reloading
 the data or accessing available information without triggering a load.

 It may also be useful when using `sync` relationships with `@ember-data/model`
 that need to be loaded/reloaded with more precise timing than marking the
 relationship as `async` and relying on autofetch would have allowed.

 However,keep in mind that marking a relationship as `async: false` will introduce
 bugs into your application if the data is not always guaranteed to be available
 by the time the relationship is accessed. Ergo, it is recommended when using this
 approach to utilize `links` for unloaded relationship state instead of identifiers.

 Reference APIs are entangled with the relationship's underlying state,
 thus any getters or cached properties that utilize these will properly
 invalidate if the relationship state changes.

 References are "stable", meaning that multiple calls to retrieve the reference
  for a given relationship will always return the same HasManyReference.

 @class BelongsToReference
 @public
 */
class BelongsToReference {
  /**
   * The field name on the parent record for this has-many relationship.
   *
   * @property {String} key
   * @public
   */

  /**
   * The type of resource this relationship will contain.
   *
   * @property {String} type
   * @public
   */

  // unsubscribe tokens given to us by the notification manager

  constructor(store, graph, parentIdentifier, belongsToRelationship, key) {
    this.graph = graph;
    this.key = key;
    this.belongsToRelationship = belongsToRelationship;
    this.type = belongsToRelationship.definition.type;
    this.store = store;
    this.___identifier = parentIdentifier;
    this.___relatedToken = null;
    this.___token = store.notifications.subscribe(parentIdentifier, (_, bucket, notifiedKey) => {
      if (bucket === 'relationships' && notifiedKey === key) {
        this._ref++;
      }
    });

    // TODO inverse
  }
  destroy() {
    // TODO @feature we need the notification manager often enough
    // we should potentially just expose it fully public
    this.store.notifications.unsubscribe(this.___token);
    this.___token = null;
    if (this.___relatedToken) {
      this.store.notifications.unsubscribe(this.___relatedToken);
      this.___relatedToken = null;
    }
  }

  /**
   * The identifier of the record that this reference refers to.
   * `null` if no related record is known.
   *
   * @property {StableRecordIdentifier | null} identifier
   * @public
   */
  get identifier() {
    if (this.___relatedToken) {
      this.store.notifications.unsubscribe(this.___relatedToken);
      this.___relatedToken = null;
    }
    const resource = this._resource();
    if (resource && resource.data) {
      const identifier = this.store.identifierCache.getOrCreateRecordIdentifier(resource.data);
      this.___relatedToken = this.store.notifications.subscribe(identifier, (_, bucket, notifiedKey) => {
        if (bucket === 'identity' || bucket === 'attributes' && notifiedKey === 'id') {
          this._ref++;
        }
      });
      return identifier;
    }
    return null;
  }

  /**
   The `id` of the record that this reference refers to. Together, the
   `type()` and `id()` methods form a composite key for the identity
   map. This can be used to access the id of an async relationship
   without triggering a fetch that would normally happen if you
   attempted to use `record.relationship.id`.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class BlogModel extends Model {
    @belongsTo('user', { async: true, inverse: null }) user;
   }
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            data: { type: 'user', id: 1 }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    // get the identifier of the reference
   if (userRef.remoteType() === "id") {
      let id = userRef.id();
    }
   ```
    @method id
   @public
   @return {String} The id of the record in this belongsTo relationship.
   */
  static {
    decorateMethodV2(this.prototype, "identifier", [compat, cached]);
  }
  id() {
    return this.identifier?.id || null;
  }

  /**
   The link Ember Data will use to fetch or reload this belongs-to
   relationship. By default it uses only the "related" resource linkage.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
   export default Model.extend({
      user: belongsTo('user', { async: true, inverse: null })
    });
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            links: {
              related: '/articles/1/author'
            }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    // get the identifier of the reference
   if (userRef.remoteType() === "link") {
      let link = userRef.link();
    }
   ```
    @method link
   @public
   @return {String} The link Ember Data will use to fetch or reload this belongs-to relationship.
   */
  link() {
    const resource = this._resource();
    if (isResourceIdentiferWithRelatedLinks(resource)) {
      if (resource.links) {
        const related = resource.links.related;
        return !related || typeof related === 'string' ? related : related.href;
      }
    }
    return null;
  }

  /**
   * any links that have been received for this relationship
   *
   * @method links
   * @public
   * @return
   */
  links() {
    const resource = this._resource();
    return resource && resource.links ? resource.links : null;
  }

  /**
   The meta data for the belongs-to relationship.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
   export default Model.extend({
      user: belongsTo('user', { async: true, inverse: null })
    });
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            links: {
              related: {
                href: '/articles/1/author'
              },
            },
            meta: {
              lastUpdated: 1458014400000
            }
          }
        }
      }
    });
    let userRef = blog.belongsTo('user');
    userRef.meta() // { lastUpdated: 1458014400000 }
   ```
    @method meta
    @public
   @return {Object} The meta information for the belongs-to relationship.
   */
  meta() {
    let meta = null;
    const resource = this._resource();
    if (resource && resource.meta && typeof resource.meta === 'object') {
      meta = resource.meta;
    }
    return meta;
  }
  _resource() {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this._ref; // subscribe
    const cache = this.store.cache;
    return cache.getRelationship(this.___identifier, this.key);
  }

  /**
   This returns a string that represents how the reference will be
   looked up when it is loaded. If the relationship has a link it will
   use the "link" otherwise it defaults to "id".
    Example
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment', { async: true, inverse: null }) comments;
   }
   ```
    ```javascript
   let post = store.push({
     data: {
       type: 'post',
       id: 1,
       relationships: {
         comments: {
           data: [{ type: 'comment', id: 1 }]
         }
       }
     }
   });
    let commentsRef = post.hasMany('comments');
    // get the identifier of the reference
   if (commentsRef.remoteType() === "ids") {
     let ids = commentsRef.ids();
   } else if (commentsRef.remoteType() === "link") {
     let link = commentsRef.link();
   }
   ```
    @method remoteType
   @public
   @return {String} The name of the remote type. This should either be `link` or `id`
   */
  remoteType() {
    const value = this._resource();
    if (isResourceIdentiferWithRelatedLinks(value)) {
      return 'link';
    }
    return 'id';
  }

  /**
   `push` can be used to update the data in the relationship and EmberData
   will treat the new data as the canonical value of this relationship on
   the backend. A value of `null` (e.g. `{ data: null }`) can be passed to
   clear the relationship.
    Example model
    ```app/models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class BlogModel extends Model {
      @belongsTo('user', { async: true, inverse: null }) user;
    }
   ```
    Setup some initial state, note we haven't loaded the user yet:
    ```js
   const blog = store.push({
      data: {
        type: 'blog',
        id: '1',
        relationships: {
          user: {
            data: { type: 'user', id: '1' }
          }
        }
      }
   });
    const userRef = blog.belongsTo('user');
   userRef.id(); // '1'
   ```
    Update the state using `push`, note we can do this even without
   having loaded the user yet by providing a resource-identifier.
    Both full a resource and a resource-identifier are supported.
    ```js
   await userRef.push({
      data: {
        type: 'user',
        id: '2',
      }
    });
     userRef.id(); // '2'
   ```
    You may also pass in links and meta fore the relationship, and sideload
   additional resources that might be required.
    ```js
    await userRef.push({
        data: {
          type: 'user',
          id: '2',
        },
        links: {
          related: '/articles/1/author'
        },
        meta: {
          lastUpdated: Date.now()
        },
        included: [
          {
            type: 'user-preview',
            id: '2',
            attributes: {
              username: '@runspired'
            }
          }
        ]
      });
    ```
    By default, the store will attempt to fetch the record if it is not loaded or its
   resource data is not included in the call to `push` before resolving the returned
   promise with the new state..
    Alternatively, pass `true` as the second argument to avoid fetching unloaded records
   and instead the promise will resolve with void without attempting to fetch. This is
   particularly useful if you want to update the state of the relationship without
   forcing the load of all of the associated record.
    @method push
   @public
   @param {Object} doc a JSONAPI document object describing the new value of this relationship.
   @param {Boolean} [skipFetch] if `true`, do not attempt to fetch unloaded records
   @return {Promise<OpaqueRecordInstance | null | void>}
  */
  async push(doc, skipFetch) {
    const {
      store
    } = this;
    const isResourceData = doc.data && isMaybeResource(doc.data);
    const added = isResourceData ? store._push(doc, true) : doc.data ? store.identifierCache.getOrCreateRecordIdentifier(doc.data) : null;
    const {
      identifier
    } = this.belongsToRelationship;
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (added) {
        assertPolymorphicType(identifier, this.belongsToRelationship.definition, added, store);
      }
    }
    const newData = {};

    // only set data if it was passed in
    if (doc.data || doc.data === null) {
      newData.data = added;
    }
    if ('links' in doc) {
      newData.links = doc.links;
    }
    if ('meta' in doc) {
      newData.meta = doc.meta;
    }
    store._join(() => {
      this.graph.push({
        op: 'updateRelationship',
        record: identifier,
        field: this.key,
        value: newData
      });
    });
    if (!skipFetch) return this.load();
  }

  /**
   `value()` synchronously returns the current value of the belongs-to
   relationship. Unlike `record.relationshipName`, calling
   `value()` on a reference does not trigger a fetch if the async
   relationship is not yet loaded. If the relationship is not loaded
   it will always return `null`.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class BlogModel extends Model {
     @belongsTo('user', { async: true, inverse: null }) user;
   }
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            data: { type: 'user', id: 1 }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    userRef.value(); // null
    // provide data for reference
   userRef.push({
      data: {
        type: 'user',
        id: 1,
        attributes: {
          username: "@user"
        }
      }
    }).then(function(user) {
      userRef.value(); // user
    });
   ```
    @method value
    @public
   @return {Model} the record in this relationship
   */
  value() {
    const resource = this._resource();
    return resource && resource.data ? this.store.peekRecord(resource.data) : null;
  }

  /**
   Loads a record in a belongs-to relationship if it is not already
   loaded. If the relationship is already loaded this method does not
   trigger a new load.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class BlogModel extends Model {
     @belongsTo('user', { async: true, inverse: null }) user;
   }
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            data: { type: 'user', id: 1 }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    userRef.value(); // null
    userRef.load().then(function(user) {
      userRef.value() === user
    });
   ```
    You may also pass in an options object whose properties will be
   fed forward. This enables you to pass `adapterOptions` into the
   request given to the adapter via the reference.
    Example
    ```javascript
   userRef.load({ adapterOptions: { isPrivate: true } }).then(function(user) {
     userRef.value() === user;
   });
   ```
   ```app/adapters/user.js
   import Adapter from '@ember-data/adapter';
    export default class UserAdapter extends Adapter {
     findRecord(store, type, id, snapshot) {
       // In the adapter you will have access to adapterOptions.
       let adapterOptions = snapshot.adapterOptions;
     }
   });
   ```
    @method load
    @public
   @param {Object} options the options to pass in.
   @return {Promise} a promise that resolves with the record in this belongs-to relationship.
   */
  async load(options) {
    const support = LEGACY_SUPPORT.get(this.___identifier);
    const fetchSyncRel = !this.belongsToRelationship.definition.isAsync && !areAllInverseRecordsLoaded(this.store, this._resource());
    return fetchSyncRel ? support.reloadBelongsTo(this.key, options).then(() => this.value()) :
    // we cast to fix the return type since typescript and eslint don't understand async functions
    // properly
    support.getBelongsTo(this.key, options);
  }

  /**
   Triggers a reload of the value in this relationship. If the
   remoteType is `"link"` Ember Data will use the relationship link to
   reload the relationship. Otherwise it will reload the record by its
   id.
    Example
    ```javascript
   // models/blog.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class BlogModel extends Model {
     @belongsTo('user', { async: true, inverse: null }) user;
   }
    let blog = store.push({
      data: {
        type: 'blog',
        id: 1,
        relationships: {
          user: {
            data: { type: 'user', id: 1 }
          }
        }
      }
    });
   let userRef = blog.belongsTo('user');
    userRef.reload().then(function(user) {
      userRef.value() === user
    });
   ```
    You may also pass in an options object whose properties will be
   fed forward. This enables you to pass `adapterOptions` into the
   request given to the adapter via the reference. A full example
   can be found in the `load` method.
    Example
    ```javascript
   userRef.reload({ adapterOptions: { isPrivate: true } })
   ```
    @method reload
    @public
   @param {Object} options the options to pass in.
   @return {Promise} a promise that resolves with the record in this belongs-to relationship after the reload has completed.
   */
  reload(options) {
    const support = LEGACY_SUPPORT.get(this.___identifier);
    return support.reloadBelongsTo(this.key, options).then(() => this.value());
  }
}
defineSignal(BelongsToReference.prototype, '_ref', 0);
const LEGACY_SUPPORT = getOrSetGlobal('LEGACY_SUPPORT', new Map());
function lookupLegacySupport(record) {
  const identifier = recordIdentifierFor(record);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected a record`);
    }
  })(identifier) : {};
  let support = LEGACY_SUPPORT.get(identifier);
  if (!support) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Memory Leak Detected`);
      }
    })(!record.isDestroyed && !record.isDestroying) : {};
    support = new LegacySupport(record);
    LEGACY_SUPPORT.set(identifier, support);
    LEGACY_SUPPORT.set(record, support);
  }
  return support;
}
class LegacySupport {
  constructor(record) {
    this.record = record;
    this.store = storeFor(record);
    this.identifier = recordIdentifierFor(record);
    this.cache = peekCache(record);
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      const graphFor = importSync('@ember-data/graph/-private').graphFor;
      this.graph = graphFor(this.store);
    }
    this._manyArrayCache = Object.create(null);
    this._relationshipPromisesCache = Object.create(null);
    this._relationshipProxyCache = Object.create(null);
    this._pending = Object.create(null);
    this.references = Object.create(null);
  }
  _syncArray(array) {
    // It’s possible the parent side of the relationship may have been destroyed by this point
    if (this.isDestroyed || this.isDestroying) {
      return;
    }
    const currentState = array[SOURCE];
    const identifier = this.identifier;
    const [identifiers, jsonApi] = this._getCurrentState(identifier, array.key);
    if (jsonApi.meta) {
      array.meta = jsonApi.meta;
    }
    if (jsonApi.links) {
      array.links = jsonApi.links;
    }
    currentState.length = 0;
    fastPush(currentState, identifiers);
  }
  mutate(mutation) {
    this.cache.mutate(mutation);
  }
  _findBelongsTo(key, resource, relationship, options) {
    // TODO @runspired follow up if parent isNew then we should not be attempting load here
    // TODO @runspired follow up on whether this should be in the relationship requests cache
    return this._findBelongsToByJsonApiResource(resource, this.identifier, relationship, options).then(identifier => handleCompletedRelationshipRequest(this, key, relationship, identifier), e => handleCompletedRelationshipRequest(this, key, relationship, null, e));
  }
  reloadBelongsTo(key, options) {
    const loadingPromise = this._relationshipPromisesCache[key];
    if (loadingPromise) {
      return loadingPromise;
    }
    const relationship = this.graph.get(this.identifier, key);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected ${key} to be a belongs-to relationship`);
      }
    })(isBelongsTo(relationship)) : {};
    const resource = this.cache.getRelationship(this.identifier, key);
    relationship.state.hasFailedLoadAttempt = false;
    relationship.state.shouldForceReload = true;
    const promise = this._findBelongsTo(key, resource, relationship, options);
    if (this._relationshipProxyCache[key]) {
      // @ts-expect-error
      return this._updatePromiseProxyFor('belongsTo', key, {
        promise
      });
    }
    return promise;
  }
  getBelongsTo(key, options) {
    const {
      identifier,
      cache
    } = this;
    const resource = cache.getRelationship(this.identifier, key);
    const relatedIdentifier = resource && resource.data ? resource.data : null;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a stable identifier`);
      }
    })(!relatedIdentifier || isStableIdentifier(relatedIdentifier)) : {};
    const store = this.store;
    const relationship = this.graph.get(this.identifier, key);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected ${key} to be a belongs-to relationship`);
      }
    })(isBelongsTo(relationship)) : {};
    const isAsync = relationship.definition.isAsync;
    const _belongsToState = {
      key,
      store,
      legacySupport: this,
      modelName: relationship.definition.type
    };
    if (isAsync) {
      if (relationship.state.hasFailedLoadAttempt) {
        return this._relationshipProxyCache[key];
      }
      const promise = this._findBelongsTo(key, resource, relationship, options);
      const isLoaded = relatedIdentifier && store._instanceCache.recordIsLoaded(relatedIdentifier);
      return this._updatePromiseProxyFor('belongsTo', key, {
        promise,
        content: isLoaded ? store._instanceCache.getRecord(relatedIdentifier) : null,
        _belongsToState
      });
    } else {
      if (relatedIdentifier === null) {
        return null;
      } else {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`You looked up the '${key}' relationship on a '${identifier.type}' with id ${identifier.id || 'null'} but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async (\`belongsTo(<type>, { async: true, inverse: <inverse> })\`)`);
          }
        })(store._instanceCache.recordIsLoaded(relatedIdentifier, true)) : {};
        return store._instanceCache.getRecord(relatedIdentifier);
      }
    }
  }
  setDirtyBelongsTo(key, value) {
    return this.cache.mutate({
      op: 'replaceRelatedRecord',
      record: this.identifier,
      field: key,
      value: extractIdentifierFromRecord(value)
    },
    // @ts-expect-error
    true);
  }
  _getCurrentState(identifier, field) {
    const jsonApi = this.cache.getRelationship(identifier, field);
    const cache = this.store._instanceCache;
    const identifiers = [];
    if (jsonApi.data) {
      for (let i = 0; i < jsonApi.data.length; i++) {
        const relatedIdentifier = jsonApi.data[i];
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected a stable identifier`);
          }
        })(isStableIdentifier(relatedIdentifier)) : {};
        if (cache.recordIsLoaded(relatedIdentifier, true)) {
          identifiers.push(relatedIdentifier);
        }
      }
    }
    return [identifiers, jsonApi];
  }
  getManyArray(key, definition) {
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      let manyArray = this._manyArrayCache[key];
      if (!definition) {
        definition = this.graph.get(this.identifier, key).definition;
      }
      if (!manyArray) {
        const [identifiers, doc] = this._getCurrentState(this.identifier, key);
        manyArray = new RelatedCollection({
          store: this.store,
          type: definition.type,
          identifier: this.identifier,
          cache: this.cache,
          identifiers,
          key,
          meta: doc.meta || null,
          links: doc.links || null,
          isPolymorphic: definition.isPolymorphic,
          isAsync: definition.isAsync,
          _inverseIsAsync: definition.inverseIsAsync,
          manager: this,
          isLoaded: !definition.isAsync,
          allowMutation: true
        });
        this._manyArrayCache[key] = manyArray;
      }
      return manyArray;
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error('hasMany only works with the @ember-data/json-api package');
      }
    })() : {};
  }
  fetchAsyncHasMany(key, relationship, manyArray, options) {
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      let loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      const jsonApi = this.cache.getRelationship(this.identifier, key);
      const promise = this._findHasManyByJsonApiResource(jsonApi, this.identifier, relationship, options);
      if (!promise) {
        manyArray.isLoaded = true;
        return Promise.resolve(manyArray);
      }
      loadingPromise = promise.then(() => handleCompletedRelationshipRequest(this, key, relationship, manyArray), e => handleCompletedRelationshipRequest(this, key, relationship, manyArray, e));
      this._relationshipPromisesCache[key] = loadingPromise;
      return loadingPromise;
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error('hasMany only works with the @ember-data/json-api package');
      }
    })() : {};
  }
  reloadHasMany(key, options) {
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      const loadingPromise = this._relationshipPromisesCache[key];
      if (loadingPromise) {
        return loadingPromise;
      }
      const relationship = this.graph.get(this.identifier, key);
      const {
        definition,
        state
      } = relationship;
      state.hasFailedLoadAttempt = false;
      state.shouldForceReload = true;
      const manyArray = this.getManyArray(key, definition);
      const promise = this.fetchAsyncHasMany(key, relationship, manyArray, options);
      if (this._relationshipProxyCache[key]) {
        return this._updatePromiseProxyFor('hasMany', key, {
          promise
        });
      }
      return promise;
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`hasMany only works with the @ember-data/json-api package`);
      }
    })() : {};
  }
  getHasMany(key, options) {
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      const relationship = this.graph.get(this.identifier, key);
      const {
        definition,
        state
      } = relationship;
      const manyArray = this.getManyArray(key, definition);
      if (definition.isAsync) {
        if (state.hasFailedLoadAttempt) {
          return this._relationshipProxyCache[key];
        }
        const promise = this.fetchAsyncHasMany(key, relationship, manyArray, options);
        return this._updatePromiseProxyFor('hasMany', key, {
          promise,
          content: manyArray
        });
      } else {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`You looked up the '${key}' relationship on a '${this.identifier.type}' with id ${this.identifier.id || 'null'} but some of the associated records were not loaded. Either make sure they are all loaded together with the parent record, or specify that the relationship is async ('hasMany(<type>, { async: true, inverse: <inverse> })')`);
          }
        })(!anyUnloaded(this.store, relationship)) : {};
        return manyArray;
      }
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`hasMany only works with the @ember-data/json-api package`);
      }
    })() : {};
  }
  _updatePromiseProxyFor(kind, key, args) {
    let promiseProxy = this._relationshipProxyCache[key];
    if (kind === 'hasMany') {
      const {
        promise,
        content
      } = args;
      if (promiseProxy) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected a PromiseManyArray`);
          }
        })('_update' in promiseProxy) : {};
        promiseProxy._update(promise, content);
      } else {
        promiseProxy = this._relationshipProxyCache[key] = new PromiseManyArray(promise, content);
      }
      return promiseProxy;
    }
    if (promiseProxy) {
      const {
        promise,
        content
      } = args;
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected a PromiseBelongsTo`);
        }
      })('_belongsToState' in promiseProxy) : {};
      if (content !== undefined) {
        promiseProxy.set('content', content);
      }
      void promiseProxy.set('promise', promise);
    } else {
      promiseProxy = PromiseBelongsTo.create(args);
      this._relationshipProxyCache[key] = promiseProxy;
    }
    return promiseProxy;
  }
  referenceFor(kind, name) {
    let reference = this.references[name];
    if (!reference) {
      if (macroCondition(!dependencySatisfies('@ember-data/graph', '*'))) {
        // TODO @runspired while this feels odd, it is not a regression in capability because we do
        // not today support references pulling from RecordDatas other than our own
        // because of the intimate API access involved. This is something we will need to redesign.
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          {
            throw new Error(`snapshot.belongsTo only supported for @ember-data/json-api`);
          }
        })() : {};
      }
      const {
        graph,
        identifier
      } = this;
      const relationship = graph.get(identifier, name);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (kind) {
          const modelName = identifier.type;
          const actualRelationshipKind = relationship.definition.kind;
          macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
            if (!test) {
              throw new Error(`You tried to get the '${name}' relationship on a '${modelName}' via record.${kind}('${name}'), but the relationship is of kind '${actualRelationshipKind}'. Use record.${actualRelationshipKind}('${name}') instead.`);
            }
          })(actualRelationshipKind === kind) : {};
        }
      }
      const relationshipKind = relationship.definition.kind;
      if (relationshipKind === 'belongsTo') {
        reference = new BelongsToReference(this.store, graph, identifier, relationship, name);
      } else if (relationshipKind === 'hasMany') {
        reference = new HasManyReference(this.store, graph, identifier, relationship, name);
      }
      this.references[name] = reference;
    }
    return reference;
  }
  _findHasManyByJsonApiResource(resource, parentIdentifier, relationship, options = {}) {
    if (macroCondition(dependencySatisfies('@ember-data/graph', '*'))) {
      if (!resource) {
        return;
      }
      const {
        definition,
        state
      } = relationship;
      upgradeStore(this.store);
      const adapter = this.store.adapterFor?.(definition.type);
      const {
        isStale,
        hasDematerializedInverse,
        hasReceivedData,
        isEmpty,
        shouldForceReload
      } = state;
      const allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this.store, resource);
      const identifiers = resource.data;
      const shouldFindViaLink = resource.links && resource.links.related && (typeof adapter?.findHasMany === 'function' || typeof identifiers === 'undefined') && (shouldForceReload || hasDematerializedInverse || isStale || !allInverseRecordsAreLoaded && !isEmpty);
      const field = this.store.schema.fields({
        type: definition.inverseType
      }).get(definition.key);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected a hasMany field definition for ${definition.inverseType}.${definition.key}`);
        }
      })(field && field.kind === 'hasMany') : {};
      const request = {
        useLink: shouldFindViaLink,
        field,
        links: resource.links,
        meta: resource.meta,
        options,
        record: parentIdentifier
      };

      // fetch via link
      if (shouldFindViaLink) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected collection to be an array`);
          }
        })(!identifiers || Array.isArray(identifiers)) : {};
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected stable identifiers`);
          }
        })(!identifiers || identifiers.every(isStableIdentifier)) : {};
        return this.store.request({
          op: 'findHasMany',
          records: identifiers || [],
          data: request,
          cacheOptions: {
            [Symbol.for('wd:skip-cache')]: true
          }
        });
      }
      const preferLocalCache = hasReceivedData && !isEmpty;
      const hasLocalPartialData = hasDematerializedInverse || isEmpty && Array.isArray(identifiers) && identifiers.length > 0;
      const attemptLocalCache = !shouldForceReload && !isStale && (preferLocalCache || hasLocalPartialData);
      if (attemptLocalCache && allInverseRecordsAreLoaded) {
        return;
      }
      const hasData = hasReceivedData && !isEmpty;
      if (attemptLocalCache || hasData || hasLocalPartialData) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected collection to be an array`);
          }
        })(Array.isArray(identifiers)) : {};
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error(`Expected stable identifiers`);
          }
        })(identifiers.every(isStableIdentifier)) : {};
        options.reload = options.reload || !attemptLocalCache || undefined;
        return this.store.request({
          op: 'findHasMany',
          records: identifiers,
          data: request,
          cacheOptions: {
            [Symbol.for('wd:skip-cache')]: true
          }
        });
      }

      // we were explicitly told we have no data and no links.
      //   TODO if the relationshipIsStale, should we hit the adapter anyway?
      return;
    }
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error(`hasMany only works with the @ember-data/json-api package`);
      }
    })() : {};
  }
  _findBelongsToByJsonApiResource(resource, parentIdentifier, relationship, options = {}) {
    if (!resource) {
      return Promise.resolve(null);
    }
    const key = relationship.definition.key;

    // interleaved promises mean that we MUST cache this here
    // in order to prevent infinite re-render if the request
    // fails.
    if (this._pending[key]) {
      return this._pending[key];
    }
    const identifier = resource.data ? resource.data : null;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected a stable identifier`);
      }
    })(!identifier || isStableIdentifier(identifier)) : {};
    const {
      isStale,
      hasDematerializedInverse,
      hasReceivedData,
      isEmpty,
      shouldForceReload
    } = relationship.state;
    const allInverseRecordsAreLoaded = areAllInverseRecordsLoaded(this.store, resource);
    const shouldFindViaLink = resource.links?.related && (shouldForceReload || hasDematerializedInverse || isStale || !allInverseRecordsAreLoaded && !isEmpty);
    const field = this.store.schema.fields(this.identifier).get(relationship.definition.key);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Attempted to access a belongsTo relationship but no definition exists for it`);
      }
    })(field && field.kind === 'belongsTo') : {};
    const request = {
      useLink: shouldFindViaLink,
      field,
      links: resource.links,
      meta: resource.meta,
      options,
      record: parentIdentifier
    };

    // fetch via link
    if (shouldFindViaLink) {
      const future = this.store.request({
        op: 'findBelongsTo',
        records: identifier ? [identifier] : [],
        data: request,
        cacheOptions: {
          [Symbol.for('wd:skip-cache')]: true
        }
      });
      this._pending[key] = future.then(doc => doc.content).finally(() => {
        this._pending[key] = undefined;
      });
      return this._pending[key];
    }
    const preferLocalCache = hasReceivedData && allInverseRecordsAreLoaded && !isEmpty;
    const hasLocalPartialData = hasDematerializedInverse || isEmpty && resource.data;
    // null is explicit empty, undefined is "we don't know anything"
    const localDataIsEmpty = !identifier;
    const attemptLocalCache = !shouldForceReload && !isStale && (preferLocalCache || hasLocalPartialData);

    // we dont need to fetch and are empty
    if (attemptLocalCache && localDataIsEmpty) {
      return Promise.resolve(null);
    }

    // we dont need to fetch because we are local state
    const resourceIsLocal = identifier?.id === null;
    if (attemptLocalCache && allInverseRecordsAreLoaded || resourceIsLocal) {
      return Promise.resolve(identifier);
    }

    // we may need to fetch
    if (identifier) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Cannot fetch belongs-to relationship with no information`);
        }
      })(identifier) : {};
      options.reload = options.reload || !attemptLocalCache || undefined;
      this._pending[key] = this.store.request({
        op: 'findBelongsTo',
        records: [identifier],
        data: request,
        cacheOptions: {
          [Symbol.for('wd:skip-cache')]: true
        }
      }).then(doc => doc.content).finally(() => {
        this._pending[key] = undefined;
      });
      return this._pending[key];
    }

    // we were explicitly told we have no data and no links.
    //   TODO if the relationshipIsStale, should we hit the adapter anyway?
    return Promise.resolve(null);
  }
  destroy() {
    this.isDestroying = true;
    let cache = this._manyArrayCache;
    this._manyArrayCache = Object.create(null);
    Object.keys(cache).forEach(key => {
      cache[key].destroy();
    });
    cache = this._relationshipProxyCache;
    this._relationshipProxyCache = Object.create(null);
    Object.keys(cache).forEach(key => {
      const proxy = cache[key];
      if (proxy.destroy) {
        proxy.destroy();
      }
    });
    cache = this.references;
    this.references = Object.create(null);
    Object.keys(cache).forEach(key => {
      cache[key].destroy();
    });
    this.isDestroyed = true;
  }
}
function handleCompletedRelationshipRequest(recordExt, key, relationship, value, error) {
  delete recordExt._relationshipPromisesCache[key];
  relationship.state.shouldForceReload = false;
  const isHasMany = relationship.definition.kind === 'hasMany';
  if (isHasMany) {
    // we don't notify the record property here to avoid refetch
    // only the many array
    value.notify();
  }
  if (error) {
    relationship.state.hasFailedLoadAttempt = true;
    const proxy = recordExt._relationshipProxyCache[key];
    // belongsTo relationships are sometimes unloaded
    // when a load fails, in this case we need
    // to make sure that we aren't proxying
    // to destroyed content
    // for the sync belongsTo reload case there will be no proxy
    // for the async reload case there will be no proxy if the ui
    // has never been accessed
    if (proxy && !isHasMany) {
      // @ts-expect-error unsure why this is not resolving the boolean but async belongsTo is weird
      if (proxy.content && proxy.content.isDestroying) {
        proxy.set('content', null);
      }
      recordExt.store.notifications._flush();
    }
    throw error;
  }
  if (isHasMany) {
    value.isLoaded = true;
  } else {
    recordExt.store.notifications._flush();
  }
  relationship.state.hasFailedLoadAttempt = false;
  // only set to not stale if no error is thrown
  relationship.state.isStale = false;
  return isHasMany || !value ? value : recordExt.store.peekRecord(value);
}
function extractIdentifierFromRecord(record) {
  if (!record) {
    return null;
  }
  return recordIdentifierFor(record);
}
function anyUnloaded(store, relationship) {
  const graph = store._graph;
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected a Graph instance to be available`);
    }
  })(graph) : {};
  const relationshipData = graph.getData(relationship.identifier, relationship.definition.key);
  const state = relationshipData.data;
  const cache = store._instanceCache;
  const unloaded = state?.find(s => {
    const isLoaded = cache.recordIsLoaded(s, true);
    return !isLoaded;
  });
  return unloaded || false;
}
function areAllInverseRecordsLoaded(store, resource) {
  const instanceCache = store._instanceCache;
  const identifiers = resource.data;
  if (Array.isArray(identifiers)) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected stable identifiers`);
      }
    })(identifiers.every(isStableIdentifier)) : {};
    // treat as collection
    // check for unloaded records
    return identifiers.every(identifier => instanceCache.recordIsLoaded(identifier));
  }

  // treat as single resource
  if (!identifiers) return true;
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected stable identifiers`);
    }
  })(isStableIdentifier(identifiers)) : {};
  return instanceCache.recordIsLoaded(identifiers);
}
function isBelongsTo(relationship) {
  return relationship.definition.kind === 'belongsTo';
}

// we force the type here to our own construct because mixin and extend patterns
// lose generic signatures. We also do this because we need to Omit `clear` from
// the type of ArrayProxy as we override it's signature.
const ArrayProxyWithCustomOverrides = ArrayProxy;

/**
  Holds validation errors for a given record, organized by attribute names.

  This class is not directly instantiable.

  Every `Model` has an `errors` property that is an instance of
  `Errors`. This can be used to display validation error
  messages returned from the server when a `record.save()` rejects.

  For Example, if you had a `User` model that looked like this:

  ```app/models/user.js
  import Model, { attr } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('string') username;
    @attr('string') email;
  }
  ```
  And you attempted to save a record that did not validate on the backend:

  ```javascript
  let user = store.createRecord('user', {
    username: 'tomster',
    email: 'invalidEmail'
  });
  user.save();
  ```

  Your backend would be expected to return an error response that described
  the problem, so that error messages can be generated on the app.

  API responses will be translated into instances of `Errors` differently,
  depending on the specific combination of adapter and serializer used. You
  may want to check the documentation or the source code of the libraries
  that you are using, to know how they expect errors to be communicated.

  Errors can be displayed to the user by accessing their property name
  to get an array of all the error objects for that property. Each
  error object is a JavaScript object with two keys:

  - `message` A string containing the error message from the backend
  - `attribute` The name of the property associated with this error message

  ```handlebars
  <label>Username: <Input @value={{@model.username}} /> </label>
  {{#each @model.errors.username as |error|}}
    <div class="error">
      {{error.message}}
    </div>
  {{/each}}

  <label>Email: <Input @value={{@model.email}} /> </label>
  {{#each @model.errors.email as |error|}}
    <div class="error">
      {{error.message}}
    </div>
  {{/each}}
  ```

  You can also access the special `messages` property on the error
  object to get an array of all the error strings.

  ```handlebars
  {{#each @model.errors.messages as |message|}}
    <div class="error">
      {{message}}
    </div>
  {{/each}}
  ```

  @class Errors
  @public
  @extends Ember.ArrayProxy
 */
class Errors extends ArrayProxyWithCustomOverrides {
  /**
    @property errorsByAttributeName
    @type {MapWithDefault}
    @private
  */
  get errorsByAttributeName() {
    return new Map();
  }

  /**
    Returns errors for a given attribute
     ```javascript
    let user = store.createRecord('user', {
      username: 'tomster',
      email: 'invalidEmail'
    });
    user.save().catch(function(){
      user.errors.errorsFor('email'); // returns:
      // [{attribute: "email", message: "Doesn't look like a valid email."}]
    });
    ```
     @method errorsFor
    @public
    @param {String} attribute
    @return {Array}
  */
  static {
    decorateMethodV2(this.prototype, "errorsByAttributeName", [computed()]);
  }
  errorsFor(attribute) {
    const map = this.errorsByAttributeName;
    let errors = map.get(attribute);
    if (errors === undefined) {
      errors = A();
      map.set(attribute, errors);
    }

    // Errors may be a native array with extensions turned on. Since we access
    // the array via a method, and not a computed or using `Ember.get`, it does
    // not entangle properly with autotracking, so we entangle manually by
    // getting the `[]` property.
    get(errors, '[]');
    return errors;
  }

  /**
    An array containing all of the error messages for this
    record. This is useful for displaying all errors to the user.
     ```handlebars
    {{#each @model.errors.messages as |message|}}
      <div class="error">
        {{message}}
      </div>
    {{/each}}
    ```
     @property messages
    @public
    @type {Array}
  */
  static {
    decorateFieldV2(this.prototype, "messages", [mapBy('content', 'message')]);
  }
  #messages = (initializeDeferredDecorator(this, "messages"), void 0);
  /**
    @property content
    @type {Array}
    @private
  */
  get content() {
    return A();
  }

  /**
    @method unknownProperty
    @private
  */
  static {
    decorateMethodV2(this.prototype, "content", [computed()]);
  }
  unknownProperty(attribute) {
    const errors = this.errorsFor(attribute);
    if (errors.length === 0) {
      return undefined;
    }
    return errors;
  }

  /**
    Total number of errors.
     @property length
    @type {Number}
    @public
    @readOnly
  */

  /**
    `true` if we have no errors.
     @property isEmpty
    @type {Boolean}
    @public
    @readOnly
  */
  static {
    decorateFieldV2(this.prototype, "isEmpty", [not('length')]);
  }
  #isEmpty = (initializeDeferredDecorator(this, "isEmpty"), void 0);
  /**
   Manually adds errors to the record. This will trigger the `becameInvalid` event/ lifecycle method on
    the record and transition the record into an `invalid` state.
    Example
   ```javascript
    let errors = user.errors;
     // add multiple errors
    errors.add('password', [
      'Must be at least 12 characters',
      'Must contain at least one symbol',
      'Cannot contain your name'
    ]);
     errors.errorsFor('password');
    // =>
    // [
    //   { attribute: 'password', message: 'Must be at least 12 characters' },
    //   { attribute: 'password', message: 'Must contain at least one symbol' },
    //   { attribute: 'password', message: 'Cannot contain your name' },
    // ]
     // add a single error
    errors.add('username', 'This field is required');
     errors.errorsFor('username');
    // =>
    // [
    //   { attribute: 'username', message: 'This field is required' },
    // ]
   ```
    @method add
    @public
    @param {string} attribute - the property name of an attribute or relationship
    @param {string[]|string} messages - an error message or array of error messages for the attribute
   */
  add(attribute, messages) {
    const errors = this._findOrCreateMessages(attribute, messages);
    this.addObjects(errors);
    this.errorsFor(attribute).addObjects(errors);
    this.__record.currentState.notify('isValid');
    this.notifyPropertyChange(attribute);
  }

  /**
    @method _findOrCreateMessages
    @private
  */
  _findOrCreateMessages(attribute, messages) {
    const errors = this.errorsFor(attribute);
    const messagesArray = Array.isArray(messages) ? messages : [messages];
    const _messages = new Array(messagesArray.length);
    for (let i = 0; i < messagesArray.length; i++) {
      const message = messagesArray[i];
      const err = errors.findBy('message', message);
      if (err) {
        _messages[i] = err;
      } else {
        _messages[i] = {
          attribute: attribute,
          message
        };
      }
    }
    return _messages;
  }

  /**
   Manually removes all errors for a given member from the record.
     This will transition the record into a `valid` state, and
    triggers the `becameValid` event and lifecycle method.
    Example:
    ```javascript
    let errors = user.errors;
    errors.add('phone', ['error-1', 'error-2']);
     errors.errorsFor('phone');
    // =>
    // [
    //   { attribute: 'phone', message: 'error-1' },
    //   { attribute: 'phone', message: 'error-2' },
    // ]
     errors.remove('phone');
     errors.errorsFor('phone');
    // => undefined
   ```
   @method remove
    @public
   @param {string} member - the property name of an attribute or relationship
   */
  remove(attribute) {
    if (this.isEmpty) {
      return;
    }
    const content = this.rejectBy('attribute', attribute);
    this.content.setObjects(content);

    // Although errorsByAttributeName.delete is technically enough to sync errors state, we also
    // must mutate the array as well for autotracking
    const errors = this.errorsFor(attribute);
    for (let i = 0; i < errors.length; i++) {
      if (errors[i].attribute === attribute) {
        // .replace from Ember.NativeArray is necessary. JS splice will not work.
        errors.replace(i, 1);
      }
    }
    this.errorsByAttributeName.delete(attribute);
    this.__record.currentState.notify('isValid');
    this.notifyPropertyChange(attribute);
    this.notifyPropertyChange('length');
  }

  /**
   Manually clears all errors for the record.
     This will transition the record into a `valid` state, and
     will trigger the `becameValid` event and lifecycle method.
   Example:
    ```javascript
   let errors = user.errors;
   errors.add('username', ['error-a']);
   errors.add('phone', ['error-1', 'error-2']);
    errors.errorsFor('username');
   // =>
   // [
   //   { attribute: 'username', message: 'error-a' },
   // ]
    errors.errorsFor('phone');
   // =>
   // [
   //   { attribute: 'phone', message: 'error-1' },
   //   { attribute: 'phone', message: 'error-2' },
   // ]
    errors.clear();
    errors.errorsFor('username');
   // => undefined
    errors.errorsFor('phone');
   // => undefined
    errors.messages
   // => []
   ```
   @method clear
   @public
   */
  clear() {
    if (this.isEmpty) {
      return;
    }
    const errorsByAttributeName = this.errorsByAttributeName;
    const attributes = [];
    errorsByAttributeName.forEach(function (_, attribute) {
      attributes.push(attribute);
    });
    errorsByAttributeName.clear();
    attributes.forEach(attribute => {
      this.notifyPropertyChange(attribute);
    });
    this.__record.currentState.notify('isValid');
    super.clear();
  }

  /**
    Checks if there are error messages for the given attribute.
     ```app/controllers/user/edit.js
    export default class UserEditController extends Controller {
      @action
      save(user) {
        if (user.errors.has('email')) {
          return alert('Please update your email before attempting to save.');
        }
        user.save();
      }
    }
    ```
     @method has
    @public
    @param {String} attribute
    @return {Boolean} true if there some errors on given attribute
  */
  has(attribute) {
    return this.errorsFor(attribute).length > 0;
  }
}
function rollbackAttributes() {
  const {
    currentState
  } = this;
  const {
    isNew
  } = currentState;
  this[RecordStore]._join(() => {
    peekCache(this).rollbackAttrs(recordIdentifierFor$1(this));
    this.errors.clear();
    currentState.cleanErrorRequests();
    if (isNew) {
      this.unloadRecord();
    }
  });
}
function unloadRecord() {
  if (this.currentState.isNew && (this.isDestroyed || this.isDestroying)) {
    return;
  }
  this[RecordStore].unloadRecord(this);
}
function belongsTo(prop) {
  return lookupLegacySupport(this).referenceFor('belongsTo', prop);
}
function hasMany(prop) {
  return lookupLegacySupport(this).referenceFor('hasMany', prop);
}
function reload(options = {}) {
  options.isReloading = true;
  options.reload = true;
  const identifier = recordIdentifierFor$1(this);
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`You cannot reload a record without an ID`);
    }
  })(identifier.id) : {};
  this.isReloading = true;
  const promise = this[RecordStore].request({
    op: 'findRecord',
    data: {
      options,
      record: identifier
    },
    cacheOptions: {
      [Symbol.for('wd:skip-cache')]: true
    }
  }).then(() => this).finally(() => {
    this.isReloading = false;
  });
  return promise;
}
function changedAttributes() {
  return peekCache(this).changedAttrs(recordIdentifierFor$1(this));
}
function serialize(options) {
  upgradeStore(this[RecordStore]);
  return this[RecordStore].serializeRecord(this, options);
}
function deleteRecord() {
  // ensure we've populated currentState prior to deleting a new record
  if (this.currentState) {
    this[RecordStore].deleteRecord(this);
  }
}
function save(options) {
  let promise;
  if (this.currentState.isNew && this.currentState.isDeleted) {
    promise = Promise.resolve(this);
  } else {
    this.errors.clear();
    promise = this[RecordStore].saveRecord(this, options);
  }
  return promise;
}
function destroyRecord(options) {
  const {
    isNew
  } = this.currentState;
  this.deleteRecord();
  if (isNew) {
    return Promise.resolve(this);
  }
  return this.save(options).then(_ => {
    this.unloadRecord();
    return this;
  });
}
function createSnapshot() {
  const store = this[RecordStore];
  upgradeStore(store);
  if (!store._fetchManager) {
    const FetchManager = importSync('@ember-data/legacy-compat/-private').FetchManager;
    store._fetchManager = new FetchManager(store);
  }

  // @ts-expect-error Typescript isn't able to curry narrowed args that are divorced from each other.
  return store._fetchManager.createSnapshot(recordIdentifierFor$1(this));
}
function notifyChanges(identifier, value, key, record, store) {
  if (value === 'attributes') {
    if (key) {
      notifyAttribute(store, identifier, key, record);
    } else {
      record.eachAttribute(name => {
        notifyAttribute(store, identifier, name, record);
      });
    }
  } else if (value === 'relationships') {
    if (key) {
      const meta = record.constructor.relationshipsByName.get(key);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected to find a relationship for ${key} on ${identifier.type}`);
        }
      })(meta) : {};
      notifyRelationship(identifier, key, record, meta);
    } else {
      record.eachRelationship((name, meta) => {
        notifyRelationship(identifier, name, record, meta);
      });
    }
  } else if (value === 'identity') {
    record.notifyPropertyChange('id');
  }
}
function notifyRelationship(identifier, key, record, meta) {
  if (meta.kind === 'belongsTo') {
    record.notifyPropertyChange(key);
  } else if (meta.kind === 'hasMany') {
    const support = LEGACY_SUPPORT.get(identifier);
    const manyArray = support && support._manyArrayCache[key];
    const hasPromise = support && support._relationshipPromisesCache[key];
    if (manyArray && hasPromise) {
      // do nothing, we will notify the ManyArray directly
      // once the fetch has completed.
      return;
    }
    if (manyArray) {
      manyArray.notify();

      //We need to notifyPropertyChange in the adding case because we need to make sure
      //we fetch the newly added record in case it is unloaded
      //TODO(Igor): Consider whether we could do this only if the record state is unloaded
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected options to exist on relationship meta`);
        }
      })(meta.options) : {};
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected async to exist on relationship meta options`);
        }
      })('async' in meta.options) : {};
      if (meta.options.async) {
        record.notifyPropertyChange(key);
      }
    }
  }
}
function notifyAttribute(store, identifier, key, record) {
  const currentValue = cacheFor(record, key);
  const cache = store.cache;
  if (currentValue !== cache.getAttr(identifier, key)) {
    record.notifyPropertyChange(key);
  }
}
const SOURCE_POINTER_REGEXP = /^\/?data\/(attributes|relationships)\/(.*)/;
const SOURCE_POINTER_PRIMARY_REGEXP = /^\/?data/;
const PRIMARY_ATTRIBUTE_KEY = 'base';
function isInvalidError(error) {
  return !!error && error instanceof Error && 'isAdapterError' in error && error.isAdapterError === true && 'code' in error && error.code === 'InvalidError';
}

/**
 * A decorator that caches a getter while
 * providing the ability to bust that cache
 * when we so choose in a way that notifies
 * tracking systems.
 *
 * @internal
 */
function tagged(_target, key, desc) {
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const getter = desc.get;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const setter = desc.set;
  desc.get = function () {
    const signal = getSignal(this, key, true);
    subscribe(signal);
    if (signal.shouldReset) {
      signal.shouldReset = false;
      signal.lastValue = getter.call(this);
    }
    return signal.lastValue;
  };
  desc.set = function (v) {
    getSignal(this, key, true); // ensure signal is setup in case we want to use it.
    // probably notify here but not yet.
    setter.call(this, v);
  };
  compat(desc);
  return desc;
}
function notifySignal(obj, key) {
  const signal = peekSignal(obj, key);
  if (signal) {
    signal.shouldReset = true;
    addToTransaction(signal);
  }
}

/**
Historically EmberData managed a state machine
for each record, the localState for which
was reflected onto Model.

This implements the flags and stateName for backwards compat
with the state tree that used to be possible (listed below).

stateName and dirtyType are candidates for deprecation.

root
  empty
    deleted    // hidden from stateName
    preloaded  // hidden from stateName

  loading
     empty     // hidden from stateName
     preloaded // hidden from stateName

  loaded
    saved
    updated
      uncommitted
      invalid
      inFlight
    created
      uncommitted
      invalid
      inFlight

  deleted
    saved
      new      // hidden from stateName
    uncommitted
    invalid
    inFlight

  @internal
*/
class RecordState {
  constructor(record) {
    const store = storeFor$1(record);
    const identity = recordIdentifierFor(record);
    this.identifier = identity;
    this.record = record;
    this.cache = store.cache;
    this.pendingCount = 0;
    this.fulfilledCount = 0;
    this.rejectedCount = 0;
    this._errorRequests = [];
    this._lastError = null;
    const requests = store.getRequestStateService();
    const notifications = store.notifications;
    const handleRequest = req => {
      if (req.type === 'mutation') {
        switch (req.state) {
          case 'pending':
            this.isSaving = true;
            break;
          case 'rejected':
            this.isSaving = false;
            this._lastError = req;
            if (!(req.response && isInvalidError(req.response.data))) {
              this._errorRequests.push(req);
            }
            notifyErrorsStateChanged(this);
            break;
          case 'fulfilled':
            this._errorRequests = [];
            this._lastError = null;
            this.isSaving = false;
            this.notify('isDirty');
            notifyErrorsStateChanged(this);
            break;
        }
      } else {
        switch (req.state) {
          case 'pending':
            this.pendingCount++;
            this.notify('isLoading');
            break;
          case 'rejected':
            this.pendingCount--;
            this._lastError = req;
            if (!(req.response && isInvalidError(req.response.data))) {
              this._errorRequests.push(req);
            }
            this.notify('isLoading');
            notifyErrorsStateChanged(this);
            break;
          case 'fulfilled':
            this.pendingCount--;
            this.fulfilledCount++;
            this.notify('isLoading');
            this.notify('isDirty');
            notifyErrorsStateChanged(this);
            this._errorRequests = [];
            this._lastError = null;
            break;
        }
      }
    };
    requests.subscribeForRecord(identity, handleRequest);

    // we instantiate lazily
    // so we grab anything we don't have yet
    const lastRequest = requests.getLastRequestForRecord(identity);
    if (lastRequest) {
      handleRequest(lastRequest);
    }
    this.handler = notifications.subscribe(identity, (identifier, type, key) => {
      switch (type) {
        case 'state':
          this.notify('isSaved');
          this.notify('isNew');
          this.notify('isDeleted');
          this.notify('isDirty');
          break;
        case 'attributes':
          this.notify('isEmpty');
          this.notify('isDirty');
          break;
        case 'errors':
          this.updateInvalidErrors(this.record.errors);
          this.notify('isValid');
          break;
      }
    });
  }
  destroy() {
    storeFor$1(this.record).notifications.unsubscribe(this.handler);
  }
  notify(key) {
    notifySignal(this, key);
  }
  updateInvalidErrors(errors) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the Cache instance for ${this.identifier.lid}  to implement getErrors(identifier)`);
      }
    })(typeof this.cache.getErrors === 'function') : {};
    const jsonApiErrors = this.cache.getErrors(this.identifier);
    errors.clear();
    for (let i = 0; i < jsonApiErrors.length; i++) {
      const error = jsonApiErrors[i];
      if (error.source && error.source.pointer) {
        const keyMatch = error.source.pointer.match(SOURCE_POINTER_REGEXP);
        let key;
        if (keyMatch) {
          key = keyMatch[2];
        } else if (error.source.pointer.search(SOURCE_POINTER_PRIMARY_REGEXP) !== -1) {
          key = PRIMARY_ATTRIBUTE_KEY;
        }
        if (key) {
          const errMsg = error.detail || error.title;
          macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
            if (!test) {
              throw new Error(`Expected field error to have a detail or title to use as the message`);
            }
          })(errMsg) : {};
          errors.add(key, errMsg);
        }
      }
    }
  }
  cleanErrorRequests() {
    this.notify('isValid');
    this.notify('isError');
    this.notify('adapterError');
    this._errorRequests = [];
    this._lastError = null;
  }
  get isLoading() {
    return !this.isLoaded && this.pendingCount > 0 && this.fulfilledCount === 0;
  }
  static {
    decorateMethodV2(this.prototype, "isLoading", [tagged]);
  }
  get isLoaded() {
    if (this.isNew) {
      return true;
    }
    return this.fulfilledCount > 0 || !this.isEmpty;
  }
  static {
    decorateMethodV2(this.prototype, "isLoaded", [tagged]);
  }
  get isSaved() {
    const rd = this.cache;
    if (this.isDeleted) {
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected Cache to implement isDeletionCommitted()`);
        }
      })(typeof rd.isDeletionCommitted === 'function') : {};
      return rd.isDeletionCommitted(this.identifier);
    }
    if (this.isNew || this.isEmpty || !this.isValid || this.isDirty || this.isLoading) {
      return false;
    }
    return true;
  }
  static {
    decorateMethodV2(this.prototype, "isSaved", [tagged]);
  }
  get isEmpty() {
    const rd = this.cache;
    // TODO this is not actually an RFC'd concept. Determine the
    // correct heuristic to replace this with.
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected Cache to implement isEmpty()`);
      }
    })(typeof rd.isEmpty === 'function') : {};
    return !this.isNew && rd.isEmpty(this.identifier);
  }
  static {
    decorateMethodV2(this.prototype, "isEmpty", [tagged]);
  }
  get isNew() {
    const rd = this.cache;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected Cache to implement isNew()`);
      }
    })(typeof rd.isNew === 'function') : {};
    return rd.isNew(this.identifier);
  }
  static {
    decorateMethodV2(this.prototype, "isNew", [tagged]);
  }
  get isDeleted() {
    const rd = this.cache;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected Cache to implement isDeleted()`);
      }
    })(typeof rd.isDeleted === 'function') : {};
    return rd.isDeleted(this.identifier);
  }
  static {
    decorateMethodV2(this.prototype, "isDeleted", [tagged]);
  }
  get isValid() {
    return this.record.errors.length === 0;
  }
  static {
    decorateMethodV2(this.prototype, "isValid", [tagged]);
  }
  get isDirty() {
    const rd = this.cache;
    if (this.isEmpty || rd.isDeletionCommitted(this.identifier) || this.isDeleted && this.isNew) {
      return false;
    }
    return this.isDeleted || this.isNew || rd.hasChangedAttrs(this.identifier);
  }
  static {
    decorateMethodV2(this.prototype, "isDirty", [tagged]);
  }
  get isError() {
    const errorReq = this._errorRequests[this._errorRequests.length - 1];
    if (!errorReq) {
      return false;
    } else {
      return true;
    }
  }
  static {
    decorateMethodV2(this.prototype, "isError", [tagged]);
  }
  get adapterError() {
    const request = this._lastError;
    if (!request) {
      return null;
    }
    return request.state === 'rejected' && request.response.data;
  }
  static {
    decorateMethodV2(this.prototype, "adapterError", [tagged]);
  }
  get isPreloaded() {
    return !this.isEmpty && this.isLoading;
  }
  static {
    decorateMethodV2(this.prototype, "isPreloaded", [cached]);
  }
  get stateName() {
    // we might be empty while loading so check this first
    if (this.isLoading) {
      return 'root.loading';

      // got nothing yet or were unloaded
    } else if (this.isEmpty) {
      return 'root.empty';

      // deleted substates
    } else if (this.isDeleted) {
      if (this.isSaving) {
        return 'root.deleted.inFlight';
      } else if (this.isSaved) {
        // TODO ensure isSaved isn't true from previous requests
        return 'root.deleted.saved';
      } else if (!this.isValid) {
        return 'root.deleted.invalid';
      } else {
        return 'root.deleted.uncommitted';
      }

      // loaded.created substates
    } else if (this.isNew) {
      if (this.isSaving) {
        return 'root.loaded.created.inFlight';
      } else if (!this.isValid) {
        return 'root.loaded.created.invalid';
      }
      return 'root.loaded.created.uncommitted';

      // loaded.updated substates
    } else if (this.isSaving) {
      return 'root.loaded.updated.inFlight';
    } else if (!this.isValid) {
      return 'root.loaded.updated.invalid';
    } else if (this.isDirty) {
      return 'root.loaded.updated.uncommitted';

      // if nothing remains, we are loaded saved!
    } else {
      return 'root.loaded.saved';
    }
  }
  static {
    decorateMethodV2(this.prototype, "stateName", [cached]);
  }
  get dirtyType() {
    // we might be empty while loading so check this first
    if (this.isLoading || this.isEmpty) {
      return '';

      // deleted substates
    } else if (this.isDirty && this.isDeleted) {
      return 'deleted';

      // loaded.created substates
    } else if (this.isNew) {
      return 'created';

      // loaded.updated substates
    } else if (this.isSaving || !this.isValid || this.isDirty) {
      return 'updated';

      // if nothing remains, we are loaded saved!
    } else {
      return '';
    }
  }
  static {
    decorateMethodV2(this.prototype, "dirtyType", [cached]);
  }
}
defineSignal(RecordState.prototype, 'isSaving', false);
function notifyErrorsStateChanged(state) {
  state.notify('isValid');
  state.notify('isError');
  state.notify('adapterError');
}

/**
  @module @ember-data/model
 */

/*
 * This decorator allows us to lazily compute
 * an expensive getter on first-access and thereafter
 * never recompute it.
 */
function computeOnce(target, propertyName, desc) {
  const cache = new WeakMap();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const getter = desc.get;
  desc.get = function () {
    let meta = cache.get(this);
    if (!meta) {
      meta = {
        hasComputed: false,
        value: undefined
      };
      cache.set(this, meta);
    }
    if (!meta.hasComputed) {
      meta.value = getter.call(this);
      meta.hasComputed = true;
    }
    return meta.value;
  };
  return desc;
}

/**
  Base class from which Models can be defined.

  ```js
  import Model, { attr } from '@ember-data/model';

  export default class User extends Model {
    @attr name;
  }
  ```

  Models are used both to define the static schema for a
  particular resource type as well as the class to instantiate
  to present that data from cache.

  @class Model
  @public
  @extends Ember.EmberObject
*/

class Model extends EmberObject {
  // set during create by the store

  init(options) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (!options?._secretInit && !options?._createProps) {
        throw new Error('You should not call `create` on a model. Instead, call `store.createRecord` with the attributes you would like to set.');
      }
    }
    const createProps = options._createProps;
    const _secretInit = options._secretInit;
    options._createProps = null;
    options._secretInit = null;
    const store = this.store = _secretInit.store;
    super.init(options);
    this[RecordStore] = store;
    const identity = _secretInit.identifier;
    _secretInit.cb(this, _secretInit.cache, identity, _secretInit.store);
    this.___recordState = macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? new RecordState(this) : null;
    this.setProperties(createProps);
    const notifications = store.notifications;
    this.___private_notifications = notifications.subscribe(identity, (identifier, type, field) => {
      notifyChanges(identifier, type, field, this, store);
    });
  }

  // @ts-expect-error destroy should not return a value, but ember's types force it to
  destroy() {
    const identifier = recordIdentifierFor$1(this);
    this.___recordState?.destroy();
    const store = storeFor$1(this);
    store.notifications.unsubscribe(this.___private_notifications);
    // Legacy behavior is to notify the relationships on destroy
    // such that they "clear". It's uncertain this behavior would
    // be good for a new model paradigm, likely cheaper and safer
    // to simply not notify, for this reason the store does not itself
    // notify individual changes once the delete has been signaled,
    // this decision is left to model instances.

    this.eachRelationship((name, meta) => {
      if (meta.kind === 'belongsTo') {
        this.notifyPropertyChange(name);
      }
    });
    LEGACY_SUPPORT.get(this)?.destroy();
    LEGACY_SUPPORT.delete(this);
    LEGACY_SUPPORT.delete(identifier);
    super.destroy();
  }

  /**
    If this property is `true` the record is in the `empty`
    state. Empty is the first state all records enter after they have
    been created. Most records created by the store will quickly
    transition to the `loading` state if data needs to be fetched from
    the server or the `created` state if the record is created on the
    client. A record can also enter the empty state if the adapter is
    unable to locate the record.
     @property isEmpty
    @public
    @type {Boolean}
    @readOnly
  */
  get isEmpty() {
    return this.currentState.isEmpty;
  }

  /**
    If this property is `true` the record is in the `loading` state. A
    record enters this state when the store asks the adapter for its
    data. It remains in this state until the adapter provides the
    requested data.
     @property isLoading
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isEmpty", [compat]);
  }
  get isLoading() {
    return this.currentState.isLoading;
  }

  /**
    If this property is `true` the record is in the `loaded` state. A
    record enters this state when its data is populated. Most of a
    record's lifecycle is spent inside substates of the `loaded`
    state.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.isLoaded; // true
     store.findRecord('model', 1).then(function(model) {
      model.isLoaded; // true
    });
    ```
     @property isLoaded
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isLoading", [compat]);
  }
  get isLoaded() {
    return this.currentState.isLoaded;
  }

  /**
    If this property is `true` the record is in the `dirty` state. The
    record has local changes that have not yet been saved by the
    adapter. This includes records that have been created (but not yet
    saved) or deleted.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.hasDirtyAttributes; // true
     store.findRecord('model', 1).then(function(model) {
      model.hasDirtyAttributes; // false
      model.set('foo', 'some value');
      model.hasDirtyAttributes; // true
    });
    ```
     @since 1.13.0
    @property hasDirtyAttributes
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isLoaded", [compat]);
  }
  get hasDirtyAttributes() {
    return this.currentState.isDirty;
  }

  /**
    If this property is `true` the record is in the `saving` state. A
    record enters the saving state when `save` is called, but the
    adapter has not yet acknowledged that the changes have been
    persisted to the backend.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.isSaving; // false
    let promise = record.save();
    record.isSaving; // true
    promise.then(function() {
      record.isSaving; // false
    });
    ```
     @property isSaving
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "hasDirtyAttributes", [compat]);
  }
  get isSaving() {
    return this.currentState.isSaving;
  }

  /**
    If this property is `true` the record is in the `deleted` state
    and has been marked for deletion. When `isDeleted` is true and
    `hasDirtyAttributes` is true, the record is deleted locally but the deletion
    was not yet persisted. When `isSaving` is true, the change is
    in-flight. When both `hasDirtyAttributes` and `isSaving` are false, the
    change has persisted.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.isDeleted;    // false
    record.deleteRecord();
     // Locally deleted
    record.isDeleted;           // true
    record.hasDirtyAttributes;  // true
    record.isSaving;            // false
     // Persisting the deletion
    let promise = record.save();
    record.isDeleted;    // true
    record.isSaving;     // true
     // Deletion Persisted
    promise.then(function() {
      record.isDeleted;          // true
      record.isSaving;           // false
      record.hasDirtyAttributes; // false
    });
    ```
     @property isDeleted
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isSaving", [compat]);
  }
  get isDeleted() {
    return this.currentState.isDeleted;
  }

  /**
    If this property is `true` the record is in the `new` state. A
    record will be in the `new` state when it has been created on the
    client and the adapter has not yet report that it was successfully
    saved.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.isNew; // true
     record.save().then(function(model) {
      model.isNew; // false
    });
    ```
     @property isNew
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isDeleted", [compat]);
  }
  get isNew() {
    return this.currentState.isNew;
  }

  /**
    If this property is `true` the record is in the `valid` state.
     A record will be in the `valid` state when the adapter did not report any
    server-side validation failures.
     @property isValid
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isNew", [compat]);
  }
  get isValid() {
    return this.currentState.isValid;
  }

  /**
    If the record is in the dirty state this property will report what
    kind of change has caused it to move into the dirty
    state. Possible values are:
     - `created` The record has been created by the client and not yet saved to the adapter.
    - `updated` The record has been updated by the client and not yet saved to the adapter.
    - `deleted` The record has been deleted by the client and not yet saved to the adapter.
     Example
     ```javascript
    let record = store.createRecord('model');
    record.dirtyType; // 'created'
    ```
     @property dirtyType
    @public
    @type {String}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "isValid", [compat]);
  }
  get dirtyType() {
    return this.currentState.dirtyType;
  }

  /**
    If `true` the adapter reported that it was unable to save local
    changes to the backend for any reason other than a server-side
    validation error.
     Example
     ```javascript
    record.isError; // false
    record.set('foo', 'valid value');
    record.save().then(null, function() {
      record.isError; // true
    });
    ```
     @property isError
    @public
    @type {Boolean}
    @readOnly
  */
  static {
    decorateMethodV2(this.prototype, "dirtyType", [compat]);
  }
  get isError() {
    return this.currentState.isError;
  }
  static {
    decorateMethodV2(this.prototype, "isError", [compat]);
  }
  set isError(v) {
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      throw new Error(`isError is not directly settable`);
    }
  }

  /**
    If `true` the store is attempting to reload the record from the adapter.
     Example
     ```javascript
    record.isReloading; // false
    record.reload();
    record.isReloading; // true
    ```
     @property isReloading
    @public
    @type {Boolean}
    @readOnly
  */

  /**
    All ember models have an id property. This is an identifier
    managed by an external source. These are always coerced to be
    strings before being used internally. Note when declaring the
    attributes for a model it is an error to declare an id
    attribute.
     ```javascript
    let record = store.createRecord('model');
    record.id; // null
     store.findRecord('model', 1).then(function(model) {
      model.id; // '1'
    });
    ```
     @property id
    @public
    @type {String}
  */
  get id() {
    // this guard exists, because some dev-only deprecation code
    // (addListener via validatePropertyInjections) invokes toString before the
    // object is real.
    if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
      try {
        return recordIdentifierFor$1(this).id;
      } catch {
        return null;
      }
    }
    return recordIdentifierFor$1(this).id;
  }
  static {
    decorateMethodV2(this.prototype, "id", [tagged]);
  }
  set id(id) {
    const normalizedId = coerceId(id);
    const identifier = recordIdentifierFor$1(this);
    const didChange = normalizedId !== identifier.id;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Cannot set ${identifier.type} record's id to ${id}, because id is already ${identifier.id}`);
      }
    })(!didChange || identifier.id === null) : {};
    if (normalizedId !== null && didChange) {
      this.store._instanceCache.setRecordId(identifier, normalizedId);
      this.store.notifications.notify(identifier, 'identity');
    }
  }
  toString() {
    return `<model::${this.constructor.modelName}:${this.id}>`;
  }

  /**
    @property currentState
    @private
    @type {Object}
  */
  // TODO we can probably make this a computeOnce
  // we likely do not need to notify the currentState root anymore
  get currentState() {
    // descriptors are called with the wrong `this` context during mergeMixins
    // when using legacy/classic ember classes. Basically: lazy in prod and eager in dev.
    // so we do this to try to steer folks to the nicer "dont user currentState"
    // error.
    if (macroCondition(!getGlobalConfig().WarpDrive.env.DEBUG)) {
      if (!this.___recordState) {
        this.___recordState = new RecordState(this);
      }
    }
    return this.___recordState;
  }
  static {
    decorateMethodV2(this.prototype, "currentState", [tagged]);
  }
  set currentState(_v) {
    throw new Error('cannot set currentState');
  }

  /**
    The store service instance which created this record instance
    @property store
    @public
   */

  /**
    When the record is in the `invalid` state this object will contain
    any errors returned by the adapter. When present the errors hash
    contains keys corresponding to the invalid property names
    and values which are arrays of Javascript objects with two keys:
     - `message` A string containing the error message from the backend
    - `attribute` The name of the property associated with this error message
     ```javascript
    record.errors.length; // 0
    record.set('foo', 'invalid value');
    record.save().catch(function() {
      record.errors.foo;
      // [{message: 'foo should be a number.', attribute: 'foo'}]
    });
    ```
     The `errors` property is useful for displaying error messages to
    the user.
     ```handlebars
    <label>Username: <Input @value={{@model.username}} /> </label>
    {{#each @model.errors.username as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}
    <label>Email: <Input @value={{@model.email}} /> </label>
    {{#each @model.errors.email as |error|}}
      <div class="error">
        {{error.message}}
      </div>
    {{/each}}
    ```
      You can also access the special `messages` property on the error
    object to get an array of all the error strings.
     ```handlebars
    {{#each @model.errors.messages as |message|}}
      <div class="error">
        {{message}}
      </div>
    {{/each}}
    ```
     @property errors
    @public
    @type {Errors}
  */
  get errors() {
    const errors = Errors.create({
      __record: this
    });
    this.currentState.updateInvalidErrors(errors);
    return errors;
  }

  /**
    This property holds the `AdapterError` object with which
    last adapter operation was rejected.
     @property adapterError
    @public
    @type {AdapterError}
  */
  static {
    decorateMethodV2(this.prototype, "errors", [computeOnce]);
  }
  get adapterError() {
    return this.currentState.adapterError;
  }
  static {
    decorateMethodV2(this.prototype, "adapterError", [compat]);
  }
  set adapterError(v) {
    throw new Error(`adapterError is not directly settable`);
  }

  /**
    Create a JSON representation of the record, using the serialization
    strategy of the store's adapter.
    `serialize` takes an optional hash as a parameter, currently
    supported options are:
    - `includeId`: `true` if the record's ID should be included in the
      JSON representation.
     @method serialize
    @public
    @param {Object} options
    @return {Object} an object whose values are primitive JSON values only
  */
  /*
    We hook the default implementation to ensure
    our tagged properties are properly notified
    as well. We still super for everything because
    sync observers require a direct call occuring
    to trigger their flush. We wouldn't need to
    super in 4.0+ where sync observers are removed.
   */
  // @ts-expect-error no return is necessary, but Ember's types are forcing it
  notifyPropertyChange(prop) {
    notifySignal(this, prop);
    super.notifyPropertyChange(prop);
  }

  /**
    Marks the record as deleted but does not save it. You must call
    `save` afterwards if you want to persist it. You might use this
    method if you want to allow the user to still `rollbackAttributes()`
    after a delete was made.
     Example
     ```js
    import Component from '@glimmer/component';
     export default class extends Component {
      softDelete = () => {
        this.args.model.deleteRecord();
      }
       confirm = () => {
        this.args.model.save();
      }
       undo = () => {
        this.args.model.rollbackAttributes();
      }
    }
    ```
     @method deleteRecord
    @public
  */

  /**
    Same as `deleteRecord`, but saves the record immediately.
     Example
     ```js
    import Component from '@glimmer/component';
     export default class extends Component {
      delete = () => {
        this.args.model.destroyRecord().then(function() {
          this.transitionToRoute('model.index');
        });
      }
    }
    ```
     If you pass an object on the `adapterOptions` property of the options
    argument it will be passed to your adapter via the snapshot
     ```js
    record.destroyRecord({ adapterOptions: { subscribe: false } });
    ```
     ```app/adapters/post.js
    import MyCustomAdapter from './custom-adapter';
     export default class PostAdapter extends MyCustomAdapter {
      deleteRecord(store, type, snapshot) {
        if (snapshot.adapterOptions.subscribe) {
          // ...
        }
        // ...
      }
    }
    ```
     @method destroyRecord
    @public
    @param {Object} options
    @return {Promise} a promise that will be resolved when the adapter returns
    successfully or rejected if the adapter returns with an error.
  */

  /**
    Unloads the record from the store. This will not send a delete request
    to your server, it just unloads the record from memory.
     @method unloadRecord
    @public
  */

  /**
    Returns an object, whose keys are changed properties, and value is
    an [oldProp, newProp] array.
     The array represents the diff of the canonical state with the local state
    of the model. Note: if the model is created locally, the canonical state is
    empty since the adapter hasn't acknowledged the attributes yet:
     Example
     ```app/models/mascot.js
    import Model, { attr } from '@ember-data/model';
     export default class MascotModel extends Model {
      @attr('string') name;
      @attr('boolean', {
        defaultValue: false
      })
      isAdmin;
    }
    ```
     ```javascript
    let mascot = store.createRecord('mascot');
     mascot.changedAttributes(); // {}
     mascot.set('name', 'Tomster');
    mascot.changedAttributes(); // { name: [undefined, 'Tomster'] }
     mascot.set('isAdmin', true);
    mascot.changedAttributes(); // { isAdmin: [undefined, true], name: [undefined, 'Tomster'] }
     mascot.save().then(function() {
      mascot.changedAttributes(); // {}
       mascot.set('isAdmin', false);
      mascot.changedAttributes(); // { isAdmin: [true, false] }
    });
    ```
     @method changedAttributes
    @public
    @return {Object} an object, whose keys are changed properties,
      and value is an [oldProp, newProp] array.
  */

  /**
    If the model `hasDirtyAttributes` this function will discard any unsaved
    changes. If the model `isNew` it will be removed from the store.
     Example
     ```javascript
    record.name; // 'Untitled Document'
    record.set('name', 'Doc 1');
    record.name; // 'Doc 1'
    record.rollbackAttributes();
    record.name; // 'Untitled Document'
    ```
     @since 1.13.0
    @method rollbackAttributes
    @public
  */

  /**
    @method _createSnapshot
    @private
  */
  // TODO @deprecate in favor of a public API or examples of how to test successfully

  /**
    Save the record and persist any changes to the record to an
    external source via the adapter.
     Example
     ```javascript
    record.set('name', 'Tomster');
    record.save().then(function() {
      // Success callback
    }, function() {
      // Error callback
    });
    ```
    If you pass an object using the `adapterOptions` property of the options
   argument it will be passed to your adapter via the snapshot.
     ```js
    record.save({ adapterOptions: { subscribe: false } });
    ```
     ```app/adapters/post.js
    import MyCustomAdapter from './custom-adapter';
     export default class PostAdapter extends MyCustomAdapter {
      updateRecord(store, type, snapshot) {
        if (snapshot.adapterOptions.subscribe) {
          // ...
        }
        // ...
      }
    }
    ```
     @method save
    @public
    @param {Object} options
    @return {Promise} a promise that will be resolved when the adapter returns
    successfully or rejected if the adapter returns with an error.
  */

  /**
    Reload the record from the adapter.
     This will only work if the record has already finished loading.
     Example
     ```js
    import Component from '@glimmer/component';
     export default class extends Component {
      async reload = () => {
        await this.args.model.reload();
        // do something with the reloaded model
      }
    }
    ```
     @method reload
    @public
    @param {Object} options optional, may include `adapterOptions` hash which will be passed to adapter request
    @return {Promise} a promise that will be resolved with the record when the
    adapter returns successfully or rejected if the adapter returns
    with an error.
  */

  attr() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      {
        throw new Error('The `attr` method is not available on Model, a Snapshot was probably expected. Are you passing a Model instead of a Snapshot to your serializer?');
      }
    })() : {};
  }

  /**
    Get the reference for the specified belongsTo relationship.
     For instance, given the following model
     ```app/models/blog-post.js
    import Model, { belongsTo } from '@ember-data/model';
     export default class BlogPost extends Model {
      @belongsTo('user', { async: true, inverse: null }) author;
    }
    ```
     Then the reference for the author relationship would be
    retrieved from a record instance like so:
     ```js
    blogPost.belongsTo('author');
    ```
     A `BelongsToReference` is a low-level API that allows access
    and manipulation of a belongsTo relationship.
     It is especially useful when you're dealing with `async` relationships
    as it allows synchronous access to the relationship data if loaded, as
    well as APIs for loading, reloading the data or accessing available
    information without triggering a load.
     It may also be useful when using `sync` relationships that need to be
    loaded/reloaded with more precise timing than marking the
    relationship as `async` and relying on autofetch would have allowed.
     However,keep in mind that marking a relationship as `async: false` will introduce
    bugs into your application if the data is not always guaranteed to be available
    by the time the relationship is accessed. Ergo, it is recommended when using this
    approach to utilize `links` for unloaded relationship state instead of identifiers.
     Reference APIs are entangled with the relationship's underlying state,
    thus any getters or cached properties that utilize these will properly
    invalidate if the relationship state changes.
     References are "stable", meaning that multiple calls to retrieve the reference
    for a given relationship will always return the same HasManyReference.
     @method belongsTo
    @public
    @param {String} name of the relationship
    @since 2.5.0
    @return {BelongsToReference} reference for this relationship
  */

  /**
    Get the reference for the specified hasMany relationship.
     For instance, given the following model
     ```app/models/blog-post.js
    import Model, { hasMany } from '@ember-data/model';
     export default class BlogPost extends Model {
      @hasMany('comment', { async: true, inverse: null }) comments;
    }
    ```
     Then the reference for the comments relationship would be
    retrieved from a record instance like so:
     ```js
    blogPost.hasMany('comments');
    ```
     A `HasManyReference` is a low-level API that allows access
    and manipulation of a hasMany relationship.
     It is especially useful when you are dealing with `async` relationships
    as it allows synchronous access to the relationship data if loaded, as
    well as APIs for loading, reloading the data or accessing available
    information without triggering a load.
     It may also be useful when using `sync` relationships with `@ember-data/model`
    that need to be loaded/reloaded with more precise timing than marking the
    relationship as `async` and relying on autofetch would have allowed.
     However,keep in mind that marking a relationship as `async: false` will introduce
    bugs into your application if the data is not always guaranteed to be available
    by the time the relationship is accessed. Ergo, it is recommended when using this
    approach to utilize `links` for unloaded relationship state instead of identifiers.
     Reference APIs are entangled with the relationship's underlying state,
    thus any getters or cached properties that utilize these will properly
    invalidate if the relationship state changes.
     References are "stable", meaning that multiple calls to retrieve the reference
    for a given relationship will always return the same HasManyReference.
     @method hasMany
    @public
    @param {String} name of the relationship
    @since 2.5.0
    @return {HasManyReference} reference for this relationship
  */

  /**
   Given a callback, iterates over each of the relationships in the model,
   invoking the callback with the name of each relationship and its relationship
   descriptor.
     The callback method you provide should have the following signature (all
   parameters are optional):
    ```javascript
   function(name, descriptor);
   ```
    - `name` the name of the current property in the iteration
   - `descriptor` the meta object that describes this relationship
    The relationship descriptor argument is an object with the following properties.
    - **name** <span class="type">String</span> the name of this relationship on the Model
   - **kind** <span class="type">String</span> "hasMany" or "belongsTo"
   - **options** <span class="type">Object</span> the original options hash passed when the relationship was declared
   - **parentType** <span class="type">Model</span> the type of the Model that owns this relationship
   - **type** <span class="type">String</span> the type name of the related Model
    Note that in addition to a callback, you can also pass an optional target
   object that will be set as `this` on the context.
    Example
    ```app/serializers/application.js
   import JSONSerializer from '@ember-data/serializer/json';
    export default class ApplicationSerializer extends JSONSerializer {
      serialize(record, options) {
      let json = {};
       record.eachRelationship(function(name, descriptor) {
        if (descriptor.kind === 'hasMany') {
          let serializedHasManyName = name.toUpperCase() + '_IDS';
          json[serializedHasManyName] = record.get(name).map(r => r.id);
        }
      });
       return json;
    }
  }
   ```
    @method eachRelationship
   @public
   @param {Function} callback the callback to invoke
   @param {any} binding the value to which the callback's `this` should be bound
   */
  eachRelationship(callback, binding) {
    this.constructor.eachRelationship(callback, binding);
  }
  relationshipFor(name) {
    return this.constructor.relationshipsByName.get(name);
  }
  inverseFor(name) {
    return this.constructor.inverseFor(name, storeFor$1(this));
  }
  eachAttribute(callback, binding) {
    this.constructor.eachAttribute(callback, binding);
  }
  static isModel = true;

  /**
    Create should only ever be called by the store. To create an instance of a
    `Model` in a dirty state use `store.createRecord`.
    To create instances of `Model` in a clean state, use `store.push`
     @method create
    @private
    @static
  */

  /**
   Represents the model's class name as a string. This can be used to look up the model's class name through
   `Store`'s modelFor method.
    `modelName` is generated for you by EmberData. It will be a lowercased, dasherized string.
   For example:
    ```javascript
   store.modelFor('post').modelName; // 'post'
   store.modelFor('blog-post').modelName; // 'blog-post'
   ```
    The most common place you'll want to access `modelName` is in your serializer's `payloadKeyFromModelName` method. For example, to change payload
   keys to underscore (instead of dasherized), you might use the following code:
    ```javascript
   import RESTSerializer from '@ember-data/serializer/rest';
   import { underscore } from '<app-name>/utils/string-utils';
    export default const PostSerializer = RESTSerializer.extend({
     payloadKeyFromModelName(modelName) {
       return underscore(modelName);
     }
   });
   ```
   @property modelName
    @public
   @type String
   @readonly
   @static
  */
  static modelName = null;

  /*
   These class methods below provide relationship
   introspection abilities about relationships.
    A note about the computed properties contained here:
    **These properties are effectively sealed once called for the first time.**
   To avoid repeatedly doing expensive iteration over a model's fields, these
   values are computed once and then cached for the remainder of the runtime of
   your application.
    If your application needs to modify a class after its initial definition
   (for example, using `reopen()` to add additional attributes), make sure you
   do it before using your model with the store, which uses these properties
   extensively.
   */

  /**
   For a given relationship name, returns the model type of the relationship.
    For example, if you define a model like this:
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
     @hasMany('comment') comments;
   }
   ```
    Calling `store.modelFor('post').typeForRelationship('comments', store)` will return `Comment`.
    @method typeForRelationship
    @public
   @static
   @param {String} name the name of the relationship
   @param {store} store an instance of Store
   @return {Model} the type of the relationship, or undefined
   */
  static typeForRelationship(name, store) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const relationship = this.relationshipsByName.get(name);
    return relationship && store.modelFor(relationship.type);
  }
  static get inverseMap() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    return Object.create(null);
  }

  /**
   Find the relationship which is the inverse of the one asked for.
    For example, if you define models like this:
    ```app/models/post.js
   import Model, { hasMany } from '@ember-data/model';
    export default class PostModel extends Model {
      @hasMany('message') comments;
    }
   ```
    ```app/models/message.js
   import Model, { belongsTo } from '@ember-data/model';
    export default class MessageModel extends Model {
      @belongsTo('post') owner;
    }
   ```
    ``` js
   store.modelFor('post').inverseFor('comments', store) // { type: 'message', name: 'owner', kind: 'belongsTo' }
   store.modelFor('message').inverseFor('owner', store) // { type: 'post', name: 'comments', kind: 'hasMany' }
   ```
    @method inverseFor
    @public
   @static
   @param {String} name the name of the relationship
   @param {Store} store
   @return {Object} the inverse relationship, or null
   */
  static {
    decorateMethodV2(this, "inverseMap", [computeOnce]);
  }
  static inverseFor(name, store) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const inverseMap = this.inverseMap;
    if (inverseMap[name]) {
      return inverseMap[name];
    } else {
      const inverse = this._findInverseFor(name, store);
      inverseMap[name] = inverse;
      return inverse;
    }
  }

  //Calculate the inverse, ignoring the cache
  static _findInverseFor(name, store) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const relationship = this.relationshipsByName.get(name);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`No relationship named '${name}' on '${this.modelName}' exists.`);
      }
    })(relationship) : {};
    if (!relationship) {
      return null;
    }
    const {
      options
    } = relationship;
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Expected the relationship ${name} on ${this.modelName} to define an inverse.`);
      }
    })(options.inverse === null || typeof options.inverse === 'string' && options.inverse.length > 0) : {};
    if (options.inverse === null) {
      return null;
    }
    const schemaExists = store.schema.hasResource(relationship);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`No associated schema found for '${relationship.type}' while calculating the inverse of ${name} on ${this.modelName}`);
      }
    })(schemaExists) : {};
    if (!schemaExists) {
      return null;
    }
    const inverseField = store.schema.fields(relationship).get(options.inverse);
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`No inverse relationship found for '${name}' on '${this.modelName}'`);
      }
    })(inverseField && (inverseField.kind === 'belongsTo' || inverseField.kind === 'hasMany')) : {};
    return inverseField || null;
  }

  /**
   The model's relationships as a map, keyed on the type of the
   relationship. The value of each entry is an array containing a descriptor
   for each relationship with that type, describing the name of the relationship
   as well as the type.
    For example, given the following model definition:
    ```app/models/blog.js
   import Model, { belongsTo, hasMany } from '@ember-data/model';
    export default class BlogModel extends Model {
      @hasMany('user') users;
      @belongsTo('user') owner;
      @hasMany('post') posts;
    }
   ```
    This computed property would return a map describing these
   relationships, like this:
    ```javascript
   import Blog from 'app/models/blog';
   import User from 'app/models/user';
   import Post from 'app/models/post';
    let relationships = Blog.relationships;
   relationships.user;
   //=> [ { name: 'users', kind: 'hasMany' },
   //     { name: 'owner', kind: 'belongsTo' } ]
   relationships.post;
   //=> [ { name: 'posts', kind: 'hasMany' } ]
   ```
    @property relationships
    @public
   @static
   @type Map
   @readOnly
   */

  static get relationships() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const map = new Map();
    const relationshipsByName = this.relationshipsByName;

    // Loop through each computed property on the class
    relationshipsByName.forEach(desc => {
      const {
        type
      } = desc;
      if (!map.has(type)) {
        map.set(type, []);
      }
      map.get(type).push(desc);
    });
    return map;
  }

  /**
   A hash containing lists of the model's relationships, grouped
   by the relationship kind. For example, given a model with this
   definition:
    ```app/models/blog.js
   import Model, { belongsTo, hasMany } from '@ember-data/model';
    export default class BlogModel extends Model {
      @hasMany('user') users;
      @belongsTo('user') owner;
       @hasMany('post') posts;
    }
   ```
    This property would contain the following:
    ```javascript
   import Blog from 'app/models/blog';
    let relationshipNames = Blog.relationshipNames;
   relationshipNames.hasMany;
   //=> ['users', 'posts']
   relationshipNames.belongsTo;
   //=> ['owner']
   ```
    @property relationshipNames
    @public
   @static
   @type Object
   @readOnly
   */
  static {
    decorateMethodV2(this, "relationships", [computeOnce]);
  }
  static get relationshipNames() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const names = {
      hasMany: [],
      belongsTo: []
    };
    this.eachComputedProperty((name, meta) => {
      if (isRelationshipSchema(meta)) {
        names[meta.kind].push(name);
      }
    });
    return names;
  }

  /**
   An array of types directly related to a model. Each type will be
   included once, regardless of the number of relationships it has with
   the model.
    For example, given a model with this definition:
    ```app/models/blog.js
   import Model, { belongsTo, hasMany } from '@ember-data/model';
    export default class BlogModel extends Model {
      @hasMany('user') users;
      @belongsTo('user') owner;
       @hasMany('post') posts;
    }
   ```
    This property would contain the following:
    ```javascript
   import Blog from 'app/models/blog';
    let relatedTypes = Blog.relatedTypes');
   //=> ['user', 'post']
   ```
    @property relatedTypes
   @public
   @static
   @type Array
   @readOnly
   */
  static {
    decorateMethodV2(this, "relationshipNames", [computeOnce]);
  }
  static get relatedTypes() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const types = [];
    const rels = this.relationshipsObject;
    const relationships = Object.keys(rels);

    // create an array of the unique types involved
    // in relationships
    for (let i = 0; i < relationships.length; i++) {
      const name = relationships[i];
      const meta = rels[name];
      const modelName = meta.type;
      if (!types.includes(modelName)) {
        types.push(modelName);
      }
    }
    return types;
  }

  /**
   A map whose keys are the relationships of a model and whose values are
   relationship descriptors.
    For example, given a model with this
   definition:
    ```app/models/blog.js
   import Model, { belongsTo, hasMany } from '@ember-data/model';
    export default class BlogModel extends Model {
      @hasMany('user') users;
      @belongsTo('user') owner;
       @hasMany('post') posts;
    }
   ```
    This property would contain the following:
    ```javascript
   import Blog from 'app/models/blog';
    let relationshipsByName = Blog.relationshipsByName;
   relationshipsByName.users;
   //=> { name: 'users', kind: 'hasMany', type: 'user', options: Object }
   relationshipsByName.owner;
   //=> { name: 'owner', kind: 'belongsTo', type: 'user', options: Object }
   ```
    @property relationshipsByName
    @public
   @static
   @type Map
   @readOnly
   */
  static {
    decorateMethodV2(this, "relatedTypes", [computeOnce]);
  }
  static get relationshipsByName() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const map = new Map();
    const rels = this.relationshipsObject;
    const relationships = Object.keys(rels);
    for (let i = 0; i < relationships.length; i++) {
      const name = relationships[i];
      const value = rels[name];
      map.set(value.name, value);
    }
    return map;
  }
  static {
    decorateMethodV2(this, "relationshipsByName", [computeOnce]);
  }
  static get relationshipsObject() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const relationships = Object.create(null);
    const modelName = this.modelName;
    this.eachComputedProperty((name, meta) => {
      if (!isRelationshipSchema(meta)) {
        return;
      }
      // TODO deprecate key being here
      meta.key = name;
      meta.name = name;
      relationships[name] = meta;
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Expected options in meta`);
        }
      })(meta.options && typeof meta.options === 'object') : {};
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You should not specify both options.as and options.inverse as null on ${modelName}.${meta.name}, as if there is no inverse field there is no abstract type to conform to. You may have intended for this relationship to be polymorphic, or you may have mistakenly set inverse to null.`);
        }
      })(!(meta.options.inverse === null && meta.options.as?.length)) : {};
    });
    return relationships;
  }

  /**
   A map whose keys are the fields of the model and whose values are strings
   describing the kind of the field. A model's fields are the union of all of its
   attributes and relationships.
    For example:
    ```app/models/blog.js
   import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
    export default class BlogModel extends Model {
      @hasMany('user') users;
      @belongsTo('user') owner;
       @hasMany('post') posts;
       @attr('string') title;
    }
   ```
    ```js
   import Blog from 'app/models/blog'
    let fields = Blog.fields;
   fields.forEach(function(kind, field) {
      // do thing
    });
    // prints:
   // users, hasMany
   // owner, belongsTo
   // posts, hasMany
   // title, attribute
   ```
    @property fields
    @public
   @static
   @type Map
   @readOnly
   */
  static {
    decorateMethodV2(this, "relationshipsObject", [computeOnce]);
  }
  static get fields() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const map = new Map();
    this.eachComputedProperty((name, meta) => {
      if (isRelationshipSchema(meta)) {
        map.set(name, meta.kind);
      } else if (isAttributeSchema(meta)) {
        map.set(name, 'attribute');
      }
    });
    return map;
  }

  /**
   Given a callback, iterates over each of the relationships in the model,
   invoking the callback with the name of each relationship and its relationship
   descriptor.
    @method eachRelationship
    @public
   @static
   @param {Function} callback the callback to invoke
   @param {any} binding the value to which the callback's `this` should be bound
   */
  static {
    decorateMethodV2(this, "fields", [computeOnce]);
  }
  static eachRelationship(callback, binding) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    this.relationshipsByName.forEach((relationship, name) => {
      callback.call(binding, name, relationship);
    });
  }

  /**
   Given a callback, iterates over each of the types related to a model,
   invoking the callback with the related type's class. Each type will be
   returned just once, regardless of how many different relationships it has
   with a model.
    @method eachRelatedType
    @public
   @static
   @param {Function} callback the callback to invoke
   @param {any} binding the value to which the callback's `this` should be bound
   */
  static eachRelatedType(callback, binding) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const relationshipTypes = this.relatedTypes;
    for (let i = 0; i < relationshipTypes.length; i++) {
      const type = relationshipTypes[i];
      callback.call(binding, type);
    }
  }

  /**
   *
   * @method determineRelationshipType
   * @private
   * @deprecated
   */
  static determineRelationshipType(knownSide, store) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const knownKey = knownSide.name;
    const knownKind = knownSide.kind;
    const inverse = this.inverseFor(knownKey, store);
    // let key;

    if (!inverse) {
      return knownKind === 'belongsTo' ? 'oneToNone' : 'manyToNone';
    }

    // key = inverse.name;
    const otherKind = inverse.kind;
    if (otherKind === 'belongsTo') {
      return knownKind === 'belongsTo' ? 'oneToOne' : 'manyToOne';
    } else {
      return knownKind === 'belongsTo' ? 'oneToMany' : 'manyToMany';
    }
  }

  /**
   A map whose keys are the attributes of the model (properties
   described by attr) and whose values are the meta object for the
   property.
    Example
    ```app/models/person.js
   import Model, { attr } from '@ember-data/model';
    export default class PersonModel extends Model {
      @attr('string') firstName;
      @attr('string') lastName;
      @attr('date') birthday;
    }
   ```
    ```javascript
   import Person from 'app/models/person'
    let attributes = Person.attributes
    attributes.forEach(function(meta, name) {
      // do thing
    });
    // prints:
   // firstName {type: "string", kind: 'attribute', options: Object, parentType: function, name: "firstName"}
   // lastName {type: "string", kind: 'attribute', options: Object, parentType: function, name: "lastName"}
   // birthday {type: "date", kind: 'attribute', options: Object, parentType: function, name: "birthday"}
   ```
    @property attributes
    @public
   @static
   @type {Map}
   @readOnly
   */
  static get attributes() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const map = new Map();
    this.eachComputedProperty((name, meta) => {
      if (isAttributeSchema(meta)) {
        macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
          if (!test) {
            throw new Error("You may not set `id` as an attribute on your model. Please remove any lines that look like: `id: attr('<type>')` from " + this.toString());
          }
        })(name !== 'id') : {};

        // TODO deprecate key being here
        meta.key = name;
        meta.name = name;
        map.set(name, meta);
      }
    });
    return map;
  }

  /**
   A map whose keys are the attributes of the model (properties
   described by attr) and whose values are type of transformation
   applied to each attribute. This map does not include any
   attributes that do not have an transformation type.
    Example
    ```app/models/person.js
   import Model, { attr } from '@ember-data/model';
    export default class PersonModel extends Model {
      @attr firstName;
      @attr('string') lastName;
      @attr('date') birthday;
    }
   ```
    ```javascript
   import Person from 'app/models/person';
    let transformedAttributes = Person.transformedAttributes
    transformedAttributes.forEach(function(field, type) {
      // do thing
    });
    // prints:
   // lastName string
   // birthday date
   ```
    @property transformedAttributes
    @public
   @static
   @type {Map}
   @readOnly
   */
  static {
    decorateMethodV2(this, "attributes", [computeOnce]);
  }
  static get transformedAttributes() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    const map = new Map();
    this.eachAttribute((name, meta) => {
      if (meta.type) {
        map.set(name, meta.type);
      }
    });
    return map;
  }

  /**
   Iterates through the attributes of the model, calling the passed function on each
   attribute.
    The callback method you provide should have the following signature (all
   parameters are optional):
    ```javascript
   function(name, meta);
   ```
    - `name` the name of the current property in the iteration
   - `meta` the meta object for the attribute property in the iteration
    Note that in addition to a callback, you can also pass an optional target
   object that will be set as `this` on the context.
    Example
    ```javascript
   import Model, { attr } from '@ember-data/model';
    class PersonModel extends Model {
      @attr('string') firstName;
      @attr('string') lastName;
      @attr('date') birthday;
    }
    PersonModel.eachAttribute(function(name, meta) {
      // do thing
    });
    // prints:
   // firstName {type: "string", kind: 'attribute', options: Object, parentType: function, name: "firstName"}
   // lastName {type: "string", kind: 'attribute', options: Object, parentType: function, name: "lastName"}
   // birthday {type: "date", kind: 'attribute', options: Object, parentType: function, name: "birthday"}
   ```
    @method eachAttribute
    @public
   @param {Function} callback The callback to execute
   @param {Object} [binding] the value to which the callback's `this` should be bound
   @static
   */
  static {
    decorateMethodV2(this, "transformedAttributes", [computeOnce]);
  }
  static eachAttribute(callback, binding) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    this.attributes.forEach((meta, name) => {
      callback.call(binding, name, meta);
    });
  }

  /**
   Iterates through the transformedAttributes of the model, calling
   the passed function on each attribute. Note the callback will not be
   called for any attributes that do not have an transformation type.
    The callback method you provide should have the following signature (all
   parameters are optional):
    ```javascript
   function(name, type);
   ```
    - `name` the name of the current property in the iteration
   - `type` a string containing the name of the type of transformed
   applied to the attribute
    Note that in addition to a callback, you can also pass an optional target
   object that will be set as `this` on the context.
    Example
    ```javascript
   import Model, { attr } from '@ember-data/model';
    let Person = Model.extend({
      firstName: attr(),
      lastName: attr('string'),
      birthday: attr('date')
    });
    Person.eachTransformedAttribute(function(name, type) {
      // do thing
    });
    // prints:
   // lastName string
   // birthday date
   ```
    @method eachTransformedAttribute
    @public
   @param {Function} callback The callback to execute
   @param {Object} [binding] the value to which the callback's `this` should be bound
   @static
   */
  static eachTransformedAttribute(callback, binding) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    this.transformedAttributes.forEach((type, name) => {
      callback.call(binding, name, type);
    });
  }

  /**
   Returns the name of the model class.
    @method toString
    @public
   @static
   */
  static toString() {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`Accessing schema information on Models without looking up the model via the store is disallowed.`);
      }
    })(this.modelName) : {};
    return `model:${this.modelName}`;
  }
}

// @ts-expect-error TS doesn't know how to do `this` function overloads
Model.prototype.save = save;
// @ts-expect-error TS doesn't know how to do `this` function overloads
Model.prototype.destroyRecord = destroyRecord;
Model.prototype.unloadRecord = unloadRecord;
Model.prototype.hasMany = hasMany;
Model.prototype.belongsTo = belongsTo;
Model.prototype.serialize = serialize;
Model.prototype._createSnapshot = createSnapshot;
Model.prototype.deleteRecord = deleteRecord;
Model.prototype.changedAttributes = changedAttributes;
Model.prototype.rollbackAttributes = rollbackAttributes;
Model.prototype.reload = reload;
defineSignal(Model.prototype, 'isReloading', false);

// this is required to prevent `init` from passing
// the values initialized during create to `setUnknownProperty`
Model.prototype._createProps = null;
Model.prototype._secretInit = null;
if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
  const lookupDescriptor = function lookupDescriptor(obj, keyName) {
    let current = obj;
    do {
      const descriptor = Object.getOwnPropertyDescriptor(current, keyName);
      if (descriptor !== undefined) {
        return descriptor;
      }
      current = Object.getPrototypeOf(current);
    } while (current !== null);
    return null;
  };

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const init = Model.prototype.init;
  Model.prototype.init = function (createArgs) {
    init.call(this, createArgs);
    const ourDescriptor = lookupDescriptor(Model.prototype, 'currentState');
    const theirDescriptor = lookupDescriptor(this, 'currentState');
    if (!ourDescriptor || !theirDescriptor) {
      throw new Error(`Unable to determine if 'currentState' is a reserved property name on instances of classes extending Model. Please ensure that 'currentState' is not defined as a property on ${this.constructor.toString()}`);
    }
    const realState = this.___recordState;
    if (ourDescriptor.get !== theirDescriptor.get || realState !== this.currentState) {
      throw new Error(`'currentState' is a reserved property name on instances of classes extending Model. Please choose a different property name for ${this.constructor.toString()}`);
    }
    const ID_DESCRIPTOR = lookupDescriptor(Model.prototype, 'id');
    const idDesc = lookupDescriptor(this, 'id');
    if (!ID_DESCRIPTOR || !idDesc) {
      throw new Error(`Unable to determine if 'id' is a reserved property name on instances of classes extending Model. Please ensure that 'id' is not defined as a property on ${this.constructor.toString()}`);
    }
    if (idDesc.get !== ID_DESCRIPTOR.get) {
      throw new Error(`You may not set 'id' as an attribute on your model. Please remove any lines that look like: \`id: attr('<type>')\` from ${this.constructor.toString()}`);
    }
  };
  delete Model.reopen;
  delete Model.reopenClass;
}
function isRelationshipSchema(meta) {
  const hasKind = typeof meta === 'object' && meta !== null && 'kind' in meta && 'options' in meta;
  return hasKind && (meta.kind === 'hasMany' || meta.kind === 'belongsTo');
}
function isAttributeSchema(meta) {
  return typeof meta === 'object' && meta !== null && 'kind' in meta && meta.kind === 'attribute';
}
export { Errors as E, LEGACY_SUPPORT as L, Model as M, PromiseBelongsTo as P, RelatedCollection as R, PromiseManyArray as a, save as b, reload as c, destroyRecord as d, deleteRecord as e, RecordState as f, changedAttributes as g, hasMany as h, belongsTo as i, createSnapshot as j, isElementDescriptor as k, lookupLegacySupport as l, normalizeModelName as n, rollbackAttributes as r, serialize as s, unloadRecord as u };