import { computed } from '@ember/object';
import { recordIdentifierFor } from '@ember-data/store';
import { peekCache } from '@ember-data/store/-private';
import { k as isElementDescriptor, n as normalizeModelName, l as lookupLegacySupport } from "./model-CAqu4cYw.js";
import { macroCondition, getGlobalConfig } from '@embroider/macros';
import { warn, deprecate } from '@ember/debug';
import { RecordStore } from '@warp-drive/core-types/symbols';
import { singularize, dasherize } from '@ember-data/request-utils/string';

/**
  @module @ember-data/model
*/
function _attr(type, options) {
  if (typeof type === 'object') {
    options = type;
    type = undefined;
  } else {
    options = options || {};
  }
  const meta = {
    type: type,
    kind: 'attribute',
    isAttribute: true,
    options: options,
    key: null
  };
  return computed({
    get(key) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ${this.constructor.toString()}`);
        }
      }
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      return peekCache(this).getAttr(recordIdentifierFor(this), key);
    },
    set(key, value) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your attr on ${this.constructor.toString()}`);
        }
      }
      const identifier = recordIdentifierFor(this);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`Attempted to set '${key}' on the deleted record ${identifier.type}:${identifier.id} (${identifier.lid})`);
        }
      })(!this.currentState.isDeleted) : {};
      const cache = peekCache(this);
      const currentValue = cache.getAttr(identifier, key);
      if (currentValue !== value) {
        cache.setAttr(identifier, key, value);
        if (!this.isValid) {
          const {
            errors
          } = this;
          if (errors.get(key)) {
            errors.remove(key);
            this.currentState.cleanErrorRequests();
          }
        }
      }
      return value;
    }
  }).meta(meta);
}

// NOTE: Usage of Explicit ANY
// -------------------------------------------------------------------
// any is required here because we are the maximal not the minimal
// subset of options allowed. If we used unknown, object, or
// Record<string, unknown> we would get type errors when we try to
// assert against a more specific implementation with precise options.
// -------------------------------------------------------------------

// see note on Explicit ANY above
// eslint-disable-next-line @typescript-eslint/no-explicit-any

/**
 * The return type of `void` is a lie to appease TypeScript. The actual return type
 * is a descriptor, but typescript incorrectly insists that decorator functions return
 * `void` or `any`.
 *
 * @typedoc
 */

/**
  `attr` defines an attribute on a [Model](/ember-data/release/classes/Model).
  By default, attributes are passed through as-is, however you can specify an
  optional type to have the value automatically transformed.
  EmberData ships with four basic transform types: `string`, `number`,
  `boolean` and `date`. You can define your own transforms by subclassing
  [Transform](/ember-data/release/classes/Transform).

  Note that you cannot use `attr` to define an attribute of `id`.

  `attr` takes an optional hash as a second parameter, currently
  supported options are:

  - `defaultValue`: Pass a string or a function to be called to set the attribute
  to a default value if and only if the key is absent from the payload response.

  Example

  ```app/models/user.js
  import Model, { attr } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('string') username;
    @attr('string') email;
    @attr('boolean', { defaultValue: false }) verified;
  }
  ```

  Default value can also be a function. This is useful it you want to return
  a new object for each attribute.

  ```app/models/user.js
  import Model, { attr } from '@ember-data/model';

  export default class UserModel extends Model {
    @attr('string') username;
    @attr('string') email;

    @attr({
      defaultValue() {
        return {};
      }
    })
    settings;
  }
  ```

  The `options` hash is passed as second argument to a transforms'
  `serialize` and `deserialize` method. This allows to configure a
  transformation and adapt the corresponding value, based on the config:

  ```app/models/post.js
  import Model, { attr } from '@ember-data/model';

  export default class PostModel extends Model {
    @attr('text', {
      uppercase: true
    })
    text;
  }
  ```

  ```app/transforms/text.js
  export default class TextTransform {
    serialize(value, options) {
      if (options.uppercase) {
        return value.toUpperCase();
      }

      return value;
    }

    deserialize(value) {
      return value;
    }

    static create() {
      return new this();
    }
  }
  ```

  @method attr
  @public
  @static
  @for @ember-data/model
  @param {String|Object} type the attribute type
  @param {Object} options a hash of options
  @return {Attribute}
*/

// see note on DataDecorator for why void
function attr(type, options, desc) {
  const args = [type, options, desc];
  // see note on DataDecorator for why void
  return isElementDescriptor(args) ? _attr()(...args) : _attr(type, options);
}

//   get: () => getT;
//   // set: (value: Awaited<getT>) => void;
//   set: (value: getT) => void;
//   // init: () => getT;
// };
// eslint-disable-next-line @typescript-eslint/no-unused-vars
// BelongsToDecoratorObject<getT>;

function _belongsTo(type, options) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected options.async from @belongsTo('${type}', options) to be a boolean`);
    }
  })(options && typeof options.async === 'boolean') : {};
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected options.inverse from @belongsTo('${type}', options) to be either null or the string type of the related resource.`);
    }
  })(options.inverse === null || typeof options.inverse === 'string' && options.inverse.length > 0) : {};
  const meta = {
    type: normalizeModelName(type),
    options: options,
    kind: 'belongsTo',
    name: '<Unknown BelongsTo>'
  };
  return computed({
    get(key) {
      // this is a legacy behavior we may not carry into a new model setup
      // it's better to error on disconnected records so users find errors
      // in their logic.
      if (this.isDestroying || this.isDestroyed) {
        return null;
      }
      const support = lookupLegacySupport(this);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your belongsTo on ${this.constructor.toString()}`);
        }
        if (Object.prototype.hasOwnProperty.call(options, 'serialize')) {
          warn(`You provided a serialize option on the "${key}" property in the "${support.identifier.type}" class, this belongs in the serializer. See Serializer and it's implementations https://api.emberjs.com/ember-data/release/classes/Serializer`, false, {
            id: 'ds.model.serialize-option-in-belongs-to'
          });
        }
        if (Object.prototype.hasOwnProperty.call(options, 'embedded')) {
          warn(`You provided an embedded option on the "${key}" property in the "${support.identifier.type}" class, this belongs in the serializer. See EmbeddedRecordsMixin https://api.emberjs.com/ember-data/release/classes/EmbeddedRecordsMixin`, false, {
            id: 'ds.model.embedded-option-in-belongs-to'
          });
        }
      }
      return support.getBelongsTo(key);
    },
    set(key, value) {
      const support = lookupLegacySupport(this);
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your belongsTo on ${this.constructor.toString()}`);
        }
      }
      this[RecordStore]._join(() => {
        support.setDirtyBelongsTo(key, value);
      });
      return support.getBelongsTo(key);
    }
  }).meta(meta);
}

/**
  `belongsTo` is used to define One-To-One and One-To-Many, and One-To-None
  relationships on a [Model](/ember-data/release/classes/Model).

  `belongsTo` takes a configuration hash as a second parameter, currently
  supported options are:

  - `async`: (*required*) A boolean value used to declare whether this is a sync (false) or async (true) relationship.
  - `inverse`: (*required*)  A string used to identify the inverse property on a related model, or `null`.
  - `polymorphic`: (*optional*) A boolean value to mark the relationship as polymorphic
  - `as`: (*optional*) A string used to declare the abstract type "this" record satisfies for polymorphism.

  ### Examples

  To declare a **one-to-many** (or many-to-many) relationship, use
  `belongsTo` in combination with `hasMany`:

  ```js
  // app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Comment extends Model {
    @belongsTo('post', { async: false, inverse: 'comments' }) post;
  }

  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('comment', { async: false, inverse: 'post' }) comments;
  }
  ```

  To declare a **one-to-one** relationship with managed inverses, use `belongsTo` for both sides:

  ```js
  // app/models/author.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Author extends Model {
    @belongsTo('address', { async: true, inverse: 'owner' }) address;
  }

  // app/models/address.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Address extends Model {
    @belongsTo('author', { async: true, inverse: 'address' }) owner;
  }
  ```

  To declare a **one-to-one** relationship without managed inverses, use `belongsTo` for both sides
  with `null` as the inverse:

  ```js
  // app/models/author.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Author extends Model {
    @belongsTo('address', { async: true, inverse: null }) address;
  }

  // app/models/address.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Address extends Model {
    @belongsTo('author', { async: true, inverse: null }) owner;
  }
  ```

  To declare a one-to-none relationship between two models, use
  `belongsTo` with inverse set to `null` on just one side::

  ```js
  // app/models/person.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Person extends Model {
    @belongsTo('person', { async: false, inverse: null }) bestFriend;
  }
  ```

  #### Sync vs Async Relationships

  EmberData fulfills relationships using resource data available in
  the cache.

  Sync relationships point directly to the known related resources.

  When a relationship is declared as async, if any of the known related
  resources have not been loaded, they will be fetched. The property
  on the record when accessed provides a promise that resolves once
  all resources are loaded.

  Async relationships may take advantage of links. On access, if the related
  link has not been loaded, or if any known resources are not available in
  the cache, the fresh state will be fetched using the link.

  In contrast to async relationship, accessing a sync relationship
  will error on access when any of the known related resources have
  not been loaded.

  If you are using `links` with sync relationships, you have to use
  the BelongsTo reference API to fetch or refresh related resources
  that aren't loaded. For instance, for a `bestFriend` relationship:

  ```js
  person.belongsTo('bestFriend').reload();
  ```

  #### Polymorphic Relationships

  To declare a polymorphic relationship, use `hasMany` with the `polymorphic`
  option set to `true`:

  ```js
  // app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Comment extends Model {
    @belongsTo('commentable', { async: false, inverse: 'comments', polymorphic: true }) parent;
  }
  ```

  `'commentable'` here is referred to as the "abstract type" for the polymorphic
  relationship.

  Polymorphic relationships with `inverse: null` will accept any type of record as their content.
  Polymorphic relationships with `inverse` set to a string will only accept records with a matching
  inverse relationships declaring itself as satisfying the abstract type.

  Below, 'as' is used to declare the that 'post' record satisfies the abstract type 'commentable'
  for this relationship.

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('comment', { async: false, inverse: 'parent', as: 'commentable' }) comments;
  }
  ```

  Note: every Model that declares an inverse to a polymorphic relationship must
  declare itself exactly the same. This is because polymorphism is based on structural
  traits.

  Polymorphic to polymorphic relationships are supported. Both sides of the relationship
  must be declared as polymorphic, and the `as` option must be used to declare the abstract
  type each record satisfies on both sides.

  @method belongsTo
  @public
  @static
  @for @ember-data/model
  @param {string} type (optional) the name of the related resource
  @param {object} options (optional) a hash of options
  @return {PropertyDescriptor} relationship
*/

// export function belongsTo<K extends Promise<unknown>, T extends Awaited<K> = Awaited<K>>(
//   type: TypeFromInstance<NoNull<T>>,
//   options: RelationshipOptions<T, true>
// ): RelationshipDecorator<K>;

function belongsTo(type, options) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`belongsTo must be invoked with a type and options. Did you mean \`@belongsTo(${type}, { async: false, inverse: null })\`?`);
      }
    })(!isElementDescriptor(arguments)) : {};
  }
  return _belongsTo(type, options);
}

/**
  @module @ember-data/model
*/
function normalizeType(type) {
  if (macroCondition(getGlobalConfig().WarpDrive.deprecations.DEPRECATE_NON_STRICT_TYPES)) {
    const result = singularize(dasherize(type));
    deprecate(`The resource type '${type}' is not normalized. Update your application code to use '${result}' instead of '${type}'.`, result === type, {
      id: 'ember-data:deprecate-non-strict-types',
      until: '6.0',
      for: 'ember-data',
      since: {
        available: '4.13',
        enabled: '5.3'
      }
    });
    return result;
  }
  return type;
}
function _hasMany(type, options) {
  macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
    if (!test) {
      throw new Error(`Expected hasMany options.async to be a boolean`);
    }
  })(options && typeof options.async === 'boolean') : {};

  // Metadata about relationships is stored on the meta of
  // the relationship. This is used for introspection and
  // serialization. Note that `key` is populated lazily
  // the first time the CP is called.
  const meta = {
    type: normalizeType(type),
    options,
    kind: 'hasMany',
    name: '<Unknown BelongsTo>'
  };
  return computed({
    get(key) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your hasMany on ${this.constructor.toString()}`);
        }
      }
      if (this.isDestroying || this.isDestroyed) {
        return [];
      }
      return lookupLegacySupport(this).getHasMany(key);
    },
    set(key, records) {
      if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
        if (['currentState'].includes(key)) {
          throw new Error(`'${key}' is a reserved property name on instances of classes extending Model. Please choose a different property name for your hasMany on ${this.constructor.toString()}`);
        }
      }
      const support = lookupLegacySupport(this);
      const manyArray = support.getManyArray(key);
      macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
        if (!test) {
          throw new Error(`You must pass an array of records to set a hasMany relationship`);
        }
      })(Array.isArray(records)) : {};
      this[RecordStore]._join(() => {
        manyArray.splice(0, manyArray.length, ...records);
      });
      return support.getHasMany(key);
    }
  }).meta(meta);
}

/**
  `hasMany` is used to define Many-To-One and Many-To-Many, and Many-To-None
  relationships on a [Model](/ember-data/release/classes/Model).

  `hasMany` takes a configuration hash as a second parameter, currently
  supported options are:

  - `async`: (*required*) A boolean value used to declare whether this is a sync (false) or async (true) relationship.
  - `inverse`: (*required*)  A string used to identify the inverse property on a related model, or `null`.
  - `polymorphic`: (*optional*) A boolean value to mark the relationship as polymorphic
  - `as`: (*optional*) A string used to declare the abstract type "this" record satisfies for polymorphism.

  ### Examples

  To declare a **many-to-one** (or one-to-many) relationship, use
  `belongsTo` in combination with `hasMany`:

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('comment', { async: false, inverse: 'post' }) comments;
  }


  // app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Comment extends Model {
    @belongsTo('post', { async: false, inverse: 'comments' }) post;
  }
  ```

  To declare a **many-to-many** relationship with managed inverses, use `hasMany` for both sides:

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('tag', { async: true, inverse: 'posts' }) tags;
  }

  // app/models/tag.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Tag extends Model {
    @hasMany('post', { async: true, inverse: 'tags' }) posts;
  }
  ```

  To declare a **many-to-many** relationship without managed inverses, use `hasMany` for both sides
  with `null` as the inverse:

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('tag', { async: true, inverse: null }) tags;
  }

  // app/models/tag.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Tag extends Model {
    @hasMany('post', { async: true, inverse: null }) posts;
  }
  ```

  To declare a many-to-none relationship between two models, use
  `hasMany` with inverse set to `null` on just one side::

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('category', { async: true, inverse: null }) categories;
  }
  ```

  #### Sync vs Async Relationships

  EmberData fulfills relationships using resource data available in
  the cache.

  Sync relationships point directly to the known related resources.

  When a relationship is declared as async, if any of the known related
  resources have not been loaded, they will be fetched. The property
  on the record when accessed provides a promise that resolves once
  all resources are loaded.

  Async relationships may take advantage of links. On access, if the related
  link has not been loaded, or if any known resources are not available in
  the cache, the fresh state will be fetched using the link.

  In contrast to async relationship, accessing a sync relationship
  will error on access when any of the known related resources have
  not been loaded.

  If you are using `links` with sync relationships, you have to use
  the HasMany reference API to fetch or refresh related resources
  that aren't loaded. For instance, for a `comments` relationship:

  ```js
  post.hasMany('comments').reload();
  ```

  #### Polymorphic Relationships

  To declare a polymorphic relationship, use `hasMany` with the `polymorphic`
  option set to `true`:

  ```js
  // app/models/comment.js
  import Model, { belongsTo } from '@ember-data/model';

  export default class Comment extends Model {
    @belongsTo('commentable', { async: false, inverse: 'comments', polymorphic: true }) parent;
  }
  ```

  `'commentable'` here is referred to as the "abstract type" for the polymorphic
  relationship.

  Polymorphic relationships with `inverse: null` will accept any type of record as their content.
  Polymorphic relationships with `inverse` set to a string will only accept records with a matching
  inverse relationships declaring itself as satisfying the abstract type.

  Below, 'as' is used to declare the that 'post' record satisfies the abstract type 'commentable'
  for this relationship.

  ```js
  // app/models/post.js
  import Model, { hasMany } from '@ember-data/model';

  export default class Post extends Model {
    @hasMany('comment', { async: false, inverse: 'parent', as: 'commentable' }) comments;
  }
  ```

  Note: every Model that declares an inverse to a polymorphic relationship must
  declare itself exactly the same. This is because polymorphism is based on structural
  traits.

  Polymorphic to polymorphic relationships are supported. Both sides of the relationship
  must be declared as polymorphic, and the `as` option must be used to declare the abstract
  type each record satisfies on both sides.

  @method hasMany
  @public
  @static
  @for @ember-data/model
  @param {string} type (optional) the name of the related resource
  @param {object} options (optional) a hash of options
  @return {PropertyDescriptor} relationship
*/

// export function hasMany<K extends Promise<unknown>, T extends Awaited<K> = Awaited<K>>(
//   type: TypeFromInstance<NoNull<T>>,
//   options: RelationshipOptions<T, true>
// ): RelationshipDecorator<K>;

function hasMany(type, options) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
    macroCondition(getGlobalConfig().WarpDrive.env.DEBUG) ? (test => {
      if (!test) {
        throw new Error(`hasMany must be invoked with a type and options. Did you mean \`@hasMany(${type}, { async: false, inverse: null })\`?`);
      }
    })(!isElementDescriptor(arguments)) : {};
  }
  return _hasMany(type, options);
}
export { attr as a, belongsTo as b, hasMany as h };