import { service } from '@ember/service';
import { task } from 'ember-concurrency';
import Controller from '@ember/controller';
import ENV from 'boel/config/environment';
import { action } from '@ember/object';
import fetch from 'fetch';
import
  {
    isServerErrorResponse,
    isUnauthorizedResponse,
    isBadRequestResponse,
    isAbortError,
    isForbiddenResponse,
  } from 'ember-fetch/errors';
import { debug } from '@ember/debug';
import
  {
    validatePresence,
    validateLength,
    validateConfirmation,
    validateNumber,
    validateFormat,
  } from 'ember-changeset-validations/validators';
import validateUniqueness from '../../../../../validations/unique';
import { tracked } from '@glimmer/tracking';
export default class AuthSuperbrugerIndstillingerBrugereNewController extends Controller
{
  @service session;
  @service notifications;
  @service intl;
  @service router;
  @service currentUser;

  validations = {
    userName: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 2,
        message: this.intl.t('validations.tooShort', {
          description: 'Brugernavnet',
          is: 2,
          min: 2,
        }),
      }),
      validateFormat({
        regex: '^[a-z0-9]+$',
        message: this.intl.t('validations.alphanumeric_username'),
      }),
      validateUniqueness({
        message: this.intl.t('validations.uniqueness_username'),
      }),
    ],
    outlet: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    email: [
      validateFormat({
        type: 'email',
        allowBlank: true,
        message: this.intl.t('validations.email', {
          description: 'Emailen',
        }),
      }),
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
    ],
    smartphone: [
      validateNumber({
        allowBlank: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 8,
        max: 8,
        allowBlank: true,
        message: this.intl.t('validations.wrongLength', {
          description: 'Telefonnummeret',
          is: 8,
        }),
      }),
    ],
    password: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 6,
        message: this.intl.t('validations.wrongLength', {
          description: 'Adgangskoden',
          is: 6,
        }),
      }),
      validateFormat({
        regex: '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*',
        message: this.intl.t('validations.format'),
      }),
    ],

    confirmPassword: [
      validatePresence({
        presence: true,
        message: this.intl.t('validations.present'),
      }),
      validateLength({
        min: 6,
        message: this.intl.t('validations.wrongLength', {
          description: 'Adgangskoden',
          is: 6,
        }),
      }),
      validateFormat({
        regex: '(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9]).*',
        message: this.intl.t('validations.format'),
      }),
      validateConfirmation({
        on: 'password',
        message: this.intl.t('validations.confirmation', {
          description: 'Adgangskoderne',
        }),
      }),
    ],
  };

  @tracked chosenOutlet;

  get ErAdministrator()
  {
    return this.currentUser.user.userRole === 'Administrator';
  }
  get IngenRettighed()
  {
    let IngenRettighed = true;

    if (this.currentUser.user.userRole != 'Normal')
    {
      IngenRettighed = false;

      if (
        this.currentUser.user.userRole == 'Superuser' &&
        this.model.editRecord.userRole === 'Administrator'
      )
      {
        IngenRettighed = true;
      }
    }
    return IngenRettighed;
  }
  @action
  choose(outlet)
  {
    this.chosenOutlet = outlet;
  }

  // Use the drop modifier to prevent the task from running twice
  @task({ drop: true })
  *saveTask(changeset)
  {
    const user = changeset.get('userName');
    const firstname = changeset.get('firstname');
    const lastname = changeset.get('lastname');
    const password = changeset.get('password');
    const confirmPassword = changeset.get('confirmPassword');
    const email = changeset.get('email');
    const role = changeset.get('userRole') || 'Normal';
    const disabled = changeset.get('disabled');
    let link = ENV.APP.host + '/' + ENV.APP.namespace + '/auth/register';
    let outletid = -1;
    let outlet = changeset.get('outlet');
    if (outlet != null)
    {
      let o = yield outlet;
      try
      {
        outletid = parseInt(o.id, 10);
      } catch (err)
      {
        outletid = -1;
      }
    }
    const Is_Valid =
      password === confirmPassword && user !== '' && outletid != -1;

    var headers = {
      'Content-Type': 'application/json',
    };

    if (Is_Valid)
    {
      if (this.session.isAuthenticated)
      {
        headers[
          'Authorization'
        ] = `Bearer ${this.session.data.authenticated.access_token}`;
      }
      this.serverError = [];
      try
      {
        let response = yield fetch(link, {
          headers: headers,
          method: 'POST',
          cache: 'no-cache',
          body: JSON.stringify({
            UserName: user,
            Password: password,
            Email: email,
            Firstname: firstname,
            Lastname: lastname,
            Outletid: outletid,
            UserRole: role,
            Disabled: disabled
          }),
        });

        if (response.ok)
        {
          if (ENV.APP.ShowNotifications) {
            this.notifications.success(this.intl.t('retbruger.ok'));
          }
          this.errorMessage = '';
          this.router.transitionTo('auth.superbruger.indstillinger.brugere', {
            queryParams: { refresh: Date.now() },
          });
        } else if (isUnauthorizedResponse(response))
        {
          this.errorMessage = this.intl.t('retbruger.unauthorized');
          this.notifications.error(this.intl.t('retbruger.unauthorized'));
          this.session.invalidate();
        } else if (isForbiddenResponse(response))
        {
          this.errorMessage = this.intl.t('retbruger.forbidden');

          this.notifications.error(this.intl.t('ret.forbidden'));
          this.session.invalidate();
        } else if (isBadRequestResponse(response))
        {
          response.json().then((data) =>
          {
            const errorList = [];
            Object.keys(data).forEach((key) =>
            {
              if (!Object.hasOwnProperty(key))
              {
                var value = data[key];
                errorList.push(value);
              }
            });
            this.serverError = errorList;
          });
        } else if (isServerErrorResponse(response))
        {
          this.errorMessage = this.intl.t('retbruger.servererror');
          this.notifications.error(this.intl.t('retbruger.servererror'));
        } else if (isAbortError(response))
        {
          this.errorMessage = this.intl.t('retbruger.servererror');
          this.notifications.error();
        }
      } catch (err)
      {
        debug('Fejl under fetch: ');
        this.notifications.error(this.intl.t('retbruger.catch'));

        debug('Fejl under fetch: ');
        debug(err);
      }
    }
  }
  @action
  cancel()
  {
    this.model.newRecord.destroyRecord();
    this.router.transitionTo('auth.superbruger.indstillinger.brugere');
    return false;
  }
}
