import { macroCondition, getGlobalConfig } from '@embroider/macros';
const name = "@warp-drive/core-types";
const version = "0.0.0";

// in testing mode, we utilize globals to ensure only one copy exists of
// these maps, due to bugs in ember-auto-import

const GlobalRef = globalThis;
const UniversalCache = GlobalRef.__warpDrive_universalCache = GlobalRef.__warpDrive_universalCache ?? {};

// in order to support mirror packages, we ensure that each
// unique package name has its own global cache
GlobalRef[name] = GlobalRef[name] ?? {
  __version: version
};
const GlobalSink = GlobalRef[name];
if (macroCondition(getGlobalConfig().WarpDrive.env.DEBUG)) {
  if (GlobalSink.__version !== version) {
    throw new Error('Multiple versions of WarpDrive detected, the application will malfunction.');
  }
}
const ModuleScopedCaches = GlobalSink.__warpDrive_ModuleScopedCaches ?? {};
if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
  if (!GlobalSink.__warpDrive_ModuleScopedCaches) {
    GlobalSink.__warpDrive_ModuleScopedCaches = ModuleScopedCaches;
  } else {
    // eslint-disable-next-line no-console
    console.warn(`
Multiple copies of EmberData have been detected. This may be due to a bug in ember-auto-import
  in which test assets get their own copy of some v2-addons. This can cause the application to
  malfunction as each copy will maintain its own separate state.`);
  }
} else {
  if (GlobalSink.__warpDrive_hasOtherCopy) {
    throw new Error('Multiple copies of EmberData detected, the application will malfunction.');
  }
  GlobalSink.__warpDrive_hasOtherCopy = true;
}
function getOrSetGlobal(key, value) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
    const existing = ModuleScopedCaches[key];
    if (existing === undefined) {
      return ModuleScopedCaches[key] = value;
    } else {
      return existing;
    }
  } else {
    return value;
  }
}
function peekTransient(key) {
  const globalKey = `(transient) ${key}`;
  return ModuleScopedCaches[globalKey] ?? null;
}
function setTransient(key, value) {
  const globalKey = `(transient) ${key}`;
  return ModuleScopedCaches[globalKey] = value;
}
function getOrSetUniversal(key, value) {
  if (macroCondition(getGlobalConfig().WarpDrive.env.TESTING)) {
    const existing = UniversalCache[key];
    if (existing === undefined) {
      return UniversalCache[key] = value;
    } else {
      return existing;
    }
  } else {
    return value;
  }
}
function peekUniversalTransient(key) {
  const globalKey = `(transient) ${key}`;
  return UniversalCache[globalKey] ?? null;
}
function setUniversalTransient(key, value) {
  const globalKey = `(transient) ${key}`;
  return UniversalCache[globalKey] = value;
}
export { getOrSetGlobal, getOrSetUniversal, peekTransient, peekUniversalTransient, setTransient, setUniversalTransient };